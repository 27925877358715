import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
  // DataTableWidget,
  SaveAction,
  // SelectMultipleWidget,
  // LoadingWidget,
  // TextFieldWidgetSelectController,
  TextFieldWidgetSelectControllerHorizontal,
  TextFieldWidgetText,
  CardHeaderWidget,
  BreadCrumbs,
  SnackBarsComponent,
} from '../../../components';
import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  // Divider,
  Card,
  CardContent,
  // Hidden,
  // Typography,
  useTheme,
  Snackbar,
  InputAdornment,
  IconButton,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';
import { makeStyles } from '@mui/styles';
import viewsTexts from '../../../common/viewsTexts.json';
import {
  alphabeticalSort,
  // numberFormat,
  // isNumber,
  // findActualYear,
  // alterArrayForSelectVariety,
  // getOrchardVarieties,
  // getOrchardVarietiesDeep,
  alterArrayGetFields,
  formatRut,
} from '../../../helpers';
// import theme from 'theme';
import { FiltersContext } from '../../../context';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  rootHelper: {
    fontSize: 17,
  },
  inputText: {
    fontSize: 26,
  },
}));

const UserAdd = (props) => {
  const { userId, userHuerto, userName, userOrchard } = useContext(Context);

  // console.log(userId, userHuerto, userName, "<<<<---context")
  const { state: filterState, addFilter } = useContext(FiltersContext);

  const {
    // orchard_filter,
    // cc_filter,
    // quarter_filter,
    // variety_filter,
    // season_filter,
    userOrchard_filter,
  } = filterState;

  // console.log('----', filterState);

  const axiosContext = useContext(AxiosContext);
  // axiosContext.authAxios //queries with token

  const [filterDataResponseState, setFilterDataResponseState] =
    useState(userOrchard_filter);

  const [showPassword, setShowPassword] = useState(false);

  const [loadingQueryState, setLoadingQueryState] = useState(false);
  const [successQueryState, setSuccessQueryState] = useState(false);

  const [profileDataState, setProfileDataState] = useState([]);
  const [loadingProfileDataState, setLoadingProfileDataState] = useState(false);

  const [loadingClientDataState, setLoadingClientDataState] = useState(false);

  const [clientDataState, setClientDataState] = useState([]);
  const [agroBusinessDataState, setAgroBusinessDataState] = useState([]);

  const [errorState, setErrorState] = useState(false);

  const [hideClientDataState, setHideClientDataState] = useState(false);

  const theme = useTheme();

  // console.log(theme)

  const classes = useStyles();

  const [valueTabState, setValueTabState] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTabState(newValue);
  };

  const {
    handleSubmit,
    control,
    watch,
    reset,
    // setValue,
    getValues,
    // setError,
    // clearErrors,
    formState: { errors },
  } = useForm();

  // const watchRut = watch('rut');
  const watchClient = watch('client');
  const watchProfile = watch('profile');

  const onSubmit = async (values) => {
    // console.log(values, '<<<--------');
    setLoadingQueryState(true);

    const profileSelected = profileDataState.filter(
      (ele) => ele.value === values.profile
    );

    // saca los objetos
    let clientObj = values.client
      ? clientDataState.filter((ele) => ele.value === values.client)
      : null;

    clientObj = clientObj
      ? alterArrayGetFields(clientObj, ['id', 'label', 'value'])
      : null;

    const agroBusinessArray = values.agroBusiness
      ? values.agroBusiness.map((agro) => {
          const dataAgro = agroBusinessDataState.filter(
            (ele) => ele.value === agro
          );
          return dataAgro[0];
        })
      : '';

    const query = {
      profile: profileSelected[0],
      email: values.email,
      // rut: formatRut(values.rut),
      client: clientObj ? clientObj[0] : null,
      agrobusiness: agroBusinessArray,
      name: values.name,
      lastname: values.lastName,
      username: values.userName,
      // maternalLastname: values.maternalLastname,
      phone: values.phone,
      password: values.password,
    };

    // console.log(query, 'envia a create');
    try {
      const response = await axiosContext.authAxios.post(
        'v1/user/create-user',
        query
      );
      const bodyInfo = response.data;

      // 	// setProfileSelectedState('');
      // 	console.log(response, '<-----response');
      // 	// let response;
      // console.log(response, '------response');

      if (response.status === 200) {
        // alert('listo');
        setSuccessQueryState(true);

        reset({
          email: '',
          rut: '',
          name: '',
          lastName: '',
          phone: '',
          password: '',
        });
      }

      // setProfileSelectedState('');

      setLoadingQueryState(false);
    } catch (err) {
      // console.log('cae al error', err);
      // console.log('Error', err.message);
      console.log('Error', err.request.status);
      if (err.request.status === 500) {
        setErrorState(true);
      }

      setLoadingQueryState(false);
    }
  };

  const { password } = getValues();

  useEffect(() => {
    // console.log(watchClient, '-----watchClient');
    if (watchClient) {
      const resultClient = clientDataState.filter(
        (ele) => ele.value === watchClient
      );
      // console.log('filtrado', resultClient);
      setAgroBusinessDataState(
        alphabeticalSort(resultClient[0].agrobusiness, 'label')
      );
    }
  }, [watchClient]);

  // endpoints
  useEffect(() => {
    getProfilesAsync();
    getClientsAsync();
  }, []);

  const getProfilesAsync = useCallback(async () => {
    setLoadingProfileDataState(true);
    try {
      let response = await axiosContext.authAxios.post(
        'v1/profile/list-profiles'
      );

      const { data, status } = response;

      if (status === 200) {
        let dataProfiles = alphabeticalSort(data.data, 'label');

        dataProfiles = dataProfiles.map((profile) => {
          if (profile.value === 'Administrador') {
            return {
              ...profile,
              label: `${profile.label} (JTP)`,
            };
          }
          return profile;
        });

        setProfileDataState(dataProfiles);
      }
    } catch (error) {
      console.log(error);
      // setLoadingVarietyFilterState(false);
    } finally {
      setLoadingProfileDataState(false);
    }
  }, [axiosContext, setLoadingProfileDataState, setProfileDataState]);

  const getClientsAsync = useCallback(async () => {
    setLoadingClientDataState(true);
    try {
      let response = await axiosContext.authAxios.post(
        'v1/client/list-clients',
        {
          limit: 500,
        }
      );

      // console.log(response, '<<----respo clientes');

      const { data, status } = response;

      if (status === 200) {
        setClientDataState(alphabeticalSort(data.data, 'label'));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingClientDataState(false);
    }
  }, [axiosContext, setLoadingClientDataState, setClientDataState]);

  const handleCloseSnackBarsComponent = () => {
    setErrorState(false);
  };

  useEffect(() => {
    // console.log(watchProfile, '----watchProfile');
    if (watchProfile && watchProfile === 'Administrador_Panel') {
      setHideClientDataState(true);
    } else {
      setHideClientDataState(false);
    }
  }, [watchProfile]);

  const onCloseSnack = () => {
    setSuccessQueryState(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-0 px-md-3'>
        <BsRow class='no-gutters'>
          <BsCol class='col-md-12'>
            <Card elevation={0}>
              <CardHeaderWidget
                title={viewsTexts.titles.user.title}
                subheader={<BreadCrumbs subTitle='' />}
              />
              {/* <Divider /> */}
              <CardContent>
                <BsRow>
                  <BsCol class='col-md-12'>
                    <form autoComplete='on' onSubmit={handleSubmit(onSubmit)}>
                      <BsRow>
                        <BsCol class='col-md-12'>
                          <TextFieldWidgetSelectControllerHorizontal
                            options={profileDataState}
                            titleOption='label'
                            control={control}
                            name='profile'
                            labeltext='Perfil'
                            errors={errors.profile}
                            defaultValue=''
                            loading={loadingProfileDataState}
                            req
                            withDefault
                            variant='outlined'
                          />
                        </BsCol>
                      </BsRow>
                      {!hideClientDataState ? (
                        <BsRow>
                          <BsCol class='col-md-6'>
                            <TextFieldWidgetSelectControllerHorizontal
                              options={clientDataState}
                              titleOption='label'
                              control={control}
                              name='client'
                              labeltext='Cliente'
                              errors={errors.client}
                              defaultValue=''
                              loading={loadingClientDataState}
                              req
                              withDefault
                              variant='outlined'
                            />
                          </BsCol>
                          <BsCol class='col-md-6'>
                            <TextFieldWidgetSelectControllerHorizontal
                              options={agroBusinessDataState}
                              titleOption='label'
                              control={control}
                              name='agroBusiness'
                              labeltext='Agrícola'
                              errors={errors.agroBusiness}
                              multiple={true}
                              defaultValue={[]}
                              loading={false}
                              req={false}
                              withDefault
                              variant='outlined'
                            />
                          </BsCol>
                        </BsRow>
                      ) : null}
                      <BsRow>
                        <BsCol class='col-md-12'>
                          {/* <TextFieldWidgetText
												name="rut"
												autoComplete="rut"
												labeltext={viewsTexts.forms.rut}
												placeholder="11.111.111-1"
												InputLabelProps={{
													shrink: true,
												}}
												req={true}
												defaultValue=""
												control={control}
												errors={errors.rut}
											/> */}
                          <TextFieldWidgetText
                            name='userName'
                            labeltext={viewsTexts.forms.userName}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            req={true}
                            defaultValue=''
                            autoComplete='off'
                            minLength={3}
                            maxLength={12}
                            control={control}
                            errors={errors.userName}
                          />
                        </BsCol>
                        {/* <BsCol class="col-md-6"></BsCol> */}
                      </BsRow>
                      <BsRow>
                        <BsCol class='col-md-6'>
                          <TextFieldWidgetText
                            name='name'
                            labeltext={viewsTexts.forms.name}
                            req={false}
                            defaultValue=''
                            InputLabelProps={{
                              shrink: true,
                            }}
                            minLength={3}
                            maxLength={100}
                            control={control}
                            errors={errors.name}
                            autoComplete='given-name'
                          />
                        </BsCol>
                        <BsCol class='col-md-6'>
                          <TextFieldWidgetText
                            name='lastName'
                            labeltext={viewsTexts.forms.lastName}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            req={false}
                            defaultValue=''
                            minLength={3}
                            maxLength={100}
                            control={control}
                            autoComplete='family-name'
                            errors={errors.lastName}
                          />
                        </BsCol>
                      </BsRow>
                      <BsRow>
                        <BsCol class='col-md-6'>
                          <TextFieldWidgetText
                            name='email'
                            labeltext={viewsTexts.forms.email}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            req={false}
                            defaultValue=''
                            control={control}
                            errors={errors.email}
                            autoComplete='email'
                          />
                        </BsCol>
                        <BsCol class='col-md-6'>
                          <TextFieldWidgetText
                            name='phone'
                            type='number'
                            labeltext={viewsTexts.forms.phone}
                            placeholder='56999999999'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            req={false}
                            defaultValue=''
                            minLength={8}
                            maxLength={11}
                            control={control}
                            autoComplete='phone'
                            errors={errors.phone}
                          />
                        </BsCol>
                      </BsRow>
                      <BsRow>
                        <BsCol class='col-md-6'>
                          <TextFieldWidgetText
                            name='password'
                            req={true}
                            minLength={6}
                            maxLength={12}
                            defaultValue=''
                            autoComplete='new-password'
                            type={showPassword ? 'text' : 'password'}
                            labeltext={viewsTexts.forms.password}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            control={control}
                            errors={errors.password}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton
                                    aria-label='toggle password visibility'
                                    color='default'
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </BsCol>
                        <BsCol class='col-md-6'>
                          <TextFieldWidgetText
                            name='password2'
                            equalto={password}
                            req={true}
                            type={showPassword ? 'text' : 'password'}
                            defaultValue=''
                            autoComplete='new-password'
                            minLength={6}
                            maxLength={12}
                            labeltext={viewsTexts.forms.password2}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            control={control}
                            errors={errors.password2}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton
                                    aria-label='toggle password visibility'
                                    color='default'
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </BsCol>
                      </BsRow>
                      <BsRow class='mt-5'>
                        <BsCol class='col-md-12 text-center'>
                          <SaveAction
                            title='Guardar'
                            color='secondary'
                            size='large'
                            className='roundedButton ml-md-2'
                            variant='contained'
                            disabled={loadingQueryState}
                            messageType='add'
                            mutationLoading={loadingQueryState}
                            success={successQueryState}
                            actionClose={onCloseSnack}
                          />
                        </BsCol>
                      </BsRow>
                    </form>
                  </BsCol>
                </BsRow>
                {errorState && (
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    open={errorState}
                    onClose={() => setErrorState(false)}
                    autoHideDuration={4500}
                    key={Math.random()}
                  >
                    <SnackBarsComponent
                      variant='error'
                      message='El nombre de usuario ya existe'
                      handleClose={handleCloseSnackBarsComponent}
                    />
                  </Snackbar>
                )}
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};
export default UserAdd;
