import React, {
	useState,
	useEffect,
	useMemo,
	useContext,
	useCallback,
} from 'react';
import { Card, CardContent, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
	SearchBasicTable,
	DataTableWidget,
	DialogDelete,
	IconActionTableDelete,
	IconActionTableEdit,
	BreadCrumbs,
	CardHeaderWidget,
	EditionAreaWidget,
} from '../../../components';
import {
	handleActionDelete,
	concatObjectConfigDatatable,
	handleActionEditNew,
} from '../../../helpers';

import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
// import '../../../assets/scss/list.scss';
import CmEdit from '../CmEdit';
import viewsTexts from '../../../common/viewsTexts.json';
import { AxiosContext } from '../../../context/AxiosContext';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
	tableContainerStyle: {
		[theme.breakpoints.up('sm')]: {
			paddingRight: 34,
		},
	},
	selectStyle: {
		marginTop: 0,
		marginBottom: 0,
	},
	divider: {
		height: '28px',
		margin: '4px',
	},
	iconButton: {
		padding: '8px',
	},
	actionColumn: {
		display: 'flex',
		justifyContent: 'flex-start',
		width: '100%',
	},
}));

const CmList = () => {
	const theme = useTheme();

	const axiosContext = useContext(AxiosContext);

	const [loadingDataTableState, setLoadingDataTableState] = useState(false);
	const [tableDataState, setTableDataState] = useState([]);
	const [openDialogDeleteState, setOpenDialogDeleteState] = useState(false);
	const [openDialogStateState, setOpenDialogStateState] = useState(false);
	const [resetPaginationToggleState, setResetPaginationToggleState] =
		useState(false);

	const [limitState, setLimitState] = useState(10);
	const [sortState, setSortState] = useState({
		key: 'value', //
		option: 'asc', //
	});
	const [pageState, setPageState] = useState(1);
	const [countState, setCountState] = useState(0);

	const [editionState, setEditionState] = useState(false);
	const [rowToHandleState, setRowToHandleState] = useState('');
	const [rowToEditState, setRowToEditState] = useState('');
	const [modalState, setModalState] = useState({
		openDetail: false,
		detailModal: '',
	});

	const [busyState, setBusyState] = useState(false);

	const [loadingDeleteState, setLoadingDeleteState] = useState(false);
	const [successDeleteState, setSuccessDeleteState] = useState(false);

	const [loadingDataState, setLoadingDataState] = useState(false);
	const [filterTextClientState, setFilterTextClientState] = useState(null);
	const [formClientState, setFormClientState] = useState('');

	const classes = useStyles();

	const customStyles = {
		headCells: {
			style: {
				color: 'white',
				backgroundColor: theme.palette.primary.light,
				'&: nth-last-child(1)': {
					backgroundColor: theme.palette.primary.main,
					borderBottom: '4px solid',
					borderColor: theme.palette.secondary.light,
				},
				div: {
					whiteSpace: 'normal',
					wordWrap: 'break-word',
				},
			},
		},
	};

	const searchFunc = async (filterText, type) => {
		console.log(filterText, type, '----filterText, type'); // Imprimir el valor del filtro
		let filter = {};
		if (filterText !== '' && type === 'client') {
			setFilterTextClientState(filterText);
		}
		if (filterText === '' && type === 'resetClient') {
			setFilterTextClientState(null);
			debouncedSearch('');
		}
		// console.log(filter, '----filter'); // Imprimir el valor del filtro
	};

	// console.log(filterTextClientState, '----filterTextClientState');

	const handleSearchChange = (value) => {
		// const { value } = e.target;
		setFormClientState(value);
		debouncedSearch(value);
	};

	const subHeaderComponentMemo = (
		<>
			<div className="mr-md-4">
				<SearchBasicTable
					resetFunc={() => {
						searchFunc('', 'resetClient');
						setFormClientState('');
					}}
					id="label"
					valuetext={formClientState}
					placeholder="Cliente"
					filterFunc={() => {}}
					onFilter={(value) => handleSearchChange(value)}
				/>
			</div>
		</>
	);

	const columnsConfig = useMemo(
		() => [
			{
				name: 'Cliente',
				selector: (row) => row.label,
				sortField: 'value',
				sortable: true,
				id: 1,
			},
		],
		[]
	);

	/*{
		  name: 'Estado',
		  selector: (row) => row.active,
		  cell: (row) => <LabelChip label={row ? 'Activo' : 'Inactivo'} />,
		  sortable: false,
		  id: 9,
		},*/

	const actionsConfig = [
		{
			name: 'Acciones',
			width: '150px',
			id: 11,
			cell: (row) => (
				<div className={classes.actionColumn}>
					{row.edit !== false && (
						<IconActionTableEdit
							handleActionEdit={() => {
								setRowToEditState(row);
								setEditionState(true);
							}}
						/>
					)}
					{!row.agrobusiness.length && (
						<IconActionTableDelete
							handleActionDelete={() => DialogDeleteHandler(row)}
						/>
					)}
				</div>
			),
		},
	];
	const actions = concatObjectConfigDatatable(actionsConfig[0]);
	const columns = columnsConfig.concat(actions);

	// Dentro de la función queryData
	async function queryData(
		page = pageState,
		sort = sortState,
		searchTerm = formClientState
	) {
		setLoadingDataTableState(true);
		setBusyState(true); // checkear
		try {
			let objQuery = {
				search: searchTerm,
				page: page,
				limit: limitState,
				sortColumn: sort ? sort.key : sortState.key,
				sortOrder: sort ? sort.option : sortState.option,
			};
			const response = await axiosContext.authAxios.post(
				'v1/client/list-clients',
				objQuery
			);
			const { data, status } = response;
			console.log(response, '<-----response queryData');

			if (status === 200) {
				setTableDataState(data.data); // Establece los datos de la tabla después de aplicar el filtro
				setCountState(data.count);
				setPageState(page);
				setSortState(sort);
			}
			setLoadingDataTableState(false);
		} catch (err) {
			console.log('Error:', err);
			setTableDataState([]);
		} finally {
			setBusyState(false);
			setLoadingDataTableState(false);
		}
	}

	const DialogDeleteHandler = (row) => {
		setRowToHandleState(row);
		setEditionState(false);
		setOpenDialogDeleteState(!openDialogDeleteState);
	};

	const deleteActionDialogHandler = async () => {
		setLoadingDeleteState(true);
		try {
			let objDel = {
				id: rowToHandleState.id,
			};

			const responseDel = await axiosContext.authAxios.post(
				'v1/client/delete-client',
				objDel
			);

			if (responseDel.status === 200) {
				setSuccessDeleteState(true);
				setLoadingDeleteState(false);
			}
		} catch (err) {
			console.log('-----------', err);
			setLoadingDeleteState(false);
		}
		setCountState(countState - 1);
		const newUsers = handleActionDelete(rowToHandleState, tableDataState);
		setTableDataState(newUsers);
	};

	const onCloseSnack = () => {
		setSuccessDeleteState(false);
	};

	const closeEditionAreaHandler = () => {
		setEditionState(false);
	};

	const handleActionEditObj = (newObject) => {
		// console.log('asi pasa al editAction', newObject);
		const newUsers = handleActionEditNew(newObject, tableDataState);
		//console.log('new users to table', newUsers);
		setTableDataState(newUsers);
	};

	//const searchTool = false;

	// table functions
	const handleOnSort = async (column, sortDirection) => {
		// console.log('handleSort', column, sortDirection);
		let nameColumn = column.sortField;
		// console.log(column.selector.toString().split(".")[1], "---");
		// let nameColumn = column.selector.toString().split('.')[1];

		if (nameColumn !== null) {
			console.log({
				key: nameColumn,
				option: sortDirection,
			});
			if (!busyState) {
				queryData(pageState, {
					key: nameColumn,
					option: sortDirection,
				});
			}
		}
	};

	const handleChangePagination = (page, totalRows) => {
		console.log(page, totalRows);
		// setPageState(page);
		if (!busyState) {
			queryData(page);
		}
	};

	const handleChangeRows = async (currentRowsPerPage, currentPage) => {
		setLoadingDataTableState(false);
		try {
			let objQuery = {
				page: currentPage,
				limit: currentRowsPerPage,
				sort: sortState,
			};

			console.log(objQuery, '---la query que va en handle Change');

			const response = await axiosContext.authAxios.post(
				'v1/client/list-clients',
				objQuery
			);
			const { data, status } = response;

			// console.log(response, "<-----response change rows")

			// setCountState(data.totalPassengers);
			if (status === 200) {
				// setTableDataState(data.data);
				setTableDataState(data.data);
				// setCountState(data.count);
				setLimitState(currentRowsPerPage);
			}
		} catch (err) {
			setTableDataState([]);
		} finally {
			setLoadingDataTableState(false);
		}
	};

	// const handleCloseModal = () => {
	// 	setModalState({
	// 		...modalState,
	// 		openDetail: false,
	// 	});
	// };

	// useEffect(() => {
	// 	const timeOut = setTimeout(() => {
	// 		queryData();
	// 	}, 500);
	// 	return () => clearTimeout(timeOut);
	// }, [formClientState]);

	useEffect(() => {
		queryData();
	}, []);

	const debouncedSearch = useCallback(
		debounce((value) => {
			queryData(undefined, undefined, value);
		}, 500),
		[filterTextClientState]
	);

	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-md-12">
						{editionState && (
							<EditionAreaWidget
								component={
									<CmEdit
										rowEdit={rowToEditState}
										closeFunc={closeEditionAreaHandler}
										editFunc={handleActionEditObj}
									/>
								}
								visible={editionState}
							/>
						)}
						<Card elevation={0}>
							<CardHeaderWidget
								title={viewsTexts.titles.client.list.title}
								subheader={<BreadCrumbs subTitle="" />}
							/>
							<CardContent>
								{/* <GenericToolbar search={searchTool} /> */}
								<div
									style={{
										minHeight: '300px',
									}}
								>
									<DataTableWidget
										totalRows={countState}
										handleOnSort={handleOnSort}
										fixedHeader={true}
										fixedHeaderScrollHeight={'590px'}
										// conditionalRowStyles={conditionalRowStyles}
										// sortFunction={customSort}
										changePagination={
											handleChangePagination
										} //onChangePage
										rowsChange={handleChangeRows} //onChangeRowsPerPage
										columns={columns}
										filteredItems={tableDataState}
										loading={
											loadingDataTableState ||
											loadingDataState
										}
										sortField="1"
										subHeaderComponentMemo={
											subHeaderComponentMemo
										}
										loadingCount={loadingDataTableState}
										customStyles={customStyles}
										moduleName="Clientes"
										resetPaginationToggle={
											resetPaginationToggleState
										}
									/>
								</div>
								<DialogDelete
									openDialog={openDialogDeleteState}
									rowDelete={rowToHandleState}
									actionDelete={deleteActionDialogHandler}
									variant="success"
									loading={loadingDeleteState}
									success={
										successDeleteState
											? successDeleteState
											: undefined
									}
									openState={setOpenDialogDeleteState}
									moduleName="Clientes"
									actionClose={onCloseSnack}
								/>
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};

export default CmList;
