import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
	// DataTableWidget,
	SaveAction,
	TextFieldWidgetSelectControllerHorizontal,
	TextFieldWidgetText,
	CardHeaderWidget,
	BreadCrumbs,
	SnackBarsComponent,
} from '../../../components';

import { Card, CardContent, useTheme, Snackbar, Alert } from '@mui/material';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';
import { makeStyles } from '@mui/styles';
import viewsTexts from '../../../common/viewsTexts.json';
import { alphabeticalSort, alterArrayGetFields } from '../../../helpers';
// import theme from 'theme';
import { FiltersContext } from '../../../context';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.tertiary.main,
		fontWeight: 500,
	},
	divider: {
		background: theme.palette.grey[400],
		marginTop: 10,
	},
	rootHelper: {
		fontSize: 17,
	},
	inputText: {
		fontSize: 26,
	},
}));

const AmAdd = (props) => {
	const { userId, userHuerto, userName, userOrchard } = useContext(Context);

	// console.log(userId, userHuerto, userName, "<<<<---context")
	const { state: filterState, addFilter } = useContext(FiltersContext);

	const {
		// orchard_filter,
		// cc_filter,
		// quarter_filter,
		// variety_filter,
		// season_filter,
		userOrchard_filter,
	} = filterState;

	// console.log('----', filterState);

	const axiosContext = useContext(AxiosContext);
	// axiosContext.authAxios //queries with token

	const [filterDataResponseState, setFilterDataResponseState] =
		useState(userOrchard_filter);

	const [loadingQueryState, setLoadingQueryState] = useState(false);
	const [successQueryState, setSuccessQueryState] = useState(false);

	const [errorState2, setErrorState2] = useState(false);

	const [clientDataState, setClientDataState] = useState([]);
	const [agroBusinessDataState, setAgroBusinessDataState] = useState([]);

	const [errorState, setErrorState] = useState(false);

	const [loadingClientDataState, setLoadingClientDataState] = useState(false);

	const theme = useTheme();

	// console.log(theme)

	const classes = useStyles();

	const [valueTabState, setValueTabState] = useState(0);

	const handleChangeTab = (event, newValue) => {
		setValueTabState(newValue);
	};

	const {
		handleSubmit,
		control,
		watch,
		reset,
		// setValue,
		getValues,
		// setError,
		// clearErrors,
		formState: { errors },
	} = useForm();

	// const watchRut = watch('rut');
	const watchClient = watch('client');
	const agroBusinesses = Array.from({ length: 10 }, (_, i) =>
		watch(`agroBusiness${i}`)
	);

	const onSubmit = async (values) => {
		console.log(values, '<<<--------');

		setLoadingQueryState(true);

		const clientObj = clientDataState.find(
			(ele) => ele.value === values.client
		);

		const clientFields = alterArrayGetFields(
			[clientObj],
			['id', 'label', 'value']
		);

		const agroBusinessValues = [];
		for (let i = 0; i < 10; i++) {
			const fieldName = `agroBusiness${i}`;
			if (values[fieldName]) {
				agroBusinessValues.push(values[fieldName]);
			}
		}

		const isAnyAgroBusinessFilled = agroBusinesses.some(
			(value) => value && value.trim() !== ''
		);
		if (!isAnyAgroBusinessFilled) {
			console.log('No hay agrícolas llenados');
			setErrorState2(true);
			setLoadingQueryState(false); // Detener el estado de carga ya que no se realizará ninguna consulta
			return;
		}

		console.log(agroBusinessValues, '--------agroBusinessValues');

		// validar si existen strings iguales en agroBusinessValues

		const agroBusinessValuesSet = new Set(agroBusinessValues);
		if (agroBusinessValuesSet.size !== agroBusinessValues.length) {
			console.log('Hay strings iguales en agroBusinessValues');
			setErrorState2(true);
			setLoadingQueryState(false); // Detener el estado de carga ya que no se realizará ninguna consulta
			return;
		}

		const query = {
			agroBusiness: agroBusinessValues,
			client: clientFields[0].label, // Usa el primer elemento de clientFields
			clientValue: clientFields[0].value, // Usa el primer elemento de clientFields
		};

		try {
			const response = await axiosContext.authAxios.post(
				'v1/agrobusiness/create-agrobusiness-platform',
				query
			);
			const bodyInfo = response.data;

			// 	// setProfileSelectedState('');
			// 	console.log(response, '<-----response');
			// 	// let response;
			console.log(response, '------response');

			if (response.status === 200) {
				setSuccessQueryState(true);

				reset({
					agroBusiness: '',
					client: '',
					clientValue: '',
				});
			}
		} catch (err) {
			console.log('Error', err);
			if (err.request.status === 500) {
				setErrorState(true);
			}
		} finally {
			setLoadingQueryState(false);
		}
	};

	useEffect(() => {
		getClientsAsync();
	}, []);

	const getClientsAsync = useCallback(async () => {
		setLoadingClientDataState(true);
		try {
			let response = await axiosContext.authAxios.post(
				'v1/client/list-clients',
				{
					limit: 500,
				}
			);

			// console.log(response, '<<----respo clientes');

			const { data, status } = response;

			if (status === 200) {
				setClientDataState(alphabeticalSort(data.data, 'label'));
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoadingClientDataState(false);
		}
	}, [axiosContext, setLoadingClientDataState, setClientDataState]);

	const handleCloseSnackBarsComponent = () => {
		setErrorState(false);
	};
	const handleCloseSnackBarsComponent2 = () => {
		setErrorState2(false);
	};

	const onCloseSnack = () => {
		setSuccessQueryState(false);
	};

	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-md-12">
						<Card elevation={0}>
							<CardHeaderWidget
								title={viewsTexts.titles.agrobusiness.title}
								subheader={<BreadCrumbs subTitle="" />}
							/>
							{/* <Divider /> */}
							<CardContent>
								<BsRow>
									<BsCol class="col-md-12">
										<form
											autoComplete="on"
											onSubmit={handleSubmit(onSubmit)}
										>
											<BsRow>
												<BsCol class="col-md-12">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															clientDataState
														}
														titleOption="label"
														control={control}
														name="client"
														labeltext="Cliente"
														errors={errors.client}
														defaultValue=""
														loading={
															loadingClientDataState
														}
														req
														withDefault
														variant="outlined"
													/>
												</BsCol>
											</BsRow>
											<BsRow>
												{[...Array(10)].map((_, i) => (
													<BsCol
														class="col-md-6"
														key={i}
													>
														<TextFieldWidgetText
															InputLabelProps={{
																shrink: true,
															}}
															name={`agroBusiness${i}`}
															labeltext={`Agrícola ${
																i + 1
															}`}
															req={i === 0}
															control={control}
															errors={
																errors[
																	`agroBusiness${i}`
																]
															}
															defaultValue=""
															disabled={false}
														/>
													</BsCol>
												))}
											</BsRow>
											<BsRow class="mt-5">
												<BsCol class="col-md-12 text-center">
													<SaveAction
														title="Guardar"
														color="secondary"
														size="large"
														className="roundedButton ml-md-2"
														variant="contained"
														disabled={
															loadingQueryState
														}
														messageType="add"
														mutationLoading={
															loadingQueryState
														}
														success={
															successQueryState
														}
														actionClose={
															onCloseSnack
														}
													/>
												</BsCol>
											</BsRow>
										</form>
									</BsCol>
								</BsRow>
								{errorState && (
									<Snackbar
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
										open={errorState}
										onClose={() => setErrorState(false)}
										autoHideDuration={4500}
										key="error-snackbar"
									>
										{/* <SnackBarsComponent
											variant="error"
											message="El nombre de agrícola ya existe"
											handleClose={
												handleCloseSnackBarsComponent
											}
										/> */}
										<Alert
											onClose={
												handleCloseSnackBarsComponent
											}
											severity="error"
											variant="filled"
											sx={{ width: '100%' }}
										>
											El nombre de agrícola ya existe
										</Alert>
									</Snackbar>
								)}
								{errorState2 && (
									<Snackbar
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
										open={errorState2}
										onClose={() => setErrorState2(false)}
										autoHideDuration={4500}
										key="error-snackbar2"
									>
										<Alert
											onClose={
												handleCloseSnackBarsComponent2
											}
											severity="error"
											variant="filled"
											sx={{ width: '100%' }}
										>
											Hay al menos una agrícola repetida
										</Alert>
									</Snackbar>
								)}
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};
export default AmAdd;
