import React from 'react';
import CommentIcon from '@mui/icons-material/Comment';
import { IconButton, Tooltip } from '@mui/material';
import '../style.scss';
const IconActionTableDetail = (props) => {
	const { handleActionDetail } = props;
	//<IconButton aria-label="delete" onClick={() => handleActionDetail(row)}>
	return (
		<Tooltip title="Detalle" arrow className="buttonIconAction">
			<IconButton
				aria-label="detail"
				onClick={handleActionDetail}
				sx={{
					color: 'white',
					backgroundColor: 'secondary.main',
					'&:hover': {
						backgroundColor: 'primary.dark',
					},
					borderRadius: 1,
					padding: 1,
					margin: 0.5,
				}}
			>
				<CommentIcon fontSize="small" />
			</IconButton>
		</Tooltip>
	);
};

export default IconActionTableDetail;
