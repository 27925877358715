import React, { useContext, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
	Toolbar,
	// Hidden,
	IconButton,
	// useTheme,
	Avatar,
	Menu,
	Button,
	ListItemIcon,
	ListItemText,
	Divider,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import CHERRY_ICON from '../../../../assets/icons/species/icono-cereza.png';
// import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SettingsIcon from '@mui/icons-material/Settings';
import { Context } from '../../../../context/Context';
import { FiltersContext } from '../../../../context/filters/FiltersContext';

// import Typography from 'theme/typography';
import { IsDesktopHandler } from '../../../../helpers';

const drawerWidth = 240;
const linkParams = '/params';

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const useStyles = makeStyles((theme) => ({
	// appBar: {
	//   zIndex: theme.zIndex.drawer - 11 + ' !important',
	//   // paddingLeft: theme.spacing(8) + 1,
	//   [theme.breakpoints.up('sm')]: {
	//     paddingLeft: parseInt(theme.spacing(8)) + 1,
	//   },
	//   transition: theme.transitions.create(['width', 'margin'], {
	//     easing: theme.transitions.easing.sharp,
	//     duration: theme.transitions.duration.leavingScreen,
	//   }),
	// },
	// appBarShift: {
	//   // marginLeft: drawerWidth,
	//   // width: `calc(100% - ${drawerWidth}px)`,
	//   transition: theme.transitions.create(['width', 'margin'], {
	//     easing: theme.transitions.easing.sharp,
	//     duration: theme.transitions.duration.enteringScreen,
	//   }),
	// },
	// menuButton: {
	//   marginRight: 36,
	// },
	// rightBar: {
	//   display: 'flex',
	//   flexGrow: 1,
	//   justifyContent: 'space-between',
	// },
	// buttonProfile: {
	//   display: 'flex',
	//   flexDirection: 'column',
	//   lineHeight: 1.2,
	//   alignItems: 'flex-start',
	//   '&& div': {
	//     fontSize: 14,
	//     fontWeight: 700,
	//   },
	//   '&& span': {
	//     fontSize: 12,
	//   },
	// },
	topBarContainerStyle: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	buttonLogoutStyle: {
		color: 'white',
		textTransform: 'none',
		borderColor: 'white',
	},
	specieButtonStyle: {
		color: 'white',
		backgroundColor: 'rgba(255,255,255,0.2)',
		borderRadius: theme.spacing(8),
		textTransform: 'none',
		border: 'solid',
		borderColor: theme.palette.primary.dark,
		borderWidth: 2,
		'&:hover': {
			backgroundColor: 'rgba(255,255,255,0)',
		},
	},
}));

const Topbar = (props) => {
	const { className, handleSidebarOpen, openState, ...rest } = props;

	const {
		// projectSelected,
		// projectList,
		// setProjectSelected,
		userFullname,
		logout,
	} = useContext(Context);

	// console.log(userFullname);

	const theme = useTheme();

	console.log('LAYOUT: --Topbar', theme);

	const [anchorElState, setAnchorElState] = useState(null);

	const classes = useStyles();
	// const theme = useTheme();
	// const context = useContext(Context);
	const { logOutFilters } = useContext(FiltersContext);

	const history = useHistory();

	const handleTopClick = (event) => {
		setAnchorElState(event.currentTarget);
	};

	const handleTopClose = () => {
		setAnchorElState(null);
	};

	// const paramsHandler = () => {
	//   history.push({
	//     pathname: linkParams,
	//   });
	//   handleTopClose();
	//   // console.log('params');
	// };

	console.log(IsDesktopHandler('sm'), 'IsDesktopHandler(sm)');

	const renderButtons = () => {
		if (!IsDesktopHandler('sm')) {
			return (
				<div
					className={classes.topBarContainerStyle}
					style={{
						flex: 1,
					}}
				>
					<Button
						variant="contained"
						className={classes.specieButtonStyle}
						startIcon={
							<Avatar
								alt="Cerezas"
								src={'CHERRY_ICON'}
								sx={{ width: 32, height: 32 }}
							/>
						}
						endIcon={<KeyboardArrowDownIcon />}
						disableElevation
					>
						Cereza
					</Button>
				</div>
			);
		}

		return (
			<div
				className={classes.topBarContainerStyle}
				style={{
					flex: 1,
				}}
			>
				{/* <Button
					variant="contained"
					className={classes.specieButtonStyle}
					startIcon={
						<Avatar
							alt="Cerezas"
							src={'CHERRY_ICON'}
							sx={{ width: 32, height: 32 }}
						/>
					}
					endIcon={<KeyboardArrowDownIcon />}
					disableElevation
				>
					Cereza
				</Button> */}
				<Divider
					orientation="vertical"
					variant="middle"
					light
					flexItem
					sx={{ mx: 3 }}
				/>
				<div>
					<Button
						className={classes.buttonLogoutStyle}
						variant="outlined"
						disableElevation
						onClick={() => {
							handleTopClose();
							logOutFilters();
							logout();
						}}
						startIcon={<LogoutIcon />}
					>
						Salir
					</Button>
				</div>
			</div>
		);
	};

	return (
		<AppBar position="fixed" open={openState} color="primary" elevation={0}>
			<Toolbar>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={handleSidebarOpen}
					edge="start"
					sx={{
						marginRight: 5,
						...(openState && { display: 'none' }),
					}}
				>
					<MenuIcon />
				</IconButton>
				{renderButtons()}
			</Toolbar>
		</AppBar>
	);
};

export default Topbar;
