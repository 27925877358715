import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import classes from './CloseButtonEditionArea.module.scss';

const CloseButtonEditionArea = (props) => (
	<IconButton className={classes.CloseButton} size="medium" onClick={props.closeFunc} aria-label="cerrar" component="span">
		<CloseIcon fontSize="small" />
	</IconButton>
);

export default CloseButtonEditionArea;
