import React, { useContext, useEffect, useReducer } from 'react';
import { FiltersContext } from './FiltersContext';
import { AxiosContext } from '../AxiosContext';
import { Context } from '../Context';
import { filtersReducer } from './filtersReducer';
// import axios from 'axios';

const INITIAL_STATE = {
	isLoading: true,
	userOrchard_filter: [],
	orchard_filter: null,
	orchard_id_filter: null,
	cc_filter: null,
	quarter_filter: null,
	variety_filter: null,
	rootstock_filter: null,
	season_filter: null,
	client_filter: null,
	season_since_filter: null,
	season_until_filter: null,
};

export const FiltersProvider = ({ children }) => {
	const [state, dispatch] = useReducer(filtersReducer, INITIAL_STATE, () => {
		// const localState = localStorage.getItem('@session'); //se mantiene aunque cierre el navegador
		const localState = window.sessionStorage.getItem('@filters'); // se limpia cuando cierra nevagador
		return localState ? JSON.parse(localState) : INITIAL_STATE;
	});

	const { token, userOrchard } = useContext(Context);
	const axiosContext = useContext(AxiosContext);

	// useEffect(() => {
	// acción inicial
	// getUserLocation().then((lngLat) =>
	// 	dispatch({ type: 'setUserOrchards', payload: lngLat })
	// );
	// }, []);

	// const setUserOrchards = (payload) => {
	// 	dispatch({ type: 'set_user_orchard', payload: payload });
	// };

	const addFilter = (filter) => {
		dispatch({ type: 'add_filter', payload: filter });
	};

	// const getArrayofIds = (array) => {
	// 	console.log('getArrayofIds----------', array);
	// 	const newArray = array.map((item) => {
	// 		return item.orchard;
	// 	});
	// 	return newArray;
	// };

	// const getMyOrchardsInfo = async (userOrch) => {
	// 	try {
	// 		const bodyPost = {
	// 			orchard: userOrch,
	// 			// clientValue: userClient?.value || null,
	// 		};

	// 		const response = await axiosContext.authAxios.post(
	// 			'v1/orchard',
	// 			bodyPost
	// 		);

	// 		const { data, status } = response;

	// 		const array_id_resume = (array) => {
	// 			const newArray = array.map((item) => {
	// 				return {
	// 					agroBusinessFilter: item.agroBusinessFilter,
	// 					_id: item.id,
	// 					id: item.id,
	// 					label: item.label,
	// 					value: item.value,
	// 					client: item.client,
	// 					clientValue: item.clientValue,
	// 					specie: item.specie,
	// 				};
	// 			});
	// 			return newArray;
	// 		};

	// 		// console.log('-------response---ORCHARD--------------->', response);
	// 		dispatch({
	// 			type: 'set_user_orchard',
	// 			payload: array_id_resume(data),
	// 		});
	// 	} catch (error) {
	// 		//   console.log(error, '--------error');
	// 	} finally {
	// 		dispatch({ type: 'is_loading_orchards', payload: false });
	// 	}
	// };

	const logOutFilters = () => {
		dispatch({ type: 'log_out', payload: INITIAL_STATE });
	};

	// useEffect(() => {
	// 	if (token && userOrchard && state.isLoading) {
	// 		// solo lo ejecuta, cuando no hay estado en el navegador
	// 		// console.log('ufe-------state----------', userOrchard, '---', state);
	// 		getMyOrchardsInfo(userOrchard);
	// 	}
	// }, [token]);

	useEffect(() => {
		// localStorage.setItem('@session', JSON.stringify(state));
		window.sessionStorage.setItem('@filters', JSON.stringify(state));
	}, [state]);

	return (
		<FiltersContext.Provider
			value={{
				state,
				// setUserOrchards,
				// getMyOrchardsInfo,
				addFilter,
				logOutFilters,
			}}
		>
			{children}
		</FiltersContext.Provider>
	);
};
