import React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	success: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.common.white,
	},
	outlined: {
		borderColor: theme.palette.grey[300],
		color: theme.palette.grey[500],
	},
}));

const LabelChip = (props) => {
	const { label } = props;
	const classes = useStyles();

	return (
		<Chip
			label={label}
			className={label === 'Activo' ? classes.success : classes.outlined}
			variant={label === 'Activo' ? 'filled' : 'outlined'}
		/>
	);
};

export default LabelChip;
