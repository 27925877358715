import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip } from '@mui/material';
import '../style.scss';

const IconActionTableEdit = (props) => {
	const { handleActionEdit } = props;

	return (
		<Tooltip title="Editar" arrow className="buttonIconAction">
			<IconButton
				sx={{
					color: 'white',
					backgroundColor: 'warning.main',
					'&:hover': {
						backgroundColor: 'primary.dark',
					},
					borderRadius: 1,
					padding: 1,
					margin: 0.5,
				}}
				aria-label="edit"
				onClick={handleActionEdit}
			>
				<EditIcon fontSize="small" />
			</IconButton>
		</Tooltip>
	);
};

export default IconActionTableEdit;
