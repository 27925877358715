import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { AG_GRID_LOCALE_ES } from "@ag-grid-community/locale";
import { AgGridReact } from "@ag-grid-community/react";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import ClearIcon from "@mui/icons-material/Clear";
import DownloadIcon from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import {
  BreadCrumbs,
  ButtonAction,
  CardHeaderWidget,
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget2,
} from "../../../components";
import { alterArrayAddLabels, dashAdd } from "../../../helpers";
import { FormPrompt } from "../../../helpers/FormPrompt";

import { useForm } from "react-hook-form";
import viewsTexts from "../../../common/viewsTexts.json";
import { AxiosContext } from "../../../context/AxiosContext";
import { Context } from "../../../context/Context";
import { BsCol, BsContainer, BsRow } from "../../../layouts/components";
// import { formatters, validators } from '../formatter_validator';
import { FIELDS, formatters, validators } from "../fieldsConfig";
import "../styles.css";
import { FiltersContext } from "../../../context";
import { createColumnDef, handleCellValueChanged } from "../agGridHelpers";

const useStyles = makeStyles((theme) => ({
  tableContainerStyle: {
    [theme.breakpoints.up("sm")]: {
      paddingRight: 34,
    },
  },
  selectStyle: {
    marginTop: 0,
    marginBottom: 0,
  },
  divider: {
    height: "28px",
    margin: "4px",
  },
  iconButton: {
    padding: "8px",
  },
  actionColumn: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
}));

ModuleRegistry.registerModules([
  FiltersToolPanelModule,
  ClientSideRowModelModule,
  RangeSelectionModule,
  MenuModule,
  MultiFilterModule,
  MasterDetailModule,
  ColumnsToolPanelModule,
  CsvExportModule,
  ExcelExportModule,
  SetFilterModule,
  ClipboardModule,
]);

const endPointFilterName = "v1/collection-bd/filter";
const endPointName = "v1/collection-bd/reception";
const endPointNamePost = "v1/collection-bd/reception/create";

const titleModule = "Water Analysis";

const Reception = () => {
  const theme = useTheme();

  const axiosContext = useContext(AxiosContext);

  const { userId, userHuerto, userName, userOrchard } = useContext(Context);

  const { state: filterState, addFilter } = useContext(FiltersContext);

  const [filterOptionsState, setFilterOptionsState] = useState(null);

  const gridApiRef = useRef(null);

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    params.api.sizeColumnsToFit();
  };

  const localeText = AG_GRID_LOCALE_ES;

  const {
    client_filter,
    orchard_filter,
    season_since_filter,
    season_until_filter,
  } = filterState;

  // TABLA
  const [confirmPromptState, setConfirmPromptState] = useState(false);

  const [tableContainerStyleState, setTableContainerStyleState] = useState({
    // height: '80vh',
    width: "100%",
    minHeight: 800,
  });

  const [tableStyleState, setTableStyleState] = useState({
    height: 700,
  });

  const [fullScreenState, setFullScreenState] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const [visibleColumnsState, setVisibleColumnsState] = useState([]);

  const [titleCheckState, setTitleCheckState] = useState(
    "Estimación Diaria Confirmada"
  );

  const [copyButtonState, setCopyButtonState] = useState(false);

  const [rowData, setRowData] = useState([]);
  const paginationPageSizeSelector = [20, 50, 100, 500, 1000];

  const [quickFilterText, setQuickFilterText] = useState(""); // estado para el filtro de busquedageneral

  const [dataTableState, setDataTableState] = useState([]);

  const [loadingSubmitFilterState, setLoadingSubmitFilterState] =
    useState(false);

  const [loadingSubmitState, setLoadingSubmitState] = useState(false);

  const [loadingFilterDataState, setLoadingFilterDataState] = useState(false);

  const [clientOptionsState, setClientOptionsState] = useState([]);

  const [successSubmitState, setSuccessSubmitState] = useState(false);

  const [orchardsOptionsState, setOrchardsOptionsState] = useState([]);

  const [seasonOptionsState, setSeasonOptionsState] = useState([]);

  const [openDialogState, setOpenDialogState] = useState(false);

  const [noMessageKilosState, setNoMessageKilosState] = useState(false);

  const [openWarningState, setOpenWarningState] = useState(false);
  const [openErrorState, setOpenErrorState] = useState(false);
  const [errorDataState, setErrorDataState] = useState(null);

  const [cellState, setCellState] = useState({});

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    reset,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm();

  // crear un ref de alert
  const alertRef = useRef(false);

  const classes = useStyles();

  const handleClickOpenDialog = () => {
    setOpenDialogState(true);
  };

  const watchClient = watch("client", "");
  const watchOrchard = watch("orchard", "");
  const watchSince = watch("seasonSince", "");
  const watchUntil = watch("seasonUntil", "");

  useEffect(() => {
    clearErrors("incorrectDates");
  }, [watchSince, watchUntil]);

  const onSubmit = async (values) => {
    if (alertRef.current) {
      handleClickOpenDialog();
      return;
    }

    setNoMessageKilosState(false);

    const seasonBeginFormat = values?.seasonSince?.value;
    // const seasonEndFormat = seasonFormatIso(seasonEnd);
    const seasonEndFormat = values?.seasonUntil?.value;

    if (seasonBeginFormat > seasonEndFormat) {
      // console.log(dateBeginFormat, dateEndFormat, '<<<<<------------');
      setError("incorrectDates", {
        type: "manual",
        message: "Fecha hasta debe ser mayor a desde",
      });
      return;
    }

    setDataTableState([]);
    setLoadingSubmitFilterState(true);

    const dataQuery = {
      clientValue: values.client.value,
      orchardValue: values.orchard.value,
      seasonSince: seasonBeginFormat,
      seasonUntil: seasonEndFormat,
    };

    console.log(dataQuery, "<<<<<-------------------dataQuery");

    // const data = YIELD_DATA;

    // // MOCKS
    // setDataTableState(data);

    // setVisibleColumnsState(finalColumns);

    // const rowDataFullTransform = processDataRowDetailFull(data);

    // setRowData(rowDataFullTransform);

    // setLoadingSubmitFilterState(false);
    addFilter({
      orchard_filter: values.orchard.value,
      client_filter: values.client.value,
      season_since_filter: seasonBeginFormat,
      season_until_filter: seasonEndFormat,
    });

    try {
      const response = await axiosContext.authAxios.post(
        endPointName,
        dataQuery
      );

      const { data, status } = response;

      // console.log(data, '<<<<<-------------------', paramsQuery);

      if (status === 200) {
        setDataTableState(data);

        setVisibleColumnsState(finalColumns);

        const rowDataFullTransform = processDataRowDetailFull(data);

        setRowData(rowDataFullTransform);
      }
    } catch (error) {
    } finally {
      setLoadingSubmitFilterState(false);
    }
  };

  const handleQuickFilterChange = (event) => {
    setQuickFilterText(event.target.value);
  };

  const clearFocusSelection = () => {
    if (gridApiRef.current && gridApiRef.current) {
      gridApiRef.current.clearFocusedCell();
    }

    // console.log('1-clearFocusSelection');

    setConfirmPromptState(true);
  };

  // procesa las filas con su detalle histórico
  const processDataRowDetailFull = (data) => {
    // const { table } = data;
    const rows = [];

    data.forEach((item) => {
      const row = {
        id: `${item.id}_${item.idUnitProductive}`,
        client: item.client,
        clientValue: item.clientValue,
        season: item?.season,
        specie: item.specie,
        specieValue: item.specieValue,
        agroBusiness: item.agroBusiness,
        agroBusinessValue: item.agroBusinessValue,
        orchard: item.orchard,
        orchardValue: item.orchardValue,
        variety: item.variety,
        varietyValue: item.varietyValue,
        idUnitProductive: item.idUnitProductive,
        cc: item.cc,
        ccValue: item.ccValue,
        quarter: item.quarter,
        quarterValue: item.quarterValue,
        rootStock: item.rootStock,
        rootStockValue: item.rootStockValue,
        fDeformed: item.fDeformed,
        fDInsect: item.fDInsect,
        fDTrips: item.fDTrips,
        fDScale: item.fDScale,
        fSoftSide: item.fSoftSide,
        fWithoutPedicel: item.fWithoutPedicel,
        fDoble: item.fDoble,
        fWoundScarred: item.fWoundScarred,
        fStains: item.fStains,
        fHalfMoon: item.fHalfMoon,
        mixVar: item.mixVar,
        fRusset: item.fRusset,
        fWound: item.fWound,
        waste: item.waste,
        fDehyd: item.fDehyd,
        pedDehyd: item.pedDehyd,
        fDBird: item.fDBird,
        fSoft: item.fSoft,
        fWoundOpen: item.fWoundOpen,
        fStartDehy: item.fStartDehy,
        pedStartDehy: item.pedStartDehy,
        fBruising: item.fBruising,
        fStainPudrition: item.fStainPudrition,
        fCracksSide: item.fCracksSide,
        fCracksWater: item.fCracksWater,
        fCracks: item.fCracks,
        fPitting: item.fPitting,
        fPudBlack: item.fPudBlack,
        fPudBrown: item.fPudBrown,
        fPudrition: item.fPudrition,
      };

      rows.push(row);
    });
    // console.log(rows, '<<<<<--------------rowsTable');

    return rows;
  };

  const resetFilters = () => {
    if (gridApiRef && gridApiRef.current) {
      gridApiRef.current.setFilterModel(null);
      gridApiRef.current.resetColumnState();
    }
  };

  const handleClickAlert = (type, errorData) => {
    if (type === "warning") {
      setErrorDataState(errorData);
      setOpenWarningState(true);
    } else {
      setErrorDataState(errorData);
      setOpenErrorState(true);
    }
  };

  const handleCloseWarningAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenWarningState(false);
    setErrorDataState(null);
  };

  const handleCloseErrorAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorState(false);
    setErrorDataState(null);
  };

  //#region FINAL COLUMNS

  const finalColumns = [
    {
      colId: "caracter",
      // headerGroup: 'caracter',
      headerName: "Caracterización",
      children: [
        {
          headerName: "ID Unidad Productiva",
          field: "idUnitProductive",
          editable: false,
          suppressFillHandle: true,
          filter: "agMultiColumnFilter",
          suppressMovable: true,
          // rowGroup: false,
          enableRowGroup: true,
          filterParams: {
            filters: [
              {
                filter: "agTextColumnFilter",
                filterParams: {
                  defaultOption: "contains",
                  filterOptions: ["contains"],
                },
              },
              {
                filter: "agSetColumnFilter",
                filterParams: {
                  suppressMiniFilter: true,
                },
              },
            ],
          },
        },
        {
          headerName: "Cliente",
          field: "client",
          editable: false,
          suppressFillHandle: true,
          filter: "agMultiColumnFilter",
          suppressMovable: true,
          // rowGroup: false,
          enableRowGroup: true,
          filterParams: {
            filters: [
              {
                filter: "agTextColumnFilter",
                filterParams: {
                  defaultOption: "contains",
                  filterOptions: ["contains"],
                },
              },
              {
                filter: "agSetColumnFilter",
                filterParams: {
                  suppressMiniFilter: true,
                },
              },
            ],
          },
        },
        {
          headerName: "Agricola",
          field: "agroBusiness",
          editable: false,
          suppressFillHandle: true,
          filter: "agMultiColumnFilter",
          suppressMovable: true,
          // rowGroup: false,
          enableRowGroup: true,
          filterParams: {
            filters: [
              {
                filter: "agTextColumnFilter",
                filterParams: {
                  defaultOption: "contains",
                  filterOptions: ["contains"],
                },
              },
              {
                filter: "agSetColumnFilter",
                filterParams: {
                  suppressMiniFilter: true,
                },
              },
            ],
          },
        },
        {
          headerName: "Huerto",
          field: "orchard",
          editable: false,
          suppressFillHandle: true,
          filter: "agMultiColumnFilter",
          suppressMovable: true,
          // rowGroup: false,
          enableRowGroup: true,
          hide: false,
          filterParams: {
            filters: [
              {
                filter: "agTextColumnFilter",
                filterParams: {
                  defaultOption: "contains",
                  filterOptions: ["contains"],
                },
              },
              {
                filter: "agSetColumnFilter",
                filterParams: {
                  suppressMiniFilter: true,
                },
              },
            ],
          },
        },
        {
          headerName: "Especie",
          field: "specie",
          editable: false,
          suppressFillHandle: true,
          filter: "agMultiColumnFilter",
          suppressMovable: true,
          hide: true,
          filterParams: {
            filters: [
              {
                filter: "agTextColumnFilter",
                filterParams: {
                  defaultOption: "contains",
                  filterOptions: ["contains"],
                },
              },
              {
                filter: "agSetColumnFilter",
                filterParams: {
                  suppressMiniFilter: true,
                },
              },
            ],
          },
        },
        {
          headerName: "CC",
          field: "cc",
          editable: false,
          suppressFillHandle: true,
          filter: "agMultiColumnFilter",
          suppressMovable: true,
          // rowGroup: false,
          enableRowGroup: true,
          filterParams: {
            filters: [
              {
                filter: "agTextColumnFilter",
                filterParams: {
                  defaultOption: "contains",
                  filterOptions: ["contains"],
                },
              },
              {
                filter: "agSetColumnFilter",
                filterParams: {
                  suppressMiniFilter: true,
                },
              },
            ],
          },
        },
        {
          headerName: "Cuartel",
          field: "quarter",
          editable: false,
          suppressFillHandle: true,
          filter: "agMultiColumnFilter",
          suppressMovable: true,
          // rowGroup: false,
          enableRowGroup: true,
          filterParams: {
            filters: [
              {
                filter: "agTextColumnFilter",
                filterParams: {
                  defaultOption: "contains",
                  filterOptions: ["contains"],
                },
              },
              {
                filter: "agSetColumnFilter",
                filterParams: {
                  suppressMiniFilter: true,
                },
              },
            ],
          },
        },
        {
          headerName: "Variedad",
          field: "variety",
          editable: false,
          filter: "agMultiColumnFilter",
          suppressMovable: true,
          // rowGroup: false,
          enableRowGroup: true,
          filterParams: {
            filters: [
              {
                filter: "agTextColumnFilter",
                filterParams: {
                  defaultOption: "contains",
                  filterOptions: ["contains"],
                },
              },
              {
                filter: "agSetColumnFilter",
                filterParams: {
                  suppressMiniFilter: true,
                },
              },
            ],
          },
          suppressFillHandle: true,
        },
        {
          headerName: "Portainjerto",
          field: "rootStock",
          editable: false,
          suppressFillHandle: true,
          filter: "agMultiColumnFilter",
          suppressMovable: true,
          // rowGroup: false,
          enableRowGroup: true,
          filterParams: {
            filters: [
              {
                filter: "agTextColumnFilter",
                filterParams: {
                  defaultOption: "contains",
                  filterOptions: ["contains"],
                },
              },
              {
                filter: "agSetColumnFilter",
                filterParams: {
                  suppressMiniFilter: true,
                },
              },
            ],
          },
        },
      ],
    },
    {
      headerName: "Temporada",
      field: "season",
      editable: false,
      suppressFillHandle: true,
      filter: false,
    },
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_DEFORMED.headerName,
      field: FIELDS.RECEPTION.F_DEFORMED.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_D_INSECT.headerName,
      field: FIELDS.RECEPTION.F_D_INSECT.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_D_TRIPS.headerName,
      field: FIELDS.RECEPTION.F_D_TRIPS.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_D_SCALE.headerName,
      field: FIELDS.RECEPTION.F_D_SCALE.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_SOFT_SIDE.headerName,
      field: FIELDS.RECEPTION.F_SOFT_SIDE.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_WITHOUT_PEDICEL.headerName,
      field: FIELDS.RECEPTION.F_WITHOUT_PEDICEL.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_DOBLE.headerName,
      field: FIELDS.RECEPTION.F_DOBLE.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_WOUND_SCARRED.headerName,
      field: FIELDS.RECEPTION.F_WOUND_SCARRED.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_STAINS.headerName,
      field: FIELDS.RECEPTION.F_STAINS.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_HALF_MOON.headerName,
      field: FIELDS.RECEPTION.F_HALF_MOON.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.MIX_VAR.headerName,
      field: FIELDS.RECEPTION.MIX_VAR.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_RUSSET.headerName,
      field: FIELDS.RECEPTION.F_RUSSET.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_WOUND.headerName,
      field: FIELDS.RECEPTION.F_WOUND.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.WASTE.headerName,
      field: FIELDS.RECEPTION.WASTE.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_DEHYD.headerName,
      field: FIELDS.RECEPTION.F_DEHYD.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.PED_DEHYD.headerName,
      field: FIELDS.RECEPTION.PED_DEHYD.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_D_BIRD.headerName,
      field: FIELDS.RECEPTION.F_D_BIRD.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_SOFT.headerName,
      field: FIELDS.RECEPTION.F_SOFT.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_WOUND_OPEN.headerName,
      field: FIELDS.RECEPTION.F_WOUND_OPEN.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_START_DEHY.headerName,
      field: FIELDS.RECEPTION.F_START_DEHY.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.PED_START_DEHY.headerName,
      field: FIELDS.RECEPTION.PED_START_DEHY.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_BRUISING.headerName,
      field: FIELDS.RECEPTION.F_BRUISING.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_STAIN_PUDRITION.headerName,
      field: FIELDS.RECEPTION.F_STAIN_PUDRITION.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_CRACKS_SIDE.headerName,
      field: FIELDS.RECEPTION.F_CRACKS_SIDE.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_CRACKS_WATER.headerName,
      field: FIELDS.RECEPTION.F_CRACKS_WATER.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_CRACKS.headerName,
      field: FIELDS.RECEPTION.F_CRACKS.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_PITTING.headerName,
      field: FIELDS.RECEPTION.F_PITTING.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_PUD_BLACK.headerName,
      field: FIELDS.RECEPTION.F_PUD_BLACK.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_PUD_BROWN.headerName,
      field: FIELDS.RECEPTION.F_PUD_BROWN.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
    createColumnDef({
      headerName: FIELDS.RECEPTION.F_PUDRITION.headerName,
      field: FIELDS.RECEPTION.F_PUDRITION.field,
      dataType: "number",
      allowEmpty: true,
      editable: true,
      suppressFillHandle: false,
      handleClickAlert,
    }),
  ];
  // funcion para limpiar las celdas editadas
  const clearEditedCells = () => {
    // const updatedRowData = rowData.map((row) => {
    //   const updatedRow = { ...row };
    //   Object.keys(updatedRow).forEach((key) => {
    //     if (key.startsWith('edited_')) {
    //       delete updatedRow[key];
    //     }
    //   });
    //   return updatedRow;
    // });

    // console.log(rowData, '<<<<<-------------------rowData');

    const transaction = {
      update: rowData.map((row) => {
        const updatedRow = { ...row };
        Object.keys(updatedRow).forEach((key) => {
          if (key.startsWith("edited_")) {
            delete updatedRow[key];
          }
        });
        return updatedRow;
      }),
    };

    // console.log(transaction, '<<<<<-------------------transaction');

    gridApiRef.current.applyTransaction(transaction);

    // setRowData(updatedRowData);
    // gridApiRef.current.setRowData(updatedRowData);
  };

  // función para setear el fullScreen
  const setFullScreen = (fullScreenState) => {
    if (fullScreenState) {
      // document.body.style.overflow = 'hidden';

      setTableContainerStyleState({
        width: "100%",
        zIndex: 1250,
        top: 0,
        left: 0,
        position: "fixed",
        overflow: "auto",
        height: "100vh",
      });

      setTableStyleState({
        height: "calc(100vh - 90px)",
      });
    } else {
      document.body.style.overflow = "";
      setTableContainerStyleState({
        // height: '80vh',
        width: "100%",
        minHeight: 800,
      });

      setTableStyleState({
        height: 700,
      });
    }
    setFullScreenState((prevState) => !prevState);
  };

  // confirma si debe tener master row, si tiene datos internos
  // const isRowMaster = useCallback((dataItem) => {
  //   return dataItem ? dataItem.variantDetails.length > 0 : false;
  // }, []);

  // genera el sidebar Custom
  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressPivotMode: true, // desactivar el modo pivot
          suppressRowGroups: true, // desactivar la agrupación de filas
          suppressValues: true, // desactivar los valores
          suppressColumnFilter: true, // desactivar el filtro de busqueda en las columnas
          contractColumnSelection: true, // contraer la selección de columnas al incio
          suppressColumnSelectAll: true, // desactivar el check de selección de todas las columnas
        },
        minWidth: 225,
        maxWidth: 225,
        width: 225,
      },
      // {
      // 	id: 'custom',
      // 	labelDefault: 'Personalizadas',
      // 	labelKey: 'customStats',
      // 	iconKey: 'columns',
      // 	toolPanel: customPanel,
      // 	toolPanelParams: {},
      // },

      // {
      //     id: 'filters',
      //     labelDefault: 'Filters',
      //     labelKey: 'filters',
      //     iconKey: 'filter',
      //     toolPanel: 'agFiltersToolPanel',
      //     minWidth: 180,
      //     maxWidth: 400,
      //     width: 250
      // }
    ],
    position: "right",
    //   defaultToolPanel: 'customPanel',
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onBtnExportCsv = () => {
    if (gridApiRef.current) {
      const date = new Date();
      const formattedDate = `${String(date.getDate()).padStart(
        2,
        "0"
      )}-${String(date.getMonth() + 1).padStart(2, "0")}-${date.getFullYear()}`; // DD-MM-YYYY
      const formattedTime = date.toTimeString().slice(0, 8).replace(/:/g, "-"); // HH-MM-SS

      const docName = dashAdd(titleCheckState);

      const fileName = `${docName}_${formattedDate}_${formattedTime}.csv`;

      gridApiRef.current.exportDataAsExcel({
        fileName: fileName,
        processCellCallback: (params) => {
          const value = params.value;
          return typeof value === "string" ? value.toUpperCase() : value;
        },
      });
    }
    handleMenuClose();
  };

  const onBtnExportExcel = () => {
    if (gridApiRef.current) {
      const date = new Date();
      const formattedDate = `${String(date.getDate()).padStart(
        2,
        "0"
      )}-${String(date.getMonth() + 1).padStart(2, "0")}-${date.getFullYear()}`; // DD-MM-YYYY
      const formattedTime = date.toTimeString().slice(0, 8).replace(/:/g, "-"); // HH-MM-SS

      const docName = dashAdd(titleCheckState);

      const fileName = `${docName}_${formattedDate}_${formattedTime}.xlsx`;

      gridApiRef.current.exportDataAsExcel({
        fileName: fileName,
        processCellCallback: (params) => {
          const value = params.value;
          return typeof value === "string" ? value.toUpperCase() : value;
        },
      });
    }
    handleMenuClose();
  };

  // funcion para edición de celdas
  const onCellValueChanged = (params) => {
    handleCellValueChanged(params, {
      setRowData,
      rowData,
      alertRef,
      setCopyButtonState,
      markEmptyAsEdited: false,
    });
  };

  const filterEditedRows = (array) => {
    return array.filter((item) =>
      Object.keys(item).some((key) => key.startsWith("edited_"))
    );
  };

  const convertRowDataToCells = (rowData) => {
    let result = [];
    const editedRows = filterEditedRows(rowData);
    editedRows.forEach((entry) => {
      const {
        season,
        idUnitProductive,
        fDeformed,
        fDInsect,
        fDTrips,
        fDScale,
        fSoftSide,
        fWithoutPedicel,
        fDoble,
        fWoundScarred,
        fStains,
        fHalfMoon,
        mixVar,
        fRusset,
        fWound,
        waste,
        fDehyd,
        pedDehyd,
        fDBird,
        fSoft,
        fWoundOpen,
        fStartDehy,
        pedStartDehy,
        fBruising,
        fStainPudrition,
        fCracksSide,
        fCracksWater,
        fCracks,
        fPitting,
        fPudBlack,
        fPudBrown,
        fPudrition,
      } = entry;

      result.push({
        idUnitProductive,
        season,
        fDeformed,
        fDInsect,
        fDTrips,
        fDScale,
        fSoftSide,
        fWithoutPedicel,
        fDoble,
        fWoundScarred,
        fStains,
        fHalfMoon,
        mixVar,
        fRusset,
        fWound,
        waste,
        fDehyd,
        pedDehyd,
        fDBird,
        fSoft,
        fWoundOpen,
        fStartDehy,
        pedStartDehy,
        fBruising,
        fStainPudrition,
        fCracksSide,
        fCracksWater,
        fCracks,
        fPitting,
        fPudBlack,
        fPudBrown,
        fPudrition,
      });
    });
    return result;
  };

  const handleClosePrompt = () => {
    // setSuccessSubmitState(false);
    setConfirmPromptState(false);
  };

  const onResetSearch = () => {
    setQuickFilterText("");
  };
  //#region FUNCIÓN DE GUARDADO DE DATOS
  const onAcceptPrompt = async () => {
    // onSaveData();
    setLoadingSubmitState(true);

    const newArray = convertRowDataToCells(rowData);

    if (newArray.length === 0) {
      setLoadingSubmitState(false);
      handleClosePrompt();
      return;
    }
    const dataObj = {
      userId: userId,
      input: newArray,
    };

    try {
      const response = await axiosContext.authAxios.post(
        endPointNamePost,
        dataObj
      );
      const { status } = response;

      if (status === 200) {
        setSuccessSubmitState(true);
        alertRef.current = false;
        setCopyButtonState(false);

        clearEditedCells();
      }
    } catch (error) {
    } finally {
      setLoadingSubmitState(false);
      handleClosePrompt();
    }
  };

  const onSaveData = async () => {
    // setLoadingSubmitState(true);

    clearFocusSelection();
  };

  const closeHandler = () => {
    setSuccessSubmitState(false);
  };

  const onCloseSnack = () => {
    // setSuccessSubmitState(false);
    handleClosePrompt();
  };

  const handleCloseDialog = () => {
    setOpenDialogState(false);
  };

  const handleCloseAndConfirmDialog = () => {
    setCopyButtonState(false);
    alertRef.current = false;
    handleSubmit(onSubmit)();

    setOpenDialogState(false);
  };

  // filter
  const getFilterAsync = useCallback(async () => {
    setLoadingFilterDataState(true);
    try {
      let response = await axiosContext.authAxios.get(endPointFilterName);

      const { data, status } = response;

      if (status === 200) {
        setFilterOptionsState(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingFilterDataState(false);
    }
  }, [axiosContext, setLoadingFilterDataState, setFilterOptionsState]);

  useEffect(() => {
    getFilterAsync();
  }, []);

  useEffect(() => {
    if (filterOptionsState) {
      setClientOptionsState(
        alterArrayAddLabels(filterOptionsState, ["clientValue", "client"])
      );
    }
  }, [filterOptionsState]);

  useEffect(() => {
    if (watchClient) {
      setValue("orchard", "");
      setOrchardsOptionsState(
        alterArrayAddLabels(watchClient?.orchards, ["orchardValue", "orchard"])
      );
    } else {
      setValue("orchard", "");
      setOrchardsOptionsState([]);
    }
  }, [setValue, watchClient]);

  useEffect(() => {
    if (watchOrchard) {
      setValue("seasonSince", "");
      setValue("seasonUntil", "");

      const seasonsOptions = watchOrchard?.seasons.map((item) => {
        return {
          value: item,
          label: item,
        };
      });

      setSeasonOptionsState(seasonsOptions);
    } else {
      setValue("seasonSince", "");
      setValue("seasonUntil", "");
      setSeasonOptionsState([]);
    }
  }, [setValue, watchOrchard]);

  // PRUEBA ONLINE
  // useEffect(() => {
  // 	const data = YIELD_DATA;

  // 	setDataTableState(data);

  // 	setVisibleColumnsState(finalColumns);

  // 	const rowDataFullTransform = processDataRowDetailFull(data);

  // 	setRowData(rowDataFullTransform);

  // 	setLoadingSubmitFilterState(false);
  // }, []);
  // si hay orchard lo selecciona
  useEffect(() => {
    if (clientOptionsState.length) {
      const found = clientOptionsState.find(
        (element) => element.value === client_filter
      );

      // console.log(found, '---found');
      if (found) {
        setValue("client", found);
      } else {
        setValue("client", "");
        setValue("orchard", "");
        setValue("seasonSince", "");
        setValue("seasonUntil", "");
      }
    }

    // console.log('se ejecuta usf incial');
  }, [clientOptionsState]);

  // si hay orchard lo selecciona
  useEffect(() => {
    if (orchardsOptionsState.length) {
      const found = orchardsOptionsState.find(
        (item) => item.value === orchard_filter
      );

      if (found) {
        setValue("orchard", found);
      } else {
        setValue("orchard", "");
        setValue("seasonSince", "");
        setValue("seasonUntil", "");
      }
    }
    // console.log('se ejecuta usf incial');
  }, [orchardsOptionsState]);

  useEffect(() => {
    if (seasonOptionsState.length) {
      const foundSince = seasonOptionsState.find(
        (item) => item.value === season_since_filter
      );

      const foundUntil = seasonOptionsState.find(
        (item) => item.value === season_until_filter
      );

      if (foundSince) {
        setValue("seasonSince", foundSince);
      } else {
        setValue("seasonSince", "");
        setValue("seasonUntil", "");
      }

      if (foundUntil) {
        setValue("seasonUntil", foundUntil);
      } else {
        setValue("seasonUntil", "");
      }
    }
  }, [seasonOptionsState]);

  return (
    <div className="form-style">
      <BsContainer fluid class="px-0 px-md-3">
        <BsRow class="no-gutters">
          <BsCol class="col-md-12">
            <Card elevation={0}>
              <CardHeaderWidget
                title={viewsTexts.titles.bdcollection.reception.title}
                subheader={<BreadCrumbs subTitle="" />}
              />
              <CardContent
                sx={{
                  paddingX: 0,
                  paddingTop: 0,
                  paddingBottom: "0 !important",
                }}
              >
                {/* PARA AVISAR DATOS NO GUARDADOS */}
                <>
                  {/* para avisar número mayores de 99999 */}
                  {errorDataState && (
                    <>
                      <Snackbar
                        open={openWarningState}
                        autoHideDuration={6000}
                        onClose={handleCloseWarningAlert}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        style={{ top: "18vh" }} // Aquí es donde la magia sucede
                      >
                        <Alert
                          onClose={handleCloseWarningAlert}
                          severity="warning"
                          variant="filled"
                          sx={{
                            width: "100%",
                            fontSize: 18,
                          }}
                        >
                          {`${errorDataState.field}:  ${errorDataState.message}`}
                        </Alert>
                      </Snackbar>
                      <Snackbar
                        open={openErrorState}
                        autoHideDuration={6000}
                        onClose={handleCloseErrorAlert}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        style={{ top: "25vh" }} // Aquí es donde la magia sucede
                      >
                        <Alert
                          onClose={handleCloseErrorAlert}
                          severity="error"
                          variant="filled"
                          sx={{
                            width: "100%",
                            fontSize: 18,
                          }}
                        >
                          VALOR INGRESADO MAYOR
                        </Alert>
                      </Snackbar>
                    </>
                  )}
                  <FormPrompt hasUnsavedChanges={copyButtonState} />
                  <Dialog
                    open={openDialogState}
                    keepMounted
                    onClose={handleCloseDialog}
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        Tiene datos editados sin guardar.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseDialog}>Cancelar</Button>
                      <Button onClick={handleCloseAndConfirmDialog}>
                        Aceptar y Filtrar
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <BsRow class="no-gutters">
                    <BsCol class="col-md-12">
                      <div style={tableContainerStyleState}>
                        {errors.incorrectDates && (
                          <p
                            style={{
                              color: theme.palette.error.main,
                              marginLeft: 10,
                            }}
                          >
                            {errors.incorrectDates.message}
                          </p>
                        )}
                        <div
                          // className='d-flex justify-content-between pb-3 pt-2'
                          className="d-flex flex-column flex-md-row justify-content-md-between justify-content-center pb-3 pt-2 gap-3"
                          style={{
                            backgroundColor: "white",
                          }}
                        >
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <form
                              className="form-inline"
                              autoComplete="on"
                              onSubmit={handleSubmit(onSubmit)}
                            >
                              <div className="input-group ml-md-2">
                                <SelectMultipleWidget2
                                  errors={errors.client}
                                  loading={loadingFilterDataState}
                                  control={control}
                                  menuPortalTarget={document.body}
                                  labeltext=""
                                  name="client"
                                  display="vertical"
                                  variant="outlined"
                                  // minheight={
                                  // 	false
                                  // }
                                  isClearable={true}
                                  isSearchable
                                  // onChange={handleClearSpecieValue}
                                  isMulti={false}
                                  req={true}
                                  defaultValue=""
                                  options={clientOptionsState}
                                  placeholder="Cliente"
                                />
                              </div>
                              <div className="input-group ml-md-2">
                                <SelectMultipleWidget2
                                  errors={errors.orchard}
                                  loading={loadingFilterDataState}
                                  control={control}
                                  menuPortalTarget={document.body}
                                  labeltext=""
                                  name="orchard"
                                  display="vertical"
                                  variant="outlined"
                                  // minheight={
                                  // 	false
                                  // }
                                  isClearable={true}
                                  isSearchable
                                  // onChange={handleClearSpecieValue}
                                  isMulti={false}
                                  req={true}
                                  defaultValue=""
                                  options={orchardsOptionsState}
                                  placeholder="Huerto"
                                />
                              </div>
                              <div className="input-group ml-md-2">
                                <SelectMultipleWidget2
                                  errors={errors.seasonSince}
                                  loading={loadingFilterDataState}
                                  control={control}
                                  menuPortalTarget={document.body}
                                  labeltext=""
                                  name="seasonSince"
                                  display="vertical"
                                  variant="outlined"
                                  // minheight={
                                  // 	false
                                  // }
                                  isClearable={true}
                                  isSearchable
                                  // onChange={handleClearSpecieValue}
                                  isMulti={false}
                                  req={false}
                                  defaultValue=""
                                  options={seasonOptionsState}
                                  placeholder="Temp. Desde"
                                />
                              </div>
                              <div className="input-group ml-md-2">
                                <SelectMultipleWidget2
                                  errors={errors.seasonUntil}
                                  loading={loadingFilterDataState}
                                  control={control}
                                  menuPortalTarget={document.body}
                                  labeltext=""
                                  name="seasonUntil"
                                  display="vertical"
                                  variant="outlined"
                                  // minheight={
                                  // 	false
                                  // }
                                  isClearable={true}
                                  isSearchable
                                  // onChange={handleClearSpecieValue}
                                  isMulti={false}
                                  req={false}
                                  defaultValue=""
                                  options={seasonOptionsState}
                                  placeholder="Temp. Hasta"
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  alignSelf: "flex-start",
                                  paddingTop: 5,
                                }}
                              >
                                <SaveAction
                                  title="Filtrar"
                                  color="secondary"
                                  size="medium"
                                  className="roundedButton ml-md-2"
                                  variant="contained"
                                  disabled={loadingSubmitFilterState}
                                  messageType="add"
                                  mutationLoading={loadingSubmitFilterState}
                                  success={false}
                                />
                              </div>
                            </form>
                          </Box>
                          <Box
                            sx={{
                              // marginRight: 2,
                              marginBottom: 0,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              gap: 2,
                            }}
                          >
                            <TextField
                              variant="outlined"
                              placeholder="Buscar..."
                              label="Buscar"
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                                endAdornment: quickFilterText ? (
                                  <IconButton
                                    color="primary"
                                    aria-label="clear"
                                    component="span"
                                    onClick={() => onResetSearch()}
                                  >
                                    <ClearIcon
                                      fontSize="small"
                                      style={{
                                        color: theme.palette.grey[400],
                                      }}
                                    />
                                  </IconButton>
                                ) : null,
                              }}
                              value={quickFilterText}
                              onChange={handleQuickFilterChange}
                              sx={{
                                width: 200,
                              }}
                            />
                          </Box>
                          <Box>
                            <ButtonAction
                              labelButton="GUARDAR"
                              color="secondary"
                              size="large"
                              actionOnClick={onSaveData}
                              className="roundedButton"
                              variant="contained"
                              messageType="add"
                              actionClose={closeHandler}
                              fullWidth
                              disabled={
                                loadingSubmitFilterState ||
                                !rowData ||
                                !rowData.length
                              }
                              mutationLoading={loadingSubmitState}
                              success={successSubmitState}
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: 2,
                            }}
                          >
                            <IconButton
                              color="primary"
                              aria-label="export"
                              onClick={handleMenuOpen}
                              disabled={
                                loadingSubmitFilterState || rowData.length === 0
                              }
                            >
                              <DownloadIcon />
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleMenuClose}
                            >
                              <MenuItem onClick={onBtnExportExcel}>
                                Exportar a Excel
                              </MenuItem>
                              <MenuItem onClick={onBtnExportCsv}>
                                Exportar a CSV
                              </MenuItem>
                            </Menu>
                            <IconButton
                              color="primary"
                              aria-label="fullscreen-toggle"
                              onClick={() => setFullScreen(!fullScreenState)}
                              disabled={loadingSubmitFilterState}
                            >
                              {fullScreenState ? (
                                <FullscreenExitIcon />
                              ) : (
                                <FullscreenIcon />
                              )}
                            </IconButton>
                          </Box>
                        </div>
                        {loadingSubmitFilterState && <LoadingWidget />}

                        {confirmPromptState ? (
                          <Dialog
                            open={confirmPromptState}
                            onClose={handleClosePrompt}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle
                              id="alert-dialog-title"
                              sx={{
                                lineHeight: 0.5,
                              }}
                            >
                              Edición {titleModule}
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText
                                id="alert-dialog-description"
                                className={classes.dialogTextStyle}
                              >
                                ¿Confirma que desea actualizar los datos?
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions
                              sx={{
                                justifyContent: "space-between",
                              }}
                            >
                              <Button
                                onClick={handleClosePrompt}
                                disabled={loadingSubmitState}
                                sx={{
                                  color: theme.palette.grey[500],
                                }}
                              >
                                Cancelar
                              </Button>
                              <ButtonAction
                                labelButton="GUARDAR"
                                color="secondary"
                                // size='large'
                                actionClose={onCloseSnack}
                                variant="contained"
                                fullWidth={true}
                                actionOnClick={onAcceptPrompt}
                                disabled={false}
                                messageType="edit"
                                mutationLoading={loadingSubmitState}
                                success={false}
                              />
                            </DialogActions>
                          </Dialog>
                        ) : null}

                        {!loadingSubmitFilterState &&
                          (dataTableState && dataTableState?.length ? (
                            <div
                              className="ag-theme-quartz"
                              style={tableStyleState}
                            >
                              <AgGridReact
                                rowData={rowData}
                                columnDefs={visibleColumnsState}
                                onCellValueChanged={onCellValueChanged}
                                pagination={true}
                                paginationPageSize={50}
                                suppressMovableColumns={true}
                                suppressDragLeaveHidesColumns={true}
                                paginationPageSizeSelector={
                                  paginationPageSizeSelector
                                }
                                localeText={localeText}
                                defaultColDef={{
                                  resizable: true,
                                  minWidth: 200, // Ancho mínimo
                                  floatingFilter: true,
                                  filter: false,
                                }}
                                //   masterDetail={true}
                                //   detailCellRendererParams={
                                //     detailCellRendererParams
                                //   }
                                groupDisplayType={"multipleColumns"}
                                rowGroupPanelShow={"always"}
                                enableRangeSelection={true}
                                enableFillHandle={true}
                                quickFilterText={quickFilterText}
                                onGridReady={onGridReady}
                                sideBar={sideBar}
                                embedFullWidthRows={true}
                                // isRowMaster={isRowMaster}
                                // detailRowAutoHeight={true}
                                getRowId={(params) => params.data.id}
                                // grandTotalRow={'bottom'}
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "70vh",
                                width: "100%",
                              }}
                            >
                              <Typography variant="h5" color="textSecondary">
                                No hay datos
                              </Typography>
                            </div>
                          ))}
                      </div>
                    </BsCol>
                  </BsRow>
                </>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default Reception;
