export const validationTypes = {
  min: (value, rule) => value >= rule.value,
  max: (value, rule) => value <= rule.value,
  integer: (value) => Number.isInteger(value),
  string: (value) => typeof value === "string",
  boolean: (value) => typeof value === "boolean",
  custom: (value, rule) => rule.validate(value),
};

// MENSAJES DE VALIDACIÓN
export const VALIDATION_MESSAGES = {
  min: (value) => `Debe ser mayor a ${value}`,
  max: (value) => `Debe ser menor a ${value}`,
  integer: "Debe ser un número entero",
  string: "Debe ser una cadena de texto",
  boolean: "Debe ser un valor booleano",
  validYear: "Debe ser un año entre 1900 y el año actual",
  validDate: "Debe ser un formato de fecha válida (YYYY-MM-DD)",
};

export const VALIDATION_RULES = {
  min: (value) => ({
    type: "min",
    value,
    message: VALIDATION_MESSAGES.min(value),
  }),
  max: (value) => ({
    type: "max",
    value,
    message: VALIDATION_MESSAGES.max(value),
  }),
  integer: {
    type: "integer",
    message: VALIDATION_MESSAGES.integer,
  },
  string: {
    type: "string",
    message: VALIDATION_MESSAGES.string,
  },
  boolean: {
    type: "boolean",
    message: VALIDATION_MESSAGES.boolean,
  },
  validYear: {
    type: "custom",
    validate: (value) => value >= 1900 && value <= new Date().getFullYear(),
    message: VALIDATION_MESSAGES.validYear,
  },
  validDate: {
    type: "custom",
    validate: (value) => {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (!regex.test(value)) {
        return false;
      }
      const date = new Date(value);
      const isValidDate =
        !isNaN(date.getTime()) && value === date.toISOString().split("T")[0];
      return isValidDate;
    },
    message: VALIDATION_MESSAGES.validDate,
  },
};
