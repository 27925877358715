import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';
import '../style.scss';
const IconActionTableDelete = (props) => {
	const { handleActionDelete } = props;
	//<IconButton aria-label="delete" onClick={() => handleActionDelete(row)}>
	return (
		<Tooltip title="Eliminar" arrow className="buttonIconAction">
			<IconButton
				sx={{
					color: 'white',
					backgroundColor: 'error.main',
					'&:hover': {
						backgroundColor: 'primary.dark',
					},
					borderRadius: 1,
					padding: 1,
					margin: 0.5,
				}}
				aria-label="delete"
				onClick={handleActionDelete}
			>
				<DeleteIcon fontSize="small" />
			</IconButton>
		</Tooltip>
	);
};

export default IconActionTableDelete;
