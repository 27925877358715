import React, { useState, Fragment, useContext } from 'react';
import {
	NavLink,
	useRouteMatch,
	useLocation,
	useMatch,
} from 'react-router-dom';
import clsx from 'clsx';
import {
	Link,
	List,
	ListItem,
	Tooltip,
	Divider,
	ListItemButton,
	Box,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import iconJson from '../../../../../../common/iconSVG.json';
import HomeIcon from '@mui/icons-material/Home';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import EventIcon from '@mui/icons-material/Event';
import DialpadIcon from '@mui/icons-material/Dialpad';
import SvgIcon from '@mui/material/SvgIcon';
import SettingsIcon from '@mui/icons-material/Settings';
import ContactsIcon from '@mui/icons-material/Contacts';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import NatureIcon from '@mui/icons-material/Nature';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import CircleIcon from '@mui/icons-material/Circle';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BackupIcon from '@mui/icons-material/Backup';
import classesModule from './SidebarNav.module.scss';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import HistorycIcon from '@mui/icons-material/History';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import BallotIcon from '@mui/icons-material/Ballot';
import { makeStyles, styled, useTheme, withStyles } from '@mui/styles';
import { Context } from '../../../../../../context/Context';
import _ from 'lodash';

const CollapseCustom = styled(Collapse, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	backgroundColor: theme.palette.grey[50],
	boxShadow: theme.shadows[25],
	'& .MuiTypography-root': {
		fontWeight: 500,
	},
	'& .Mui-selected, & .active': {
		// backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.main,
		// borderRadius: 8,
		'& .MuiListItemIcon-root': {
			color: theme.palette.primary.main,
		},
		'& .MuiTypography-root': {
			color: theme.palette.primary.main,
			fontWeight: 500,
		},
		'&:hover': {
			// backgroundColor: theme.palette.primary.dark,
		},
	},
}));

const useStyles = makeStyles((theme) => ({
	listItemStyle: {},
	listItemButtonStyle: {
		minHeight: 40,
		'& .MuiTypography-root': {
			fontWeight: 500,
		},
		'&.Mui-selected, &.active': {
			backgroundColor: theme.palette.primary.main,
			color: 'white',
			borderRadius: 8,
			'& .MuiListItemIcon-root': {
				color: 'white',
			},
			'& .MuiTypography-root': {
				color: 'white',
				fontWeight: 500,
			},
			'&:hover': {
				backgroundColor: theme.palette.primary.dark,
			},
		},
	},
}));

const SidebarNav = (props) => {
	const { pages, className, open, ...rest } = props;

	const { logout } = useContext(Context);

	const classes = useStyles();

	const [activeIdItem, setActiveIdItem] = useState(null);

	const [openState, setOpenState] = useState({
		values: {},
	});

	const theme = useTheme();

	// console.log(theme, 'el theme');

	const sortedPages = _.sortBy(pages, _.property('menuOrder'));

	const match = useRouteMatch();

	const handleActiveRoute = (path) => {
		const isCurrentPath = match?.path === `/${path}`;

		if (isCurrentPath) {
			const menuActive = sortedPages;

			console.log(menuActive, '----menuActive cuando es active SIMPLE');
			activeIdItem.current = menuActive.id;
		}

		return isCurrentPath || false;
	};

	const handleClickSubItem = (pageId, submenuId) => {
		setActiveIdItem(pageId);
		setOpenState((openState) => ({
			values: {
				...openState.values,
				[submenuId]: !openState.values[submenuId],
			},
		}));
	};

	const handleClickParentSubmenu = (id) => {
		// console.log(id, '<-----id click1', openState);
		// setActiveIdItem(id);
		setOpenState((openState) => ({
			values: {
				...!openState.values,
				[id]: !openState.values[id],
			},
		}));
	};

	const location = useLocation();

	function findParentMenu(array, href) {
		if (array.submenu && Array.isArray(array.submenu)) {
			return array.submenu.some((item) => item.href === href);
		}
		return false;
	}

	const handleActiveRouteWithSub = (path) => {
		const menuActive = sortedPages;

		// definir una variable que captura la url actual

		const menuObtained = findParentMenu(menuActive, location.pathname);

		// if (menuObtained) {
		//   console.log(menuActive, '----menuActive cuando es active');
		// } else {
		//   console.log(menuActive, '----menuActive cuando no es active');
		// }

		return menuObtained;
	};

	const handleClickNoSub = (id) => {
		// console.log(id, '<-----id handleClickNoSub');
		setActiveIdItem(id);
		setOpenState((openState) => ({
			values: {
				...!openState.values,
				[id]: !openState.values[id],
			},
		}));
	};

	// console.log(pages, '------pages--------------------------');

	const renderMaterialIcon = (icon) => {
		switch (icon) {
			case 'SettingsIcon':
				return <SettingsIcon />;

			case 'DashboardIcon':
				return <DashboardIcon />;

			case 'PersonIcon':
				return <PersonIcon />;

			case 'AssignmentIndIcon':
				return <AssignmentIndIcon />;

			case 'EventIcon':
				return <EventIcon />;

			case 'DialpadIcon':
				return <DialpadIcon />;

			case 'ContactsIcon':
				return <ContactsIcon />;

			case 'NatureIcon':
				return <NatureIcon />;

			case 'HowToRegIcon':
				return <HowToRegIcon />;

			case 'AddIcon':
				return <AddIcon />;

			case 'ListIcon':
				return <ListIcon />;

			case 'HomeIcon':
				return <HomeIcon />;

			case 'AssignmentTurnedInIcon':
				return <AssignmentTurnedInIcon />;

			case 'BackupIcon':
				return <BackupIcon />;

			case 'AssessmentIcon':
				return <AssessmentIcon />;

			case 'BusinessCenterIcon':
				return <BusinessCenterIcon />;

			case 'BallotIcon':
				return <BallotIcon />;
			case 'SystemUpdateAltIcon':
				return <SystemUpdateAltIcon />;

			case 'CircleIcon':
				return <CircleIcon sx={{ fontSize: 12 }} />;

			default:
			case 'HistorycIcon':
				return <HistorycIcon />;

				break;
		}
	};

	// console.log(openState, '<<<<<<-----------openState');

	return (
		<List {...rest} className={clsx(className)}>
			{/* <ListItem
				button
				activeClassName={classes.active}
				className={classes.item}
				key={1000}
				selected={false}
				component={NavLink}
				to="/"
				onClick={() => handleClick(1000)}
				name={1000}>
				<ListItemIcon className={classes.icon}><DashboardIcon /></ListItemIcon>
				<ListItemText primary="Inicio" />
			</ListItem> */}
			{pages.map((page) => (
				<Fragment key={page.id + 'fragment'}>
					{page.submenu ? (
						<>
							<Tooltip
								title={page.title}
								aria-label={page.title}
								placement="right"
								arrow
								key={page.id}
								disableHoverListener={open}
							>
								<ListItem
									key="sub"
									disablePadding
									className={classes.listItemStyle}
									sx={{ display: 'block', px: 0.5 }}
									name={page.id}
								>
									<ListItemButton
										selected={handleActiveRouteWithSub(
											page.href
										)}
										component={Link}
										onClick={() =>
											handleClickParentSubmenu(page.id)
										}
										className={classes.listItemButtonStyle}
										sx={{
											justifyContent: open
												? 'initial'
												: 'center',
										}}
									>
										<ListItemIcon
											sx={{
												minWidth: 0,
												mr: open ? 2 : 'auto',
												justifyContent: 'center',
											}}
										>
											{page.iconType === 'material' ? (
												renderMaterialIcon(page.icon)
											) : (
												<SvgIcon viewBox="0 -1 18 22">
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d={
															iconJson.icon[
																page.icon
															]
														}
													/>
												</SvgIcon>
											)}
										</ListItemIcon>
										<ListItemText
											primary={page.title}
											sx={{ opacity: open ? 1 : 0 }}
										/>
										{openState.values[page.id] ? (
											<ExpandLess />
										) : (
											<ExpandMore />
										)}
									</ListItemButton>
								</ListItem>
							</Tooltip>
							<CollapseCustom
								// className={classesModule.Collapse}
								in={openState.values[page.id]}
								timeout="auto"
								sx={{ mx: 0.5 }}
								unmountOnExit
								key={page.id + 'submenu'}
							>
								<List
									component="div"
									disablePadding
									dense={true}
								>
									{page.submenu.map((submenu) => {
										const match = handleActiveRoute(
											`${submenu.href}/*`
										);

										return (
											<Tooltip
												title={`${page.title} > ${submenu.title}`}
												aria-label={`${page.title} > ${submenu.title}`}
												placement="right"
												arrow
												key={submenu.id}
												disableHoverListener={open}
											>
												<ListItemButton
													sx={{ pl: open ? 3 : 2 }}
													component={NavLink}
													to={submenu.href}
													selected={
														match ? true : false
													}
													onClick={() =>
														handleClickSubItem(
															page.id,
															submenu.id
														)
													}
													// selected={activeIdItem === page.id}
													name={submenu.id}
													// data-menu={page.id}
												>
													<ListItemIcon
														sx={{
															minWidth: 0,
															mr: open
																? 2
																: 'auto',
															justifyContent:
																'center',
														}}
													>
														{submenu.iconType ===
														'material' ? (
															renderMaterialIcon(
																submenu.icon
															)
														) : (
															<SvgIcon
																width="24"
																height="24"
																viewBox="0 0 24 24"
															>
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	// stroke="#66788E"
																	// stroke-width="2"
																	d={
																		iconJson
																			.icon[
																			submenu
																				.icon
																		]
																	}
																/>
															</SvgIcon>
														)}
													</ListItemIcon>
													<ListItemText
														primary={submenu.title}
														sx={{
															opacity: open
																? 1
																: 0,
														}}
													/>
												</ListItemButton>
											</Tooltip>
										);
									})}
								</List>
							</CollapseCustom>
						</>
					) : (
						<>
							<Tooltip
								title={page.title}
								aria-label={page.title}
								placement="right"
								arrow
								disableHoverListener={open}
							>
								<ListItem
									key={page.id}
									disablePadding
									sx={{ display: 'block', px: 0.5 }}
									className={classes.listItemStyle}
									name={page.id}
								>
									<ListItemButton
										// selected={activeIdItem === page.id}
										selected={handleActiveRoute(page.href)}
										component={NavLink}
										to={page.href ? page.href : 'null'}
										onClick={() =>
											handleClickNoSub(page.id)
										}
										className={classes.listItemButtonStyle}
										sx={{
											justifyContent: open
												? 'initial'
												: 'center',
										}}
									>
										<ListItemIcon
											sx={{
												minWidth: 0,
												mr: open ? 2 : 'auto',
												justifyContent: 'center',
											}}
										>
											{page.iconType === 'material' ? (
												renderMaterialIcon(page.icon)
											) : (
												<SvgIcon viewBox="0 -1 18 22">
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														// stroke="#66788E"
														// stroke-width="2"
														d={
															iconJson.icon[
																page.icon
															]
														}
													/>
												</SvgIcon>
											)}
										</ListItemIcon>
										<ListItemText
											primary={page.title}
											sx={{
												opacity: open ? 1 : 0,
											}}
										/>
									</ListItemButton>
								</ListItem>
							</Tooltip>
						</>
					)}
				</Fragment>
			))}
			{/* <Tooltip
				title="HOLA"
				aria-label="HOLA"
				placement="right"
				arrow
				disableHoverListener={open}
			>
				<ListItemCustom
					button
					key="1234567"
					to="intelligence"
					component={NavLink}
					onClick={() => handleClick('1')}
					name="1"
				>
					<ListItemIcon className={clsx(classesModule.icon)}>
						<SvgIcon viewBox="0 -1 18 22">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d={iconJson.icon['intelligence']}
							/>
						</SvgIcon>
					</ListItemIcon>
					<ListItemText primary="HOLA" />
				</ListItemCustom>
			</Tooltip> */}
			<Box
				sx={{
					display: {
						xs: 'block',
						sm: 'none',
						md: 'none',
						lg: 'none',
						xl: 'none',
					},
				}}
			>
				<Divider className="my-3" />
				<ListItem disablePadding dense={true}>
					<ListItemButton
						key={1000}
						selected={false}
						component={NavLink}
						to="/"
						onClick={() => logout()}
						name={1000}
					>
						<ListItemIcon>
							<ExitToAppIcon />
						</ListItemIcon>
						<ListItemText primary="Cerrar Sesión" />
					</ListItemButton>
				</ListItem>
			</Box>
		</List>
	);
};

export default SidebarNav;
