import React, {useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
	// DataTableWidget,
	SaveAction,
	// SelectMultipleWidget,
	// LoadingWidget,
	// TextFieldWidgetSelectController,
	TextFieldWidgetSelectControllerHorizontal,
	TextFieldWidgetText,
	CardHeaderWidget,
	BreadCrumbs,
	SnackBarsComponent,
	RadioGroupWidget,
} from '../../../components';


import {
	Client,
	Orchards,
	Specie,
}
from '../Options/OptionsData';

import {
	// Accordion,
	// AccordionDetails,
	// AccordionSummary,
	// Divider,
	Card,
	CardContent,
	// Hidden,
	// Typography,
	useTheme,
	Snackbar,
	Radio,
	FormControlLabel,
} from '@mui/material';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';
// import viewsTexts from '../../common/viewsTexts.json';
import { makeStyles } from '@mui/styles';
// import { ToggleButton, ToggleButtonGroup } from '@mui/lab';
import { SimpleTabs, TabPanel } from 'components/TabPanel';
import viewsTexts from '../../../common/viewsTexts.json';
import {
	// numberFormat,
	// isNumber,
	// findActualYear,
	// alterArrayForSelectVariety,
	// getOrchardVarieties,
	// getOrchardVarietiesDeep,
	alterArrayGetFields,
	alterArrayAddLabels,
	formatRut,
} from '../../../helpers';
// import theme from 'theme';
import { FiltersContext } from '../../../context';
import { result } from 'lodash';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.tertiary.main,
		fontWeight: 500,
	},
	divider: {
		background: theme.palette.grey[400],
		marginTop: 10,
	},
	rootHelper: {
		fontSize: 17,
	},
	inputText: {
		fontSize: 26,
	},
}));

const HdAdd = (props) => {
	const { userId, userHuerto, userName, userOrchard } = useContext(Context);

	// console.log(userId, userHuerto, userName, "<<<<---context")
	const { state: filterState, addFilter } = useContext(FiltersContext);

	const {
		// orchard_filter,
		// cc_filter,
		// quarter_filter,
		// variety_filter,
		// season_filter,
		userOrchard_filter,
	} = filterState;

	// console.log('----', filterState);

	const axiosContext = useContext(AxiosContext);
	// axiosContext.authAxios //queries with token

	const [filterDataResponseState, setFilterDataResponseState] = useState(
		userOrchard_filter
	);

	const [loadingQueryState, setLoadingQueryState] = useState(false);
	const [successQueryState, setSuccessQueryState] = useState(false);


	const [profileDataState, setProfileDataState] = useState([]);
	const [loadingProfileDataState, setLoadingProfileDataState] = useState(
		false
	);
	const [seasonState, setSeasonState] = useState([]);
	const [rootStockDataState, setRootStockDataState] = useState([]);
	const [varietyDataState, setVarietyDataState] = useState([]);
	const [orchardsDataComboState, setOrchardsDataComboState] = useState([]);
	const [specieDataState, setSpecieDataState] = useState([]);
	const [clientDataState, setClientDataState] = useState([]);
	const [agroBusinessDataState, setAgroBusinessDataState] = useState([]);
	const [supplierDataState, setSupplierDataState] = useState([]);
	const [ccDataState, setCcDataState] = useState([]);
	const [quarterDataState, setQuarterDataState] = useState([]);
	const [filtersDataState, setFiltersDataState] = useState(null);
	const [loadingFiltersState, setLoadingFiltersState] = useState(false);
	const [orchardsDataState, setOrchardsDataState] = useState([]);
	const [loadingOrchardsDataState, setLoadingOrchardsDataState] = useState(false);

	const [errorState, setErrorState] = useState(false);

	const [allOrchardsDataState, setAllOrchardsDataState] = useState([]);
	const [showSelectController, setShowSelectController] = useState(false);


	const theme = useTheme();

	// console.log(theme)

	const classes = useStyles();

	const [valueTabState, setValueTabState] = useState(0);

	const handleChangeTab = (event, newValue) => {
		setValueTabState(newValue);
	};

	const {
		handleSubmit,
		control,
		watch,
		reset,
		// setValue,
		getValues,
		// setError,
		// clearErrors,
		formState: { errors },
	} = useForm({defaultValues: {
		credentialValue: "no",
		}
	});

	// const watchRut = watch('rut');
	const watchClient = watch('clientValue');
	const watchAgroBusiness = watch('agroBusinessValue');
	const watchOrchard = watch('orchardValue');
	const watchSpecie = watch('specieValue');
	const watchVariety = watch('varietyValue');
	const watchRootStock = watch('rootStockValue');

	const onSubmit = async (values) => {
		console.log(values, '<<<--------');
		setLoadingQueryState(true);

		// saca los objetos
		let clientObj = clientDataState.filter(
			(ele) => ele.value === values.client
		);

		clientObj = alterArrayGetFields(clientObj, ['id', 'label', 'value']);

		let orchardObj = orchardsDataComboState.filter(
			(ele) => ele.value === values.orchard
		);

		orchardObj = alterArrayGetFields(orchardObj, ['id','label','value']);

		let agroBusinessObj = agroBusinessDataState.filter(
			(ele) => ele.value === values.agroBusiness
		);

		agroBusinessObj = alterArrayGetFields(agroBusinessObj, ['id','label','value']);

		let specieObj = specieDataState.filter(
			(ele) => ele.value === values.specieObj
		);

		specieObj = alterArrayGetFields(specieObj, ['id','label','value']);

	/*	
		let rootStockObj = rootStockDataState.filter(
			(ele) => ele.value === values.rootStockObj
		);

		rootStockObj = alterArrayGetFields(rootStockObj, ['label','value']);
*/




/*

		let agroBusinessObj = agroBusinessDataState.filter(
			(ele) => ele.value === values.agroBusiness
		);

		agroBusinessObj = alterArrayGetFields(agroBusinessObj, ['id','label','value']);
			
///////////////
console.log(agroBusinessObj, '<<----agroBusinessObj');
		let orchardObj = orchardsDataComboState.filter(
			(ele) => ele.value === values.orchard
		);

		orchardObj = alterArrayGetFields(orchardObj, ['id','label','value']);
		

//////////////


		let specieObj =specieDataState.filter(
			(ele) => ele.value === values.specie
		);

		specieObj = alterArrayGetFields(specieObj, ['id','label','value']);


*/



		// const agroBusinessObj = agroBusinessDataState.filter(
		// 	(ele) => ele.value === values.agroBusiness
		// );

		// console.log(clientObj, agroBusinessArray, '----------');

		const query = {
			// rut: formatRut(values.rut),
			clientValue: clientObj[0],
			agroBusinessValue: agroBusinessObj[0],
			orchardValue: orchardObj[0],
			ccValue: values.cc,
			quarterValue: values.quarter,
			specieValue: specieObj[0],
			varietyValue: values.variety,
			rootStockValue: values.rootStock,
			seasonValue: values.season,
			credentialValue: values.credential,
			// email: values.email,
			// maternalLastname: values.maternalLastname,
		};

		// console.log(query, 'envia a create');
		try {
			const response = await axiosContext.authAxios.post(
				'v1/user/create-user',
				query
			);
			const bodyInfo = response.Client;

			// 	// setProfileSelectedState('');
			// 	console.log(response, '<-----response');
			// 	// let response;
			console.log(response, '------response');

			if (response.status === 200) {
				// alert('listo');
				setSuccessQueryState(true);

				reset({
					agroBusinessValue: '',
					orchardValue: '',
					ccValue: '',
					quarterValue: '',
					specieValue: '',
					varietyValue: '',
					rootStockValue: '',
					seasonValue: '',
				});
			}

			// setProfileSelectedState('');

			setLoadingQueryState(false);
		} catch (err) {
			// console.log('cae al error', err);
			// console.log('Error', err.message);
			console.log('Error', err.request.status);
			if (err.request.status === 500) {
				setErrorState(true);
			}

			setLoadingQueryState(false);
		}
	};

	// useEffect(() => {
	// 	if (watchRut && watchRut.length > 3) {
	// 		setValue('rut', formatRut(watchRut));
	// 	}
	// }, [watchRut, setValue]);

	const { password } = getValues();


	useEffect(() => {
		if (watchClient) {
			const resultClient = clientDataState.find(
				(ele) => ele.value === watchClient
			);
			setAgroBusinessDataState(
				alterArrayAddLabels(resultClient.agroBusinessData, [
					'agroBusinessValue',
					'agroBusiness',
				])
			);
			console.log('resultado cliente', resultClient);
		}
	}, [watchClient]);

	useEffect(() => {
		console.log(
			watchClient,
			watchAgroBusiness,
			'-----watchClient, watchAgrobusiness'
		);
		if (watchClient && watchAgroBusiness) {
			const resultAgrobusiness = agroBusinessDataState.find(
				(ele) => ele.value === watchAgroBusiness
			);

			console.log(resultAgrobusiness, '<<----resultAgrobusiness');

			if (resultAgrobusiness) {
				setOrchardsDataComboState(
					alterArrayAddLabels(resultAgrobusiness.orchardData, [
						'orchardValue',
						'orchard',
					])
				);
			} else {
				setOrchardsDataComboState([]);
			}
		} else {
			setOrchardsDataComboState([]);
		}
	}, [watchAgroBusiness, agroBusinessDataState]);

	useEffect(() => {
		// Detectar cambios en el valor del radio button
		if (watch('credentialValue') === 'si') {
		  setShowSelectController(true);
		} else {
		  setShowSelectController(false);
		}
	  }, [watch('credentialValue')]);



	// endpoints
	useEffect(() => {
		getDataFiltersAsync();
	}, []);

	const getDataFiltersAsync = async () => {
		setLoadingFiltersState(true);
		try {
			let response = await axiosContext.authAxios.post(
				'v1/unitProductive/filter-create'
			);

			const { data, status } = response;

			if (status === 200) {
				setFiltersDataState(data);
			}
		} catch (error) {
			console.log(error);
			// setLoadingVarietyFilterState(false);
		} finally {
			setLoadingFiltersState(false);
		}
	};

	console.log(filtersDataState, '<<----filtersDataState');


	useEffect(() => {
		if (filtersDataState) {
			setClientDataState(
				alterArrayAddLabels(filtersDataState.clientData, [
					'clientValue',
					'client',
				])
			);
		}
	}, [filtersDataState]);

	

	const handleCloseSnackBarsComponent = () => {
		setErrorState(false);
	};

	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-md-12">
						<Card elevation={0}>
							<CardHeaderWidget
								title={viewsTexts.titles.historicData.title}
								subheader={<BreadCrumbs subTitle="" />}
							/>
							{/* <Divider /> */}
							<CardContent>
								<BsRow>
									<BsCol class="col-md-12">
										<form
											autoComplete="on"
											onSubmit={handleSubmit(onSubmit)}
										>
											<BsRow>
												<BsCol class="col-md-6">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															clientDataState
														}
														titleOption="label"
														control={control}
														name="clientValue"
														labeltext="Cliente"
														errors={errors.client}
														defaultValue=""
														loading={false}
														req
														withDefault
														variant="outlined"
													/>
												</BsCol>
												<BsCol class="col-md-6">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															agroBusinessDataState
														}
														titleOption="label"
														control={control}
														name="agroBusinessValue"
														labeltext="Agrícola"
														errors={errors.agroBusiness}
														multiple={false}
														defaultValue=""
														loading={false}
														req
														withDefault
														variant="outlined"
													/>
												</BsCol>
											</BsRow>
											<BsRow>

												<BsCol class="col-md-6">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															orchardsDataComboState
														}
														titleOption="label"
														control={control}
														name="orchardValue"
														labeltext="Huerto"
														errors={
															errors.orchardcombo
														}
														multiple={false}
														defaultValue=""
														loading={false}
														req
														withDefault
														variant="outlined"
													/>
												</BsCol>
												<BsCol class="col-md-6">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															supplierDataState
														}
														titleOption="label"
														control={control}
														name="supplier"
														labeltext="Proveedor"
														errors={
															errors.supplier
														}
														multiple={false}
														defaultValue=""
														loading={false}
														req
														withDefault
														variant="outlined"
													/>
												</BsCol>
											</BsRow>
											<BsRow>
											<BsCol class="col-md-12">
											<div style={{ display: 'flex', alignItems: 'center' }}>
    										<div style={{ marginRight: '16px',fontSize: '0.85rem', width:'165px'}}>Credencial</div> {/* Texto del label a la izquierda */}
												<RadioGroupWidget
											
													control={control}
													name="credentialValue"
													req={true} 
													errors={errors.credential} 
													variant="outlined"
												>
													<FormControlLabel 
														value="si" 
														control={<Radio />} 
														label="Si"  
													/>
													<FormControlLabel 
														value="no" 
														control={<Radio />} 
														label="No" 
													/>
												</RadioGroupWidget>
											</div>
											{showSelectController ? (
														<TextFieldWidgetSelectControllerHorizontal
														options={orchardsDataComboState}
														titleOption="label"
														control={control}
														name="credential"
														labeltext="Credencial"
														errors={errors.credential}
														multiple={false}
														defaultValue=""
														loading={false}
														req
														withDefault
														variant="outlined"
														/>
													) : (
														<BsRow>
															<BsCol class="col-md-6">
															<TextFieldWidgetText
																InputLabelProps={{
																shrink: true,
																}}
																name="username"
																labeltext="Usuario"
																req={false}
																control={control}
																errors={errors.username}
																defaultValue=""
															/>
															</BsCol>
															<BsCol class="col-md-6">
															<TextFieldWidgetText
																InputLabelProps={{
																shrink: true,
																}}
																name="password"
																labeltext="Contraseña"
																req={false}
																control={control}
																errors={errors.password}
																defaultValue=""
															/>
															</BsCol>
														</BsRow>
														)}

													</BsCol>
											</BsRow>
											<BsRow class="mt-5">
												<BsCol class="col-md-12 text-center">
													<SaveAction
														title="Guardar"
														color="secondary"
														size="large"
														className="roundedButton ml-md-2"
														variant="contained"
														disabled={
															loadingQueryState
														}
														messageType="add"
														mutationLoading={
															loadingQueryState
														}
														success={
															successQueryState
														}
													/>
												</BsCol>
											</BsRow>							
										</form>
									</BsCol>
								</BsRow>
								{errorState && (
									<Snackbar
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
										open={errorState}
										onClose={() => setErrorState(false)}
										autoHideDuration={4500}
										key={Math.random()}
									>
										<SnackBarsComponent
											variant="error"
											message="Data de clima ya registrada"
											handleClose={
												handleCloseSnackBarsComponent
											}
										/>
									</Snackbar>
								)}
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};
export default HdAdd;
