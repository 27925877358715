import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import classes from './Tabs.module.scss';

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<div className={['py-5 px-3', classes.tabPanelStyle].join(' ')}>
					{children}
				</div>
			)}
		</Typography>
	);
};

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

export default TabPanel;
