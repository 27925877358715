import React, {
	useState,
	useEffect,
	useMemo,
	useContext,
	useCallback,
} from 'react';
import { Card, CardContent, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
	DataTableWidget,
	DialogDelete,
	IconActionTableDelete,
	IconActionTableEdit,
	BreadCrumbs,
	CardHeaderWidget,
	EditionAreaWidget,
	SearchBasicSelect,
} from '../../../components';
import {
	handleActionEdit,
	handleActionDelete,
	concatObjectConfigDatatable,
	handleActionEditNew,
	alphabeticalSort,
} from '../../../helpers';

import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
// import '../../../assets/scss/list.scss';
import AmEdit from '../AmEdit';
import viewsTexts from '../../../common/viewsTexts.json';
import { AxiosContext } from '../../../context/AxiosContext';

const useStyles = makeStyles((theme) => ({
	tableContainerStyle: {
		[theme.breakpoints.up('sm')]: {
			paddingRight: 34,
		},
	},
	selectStyle: {
		marginTop: 0,
		marginBottom: 0,
	},
	divider: {
		height: '28px',
		margin: '4px',
	},
	iconButton: {
		padding: '8px',
	},
	actionColumn: {
		display: 'flex',
		justifyContent: 'flex-start',
		width: '100%',
	},
}));

const AmList = () => {
	const theme = useTheme();

	const axiosContext = useContext(AxiosContext);

	const [loadingDataTableState, setLoadingDataTableState] = useState(false);
	const [tableDataState, setTableDataState] = useState([]);
	const [openDialogDeleteState, setOpenDialogDeleteState] = useState(false);
	const [openDialogStateState, setOpenDialogStateState] = useState(false);
	const [resetPaginationToggleState, setResetPaginationToggleState] =
		useState(false);

	const [limitState, setLimitState] = useState(10);
	const [sortState, setSortState] = useState({
		key: 'label',
		option: 'desc',
	});
	const [pageState, setPageState] = useState(1);
	const [countState, setCountState] = useState(0);

	const [editionState, setEditionState] = useState(false);
	const [rowToHandleState, setRowToHandleState] = useState('');
	const [rowToEditState, setRowToEditState] = useState('');
	const [modalState, setModalState] = useState({
		openDetail: false,
		detailModal: '',
	});

	const [busyState, setBusyState] = useState(false);

	const [loadingDeleteState, setLoadingDeleteState] = useState(false);
	const [successDeleteState, setSuccessDeleteState] = useState(false);

	const [loadingStateState, setLoadingStateState] = useState(false);
	const [successStateState, setSuccessStateState] = useState(false);

	const [clientDataState, setClientDataState] = useState([]);
	//const [profileDataState, setProfileDataState] = useState([]);
	const [agrobusinessDataState, setAgrobusinessDataState] = useState([]);
	const [loadingDataState, setLoadingDataState] = useState(false);

	const [filterClientIdState, setFilterClientIdState] = useState(null);
	//cliente huerto especie ese orden.. y poner el estado
	const [filterTextAgrobusinessState, setFilterTextAgrobusionessState] =
		useState(null);

	//const [formProfileState, setFormProfileState] = useState('');
	//const [formUsernameState, setFormUsernameState] = useState('');

	const [formClientState, setFormClientState] = useState('');

	const classes = useStyles();

	const customStyles = {
		headCells: {
			style: {
				color: 'white',
				backgroundColor: theme.palette.primary.light,
				'&: nth-last-child(1)': {
					backgroundColor: theme.palette.primary.main,
					borderBottom: '4px solid',
					borderColor: theme.palette.secondary.light,
				},
				div: {
					whiteSpace: 'normal',
					wordWrap: 'break-word',
				},
			},
		},
	};

	const searchFunc = async (filterText, type) => {
		if (filterText !== '' && type === 'client') {
			const resultClient = clientDataState.find(
				(ele) => ele.id === filterText
			);
			console.log(resultClient, '---resultClient');
			setFilterClientIdState(resultClient.value);
		}
		if (filterText === '' && type === 'resetClient') {
			setFilterClientIdState(null);
		}
	};

	const subHeaderComponentMemo = (
		<>
			<div className="mr-md-4">
				<SearchBasicSelect
					resetFunc={() => {
						searchFunc('', 'resetClient');
						setFormClientState('');
					}}
					id="client"
					loading={loadingDataState}
					options={clientDataState}
					valuetext={formClientState}
					placeholder="Por Cliente"
					filterFunc={() => searchFunc(formClientState, 'client')}
					onFilter={(value) => setFormClientState(value)}
				/>
			</div>
		</>
	);

	console.log(clientDataState, '------clientDataState');

	const columnsConfig = useMemo(
		() => [
			{
				name: 'Cliente',
				selector: (row) => row.client,
				sortField: 'client',
				// hide: 'sm',
				sortable: true,
				id: 1,
			},
			{
				name: 'Agricola',
				selector: (row) => row.label,
				sortField: 'agroBusiness',
				sortable: false,
				id: 2,
			},
		],
		[]
	);

	const actionsConfig = [
		{
			name: 'Acciones',
			width: '150px',
			id: 11,
			cell: (row) => (
				<div className={classes.actionColumn}>
					{row.edit !== false && (
						<>
							<IconActionTableEdit
								handleActionEdit={() => {
									setRowToEditState(row);
									setEditionState(true);
								}}
							/>
							<IconActionTableDelete
								handleActionDelete={() =>
									DialogDeleteHandler(row)
								}
							/>
						</>
					)}
				</div>
			),
		},
	];
	const actions = concatObjectConfigDatatable(actionsConfig[0]);
	const columns = columnsConfig.concat(actions);

	async function queryData(page = pageState, sort = sortState) {
		setLoadingDataTableState(true);
		setBusyState(true);
		try {
			let objQuery = {
				clientValue: filterClientIdState,
				page: page,
				limit: limitState,
				// sort: sort ? sort : sortState,
				sortColumn: sort ? sort.key : sortState.key,
				sortOrder: sort ? sort.option : sortState.option,
			};

			const response = await axiosContext.authAxios.post(
				'v1/agrobusiness/list',
				objQuery
			);

			const { data, status } = response;

			console.log(response, '<-----response query');

			// setCountState(data.totalPassengers);
			if (status === 200) {
				setTableDataState(data.data);
				setCountState(data.count);
				setPageState(page);
				setSortState(sort);
				console.log(
					response,
					'----------------------columnas que se pasan en la query'
				);
			}
		} catch (err) {
			setTableDataState([]);
			console.log('-----------', err);
		} finally {
			setBusyState(false);
			setLoadingDataTableState(false);
		}
	}

	const DialogDeleteHandler = (row) => {
		setRowToHandleState(row);
		setEditionState(false);
		setOpenDialogDeleteState(!openDialogDeleteState);
	};

	const deleteActionDialogHandler = async () => {
		setLoadingDeleteState(true);
		try {
			let objDel = {
				id: rowToHandleState.id,
			};

			console.log(objDel, '----------------------la query que va');

			const responseDel = await axiosContext.authAxios.post(
				'v1/agrobusiness/delete-agrobusiness',
				objDel
			);

			console.log(responseDel, '<-----response query');

			if (responseDel.status === 200) {
				setSuccessDeleteState(true);
			}
		} catch (err) {
			console.log('-----------', err);
		} finally {
			setLoadingDeleteState(false);
		}
		setCountState(countState - 1);
		const newUsers = handleActionDelete(rowToHandleState, tableDataState);
		setTableDataState(newUsers);
	};

	const onCloseSnack = () => {
		setSuccessDeleteState(false);
	};

	const closeEditionAreaHandler = () => {
		setEditionState(false);
	};

	const handleActionEditObj = (newObject) => {
		// console.log('asi pasa al editAction', newObject);
		const newUsers = handleActionEditNew(newObject, tableDataState);
		//console.log('new users to table', newUsers);
		setTableDataState(newUsers);
	};

	// table functions
	const handleOnSort = async (column, sortDirection) => {
		// console.log('handleSort', column, sortDirection);
		let nameColumn = column.sortField;
		// console.log(column.selector.toString().split(".")[1], "---");
		// let nameColumn = column.selector.toString().split('.')[1];

		if (nameColumn !== null) {
			console.log({
				key: nameColumn,
				option: sortDirection,
			});
			if (!busyState) {
				queryData(pageState, {
					key: nameColumn,
					option: sortDirection,
				});
			}
		}
	};

	const handleChangePagination = (page, totalRows) => {
		console.log(page, totalRows);
		// setPageState(page);
		if (!busyState) {
			queryData(page);
		}
	};

	const handleChangeRows = async (currentRowsPerPage, currentPage) => {
		setLoadingDataTableState(false);
		try {
			let objQuery = {
				page: currentPage,
				limit: currentRowsPerPage,
				sort: sortState,
			};

			console.log(objQuery, '--- query rows');

			const response = await axiosContext.authAxios.post(
				'v1/agrobusiness/list',
				objQuery
			);
			const { data, status } = response;

			// console.log(response, "<-----response change rows")

			// setCountState(data.totalPassengers);
			if (status === 200) {
				// setTableDataState(data.data);
				console.log('eeeeeeee en handle', data);
				setTableDataState(data.data);
				// setCountState(data.count);
				setLimitState(currentRowsPerPage);
			}
		} catch (err) {
			setTableDataState([]);
		} finally {
			setLoadingDataTableState(false);
		}
	};

	const getClientsAsync = useCallback(async () => {
		setLoadingDataState(true);
		try {
			let response = await axiosContext.authAxios.post(
				'v1/client/list-clients',
				{
					limit: 500,
				}
			);

			// console.log(response, '<<----respo clientes');

			const { data, status } = response;

			if (status === 200) {
				setClientDataState(alphabeticalSort(data.data, 'label'));
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoadingDataState(false);
		}
	}, [axiosContext, setLoadingDataState, setClientDataState]);

	useEffect(() => {
		// queryData();
		getClientsAsync();
	}, []);

	useEffect(() => {
		if (!busyState) {
			queryData();
			console.log('ejecuta por filter id client');
		}
	}, [filterClientIdState]);

	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-md-12">
						{editionState && (
							<EditionAreaWidget
								component={
									<AmEdit
										rowEdit={rowToEditState}
										closeFunc={closeEditionAreaHandler}
										editFunc={handleActionEditObj}
									/>
								}
								visible={editionState}
							/>
						)}
						<Card elevation={0}>
							<CardHeaderWidget
								title={
									viewsTexts.titles.agrobusiness.list.title
								}
								subheader={<BreadCrumbs subTitle="" />}
							/>
							<CardContent>
								{/* <GenericToolbar search={searchTool} /> */}
								<div
									style={{
										minHeight: '300px',
									}}
								>
									<DataTableWidget
										totalRows={countState}
										handleOnSort={handleOnSort}
										fixedHeader={true}
										fixedHeaderScrollHeight={'590px'}
										// conditionalRowStyles={conditionalRowStyles}
										// sortFunction={customSort}
										changePagination={
											handleChangePagination
										} //onChangePage
										rowsChange={handleChangeRows} //onChangeRowsPerPage
										columns={columns}
										sortField="1"
										filteredItems={tableDataState}
										subHeaderComponentMemo={
											subHeaderComponentMemo
										}
										loading={loadingDataTableState}
										loadingCount={loadingDataTableState}
										customStyles={customStyles}
										moduleName="Agrícolas"
										resetPaginationToggle={
											resetPaginationToggleState
										}
									/>
								</div>
								<DialogDelete
									openDialog={openDialogDeleteState}
									rowDelete={rowToHandleState}
									actionDelete={deleteActionDialogHandler}
									variant="success"
									loading={loadingDeleteState}
									success={
										successDeleteState
											? successDeleteState
											: undefined
									}
									openState={setOpenDialogDeleteState}
									moduleName="Agrícolas"
									actionClose={onCloseSnack}
								/>
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};

export default AmList;
