import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
	SaveAction,
	CardHeaderWidget,
	TextFieldWidgetText,
} from '../../../components';
import { Card, CardContent, Divider } from '@mui/material';
import { BsRow, BsCol } from '../../../layouts/components';
import { CloseButtonEditionArea } from '../../../components/EditionAreaWidget';
import '../../../assets/scss/form.scss';
import viewsTexts from '../../../common/viewsTexts.json';
import { AxiosContext } from '../../../context/AxiosContext';
import { alphabeticalSort } from 'helpers';

const AmEdit = (props) => {
	console.log(props.rowEdit, ' <----llega row a edit');
	const { client, clientValue, label, id } = props.rowEdit;

	const { editFunc, closeFunc } = props;
	// const [clientDataState, setClientDataState] = useState([]);
	// const [agroBusinessDataState, setAgroBusinessDataState] = useState([]);
	// const [loadingDataState, setLoadingDataState] = useState(false);
	// Asume que quieres el rootStock de la primera variedad de la primera especie

	const axiosContext = useContext(AxiosContext);

	const [loadingQueryState, setLoadingQueryState] = useState(false);
	const [successQueryState, setSuccessQueryState] = useState(false);

	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
	} = useForm();

	useEffect(() => {
		reset({
			client: client,
			agroBusiness: label,
			id: id,
		});
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [client, label, id, reset]);

	const onSubmit = async (values) => {
		setLoadingQueryState(true);

		const query = {
			id: values.id,
			name: values.agroBusiness,
		};

		try {
			const response = await axiosContext.authAxios.post(
				'/v1/agrobusiness/update-agrobusiness',
				query
			);

			console.log(response, '<---respuesta de query edit user');

			if (response.status === 200) {
				// alert('listo');
				editFunc(response.data);
				setSuccessQueryState(true);
			}
		} catch (err) {
		} finally {
			setLoadingQueryState(false);
		}
	};

	// const getClientsAsync = useCallback(async () => {
	// 	setLoadingDataState(true);
	// 	try {
	// 		let response = await axiosContext.authAxios.post(
	// 			'v1/client/list-clients',
	// 			{
	// 				limit: 500,
	// 			}
	// 		);

	// 		const { data, status } = response;

	// 		if (status === 200) {
	// 			setClientDataState(alphabeticalSort(data.data, 'label'));
	// 		}
	// 	} catch (error) {
	// 		console.log(error);
	// 	} finally {
	// 		setLoadingDataState(false);
	// 	}
	// }, [axiosContext, setLoadingDataState, setClientDataState]);

	// // endpoints
	// useEffect(() => {
	// 	getClientsAsync();
	// }, []);
	const onCloseSnack = () => {
		setSuccessQueryState(false);
	};

	return (
		<Card elevation={0}>
			<CardHeaderWidget
				title={
					viewsTexts.titles.agrobusiness.agrobusinessTextEdit +
					' ' +
					client +
					' ' +
					label
				}
			/>
			<CloseButtonEditionArea closeFunc={props.closeFunc} />
			<Divider />
			<CardContent>
				<BsRow>
					<BsCol class="col-md-12">
						<form
							autoComplete="on"
							onSubmit={handleSubmit(onSubmit)}
						>
							<BsRow>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="client"
										//labeltext={viewsTexts.forms.orchard}
										labeltext="Cliente"
										req={false}
										control={control}
										errors={errors.client}
										defaultValue=""
										disabled={true}
									/>
									<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="id"
										labeltext=""
										control={control}
										style={{
											display: 'none',
										}}
										defaultValue={id}
									/>
								</BsCol>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="agroBusiness"
										labeltext="Agricola"
										req
										control={control}
										errors={errors.agroBusiness}
										defaultValue=""
										disabled={false}
									/>
								</BsCol>
							</BsRow>
							<BsRow class="mt-5">
								<BsCol class="col-md-12 text-center">
									<SaveAction
										title="Guardar"
										color="secondary"
										size="large"
										className="roundedButton ml-md-2"
										variant="contained"
										disabled={loadingQueryState}
										messageType="edit"
										actionClose={() => {
											closeFunc();
											onCloseSnack();
										}}
										mutationLoading={loadingQueryState}
										success={successQueryState}
									/>
								</BsCol>
							</BsRow>
						</form>
					</BsCol>
				</BsRow>
			</CardContent>
		</Card>
	);
};

export default AmEdit;
