import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText,
} from "../../../components";
import { Card, CardContent, Divider } from "@mui/material";
import { BsRow, BsCol } from "../../../layouts/components";
import { CloseButtonEditionArea } from "../../../components/EditionAreaWidget";
import "../../../assets/scss/form.scss";
import viewsTexts from "../../../common/viewsTexts.json";
import { AxiosContext } from "../../../context/AxiosContext";
import { Context } from "../../../context/Context";

const CmEdit = (props) => {
  console.log(props.rowEdit, " llega row a edit");
  console.log(props);

  const { label, id } = props.rowEdit;

  const { editFunc, closeFunc } = props;
  // const [clientDataState, setClientDataState] = useState([]);
  // const [loadingDataState, setLoadingDataState] = useState(false);

  const { userName } = useContext(Context);

  const axiosContext = useContext(AxiosContext);

  const [loadingQueryState, setLoadingQueryState] = useState(false);
  const [successQueryState, setSuccessQueryState] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    reset({
      clientName: label,
      id: id,
      // username: username,
    });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [label, id, reset]);

  const onSubmit = async (values) => {
    setLoadingQueryState(true);

    const query = {
      id: values.id,
      name: values.clientName,
      username: userName,
    };

    console.log(query, "envia a update");

    try {
      const response = await axiosContext.authAxios.post(
        "v1/client/update-client-platform",
        query
      );

      if (response.status === 200) {
        // alert('listo');
        editFunc(response.data);
        setSuccessQueryState(true);
      }

      setLoadingQueryState(false);
    } catch (err) {
      setLoadingQueryState(false);
    }
  };

  // const getClientsAsync = async () => {

  // 	setLoadingDataState(true);
  // 	try {
  // 		let response = await axiosContext.authAxios.post(
  // 			'v1/client/list-clients'
  // 		);

  // 		console.log(response, '<<----respuesta profiles');

  // 		const { data, status } = response;

  // 		if (status === 200) {
  // 			setClientDataState(data.data);
  // 			setLoadingDataState(false);

  // 		}
  // 	} catch (error) {
  // 		console.log(error);
  // 		setLoadingDataState(false);
  // 		// setLoadingVarietyFilterState(false);
  // 	}
  // };

  // endpoints
  // useEffect(() => {
  // 	getClientsAsync();

  // }, []);

  const onCloseSnack = () => {
    setSuccessQueryState(false);
  };

  return (
    <Card elevation={0}>
      <CardHeaderWidget
        title={viewsTexts.titles.client.clientTextEdit + " " + label}
      />
      <CloseButtonEditionArea closeFunc={props.closeFunc} />
      <Divider />
      <CardContent>
        <BsRow>
          <BsCol class="col-md-12">
            <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
              <BsRow>
                <BsCol class="col-md-6">
                  <TextFieldWidgetText
                    name="clientName"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    minLength={1}
                    maxLength={100}
                    //labeltext={viewsTexts.forms.orchard}
                    labeltext="Cliente"
                    req={true}
                    control={control}
                    errors={errors.clientName}
                    defaultValue=""
                  />
                  <TextFieldWidgetText
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="id"
                    labeltext=""
                    control={control}
                    style={{
                      display: "none",
                    }}
                    defaultValue={id}
                  />
                </BsCol>
              </BsRow>
              <BsRow class="mt-5">
                <BsCol class="col-md-12 text-center">
                  <SaveAction
                    title="Guardar"
                    color="secondary"
                    size="large"
                    className="roundedButton ml-md-2"
                    variant="contained"
                    disabled={loadingQueryState}
                    messageType="edit"
                    actionClose={() => {
                      closeFunc();
                      onCloseSnack();
                    }}
                    mutationLoading={loadingQueryState}
                    success={successQueryState}
                  />
                </BsCol>
              </BsRow>
            </form>
          </BsCol>
        </BsRow>
      </CardContent>
    </Card>
  );
};
export default CmEdit;
