import React, { useContext, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
	AppBar,
	Toolbar,
	// Hidden,
	// useTheme,
	Avatar,
	Menu,
	Button,
	ListItemIcon,
	ListItemText,
	Box,
	Divider,
	useTheme,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { makeStyles } from '@mui/styles';
import LogoTopbar from '../../../../assets/logo-white.png';
import Bgtoplogo from '../../../../assets/backlogo-top.png';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SettingsIcon from '@mui/icons-material/Settings';
// import './SelectStyle.scss';
import { Context } from '../../../../context/Context';
import { FiltersContext } from '../../../../context';
// import CHERRY_ICON from '../../../../assets/icons/species/icono-cereza.png';
// import PIT_ICON from '../../../../assets/icons/species/icono-carozo.png';
// import PLUM_ICON from '../../../../assets/icons/species/icono-ciruela.png';
// import NECTARINE_ICON from '../../../../assets/icons/species/icono-nectarin.png';
// import PEACH_ICON from '../../../../assets/icons/species/icono-durazno.png';

// import Typography from 'theme/typography';
import { IsDesktopHandler } from '../../../../helpers';
import MenuV3Slider from '../MenuV3Slider';

const ICONS = {
	Cerezo: 'CHERRY_ICON',
	Ciruelo: 'PLUM_ICON',
	Nectarines: 'NECTARINE_ICON',
	Duraznero: 'PEACH_ICON',
};

// const drawerWidth = 240;
const linkParams = '/params';

const useStyles = makeStyles((theme) => {
	console.log(theme, '------------------theme');

	return {
		menuButton: {
			marginRight: 36,
		},
		logoTop: {
			maxHeight: 40,
		},
		flexGrow: {
			flexGrow: 1,
		},
		topBarDropDown: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		topBarContainerStyle: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
		},
		rightBar: {
			display: 'flex',
			flexGrow: 1,
			justifyContent: 'space-between',
		},
		buttonProfile: {
			display: 'flex',
			flexDirection: 'column',
			lineHeight: 1.2,
			alignItems: 'flex-start',
			'&& div': {
				fontSize: 14,
				fontWeight: 700,
			},
			'&& span': {
				fontSize: 12,
			},
		},
		specieButtonStyle: {
			color: 'white',
			backgroundColor: 'rgba(255,255,255,0.2)',
			borderRadius: theme.spacing(8),
			textTransform: 'none',
			border: 'solid',
			borderColor: theme.palette.primary.dark,
			borderWidth: 2,
			'&:hover': {
				backgroundColor: 'rgba(255,255,255,0)',
			},
		},
	};
});

const Topbar = (props) => {
	const {
		className,
		onSidebarOpen,
		openState,
		drawerWidth,
		window,
		moduleId,
		...rest
	} = props;

	const {
		// projectSelected,
		// projectList,
		// setProjectSelected,
		userFullname,
		logout,
		userSpecies,
	} = useContext(Context);

	// console.log(userFullname);

	const [anchorElState, setAnchorElState] = useState(null);

	const classes = useStyles();
	const theme = useTheme();
	// const context = useContext(Context);
	// const { clearOptions } = useContext(OptionsContext);
	const {
		logOutFilters,
		state: { specie_filter, userSpecies_filter },
	} = useContext(FiltersContext);

	const history = useHistory();

	const handleTopClick = (event) => {
		setAnchorElState(event.currentTarget);
	};

	const handleTopClose = () => {
		setAnchorElState(null);
	};

	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
		threshold: 90,
	});

	// console.log(userSpecies_filter, specie_filter, '----------------topbar');

	// find en userSpecies_filter el specieValue === specie_filter
	const specieObj = userSpecies_filter
		? userSpecies_filter.find((specie) => specie.value === specie_filter)
		: null;

	const redirect = (link) => {
		history.push({
			pathname: link,
		});
	};

	const redirectHandler = (path) => {
		redirect(path);
		handleTopClose();
	};

	const renderSelects = () => {
		return (
			<div className={classes.topBarContainerStyle}>
				{IsDesktopHandler('sm') && (
					<>
						<Button
							variant="contained"
							className={classes.specieButtonStyle}
							onClick={() =>
								userSpecies.length > 1
									? redirectHandler('/specie-menu')
									: null
							}
							startIcon={
								<Avatar
									alt={specieObj?.label || 'specie'}
									src={ICONS[specieObj?.value || 'Cerezo']}
									sx={{ width: 32, height: 32 }}
								/>
							}
							// endIcon={<KeyboardArrowDownIcon />}
							disableElevation
						>
							{specieObj?.label}
						</Button>
						<Divider
							orientation="vertical"
							variant="middle"
							flexItem
							sx={{ mx: 3, opacity: 0.2 }}
						/>
					</>
				)}
				<Avatar alt="name">
					<PersonIcon />
				</Avatar>
				<Button
					id="profile-button"
					aria-controls="profile-menu"
					aria-haspopup="true"
					color="inherit"
					onClick={handleTopClick}
					aria-expanded={anchorElState ? 'true' : undefined}
					endIcon={<KeyboardArrowDownIcon />}
				>
					<div className={classes.buttonProfile}>
						<div>{userFullname}</div>
						{/* <span>Administrador general</span> */}
					</div>
				</Button>
				<Menu
					id="profile-menu"
					anchorEl={anchorElState}
					// getContentAnchorEl={null}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					keepMounted
					open={Boolean(anchorElState)}
					onClose={handleTopClose}
					MenuListProps={{
						'aria-labelledby': 'profile-button',
					}}
				>
					<MenuItem onClick={() => redirectHandler(linkParams)}>
						<ListItemIcon>
							<SettingsIcon />
						</ListItemIcon>
						<ListItemText primary="Parámetros" />
					</MenuItem>
					<MenuItem
						onClick={() => {
							handleTopClose();
							logOutFilters();
							// clearOptions();
							logout();
						}}
					>
						<ListItemIcon>
							<ExitToAppIcon />
						</ListItemIcon>
						<ListItemText primary="Cerrar sesión" />
					</MenuItem>
				</Menu>
			</div>
		);
	};

	const trigger1 = useScrollTrigger({
		target: window ? window() : undefined,
	});

	// console.log('trigger1', trigger1, theme);

	return (
		<>
			<Slide appear={false} direction="down" in={!trigger1}>
				<AppBar {...rest} elevation={0} position="fixed" className="">
					<Toolbar>
						<div className={classes.rightBar}>
							<RouterLink to="/">
								<img
									className={clsx(classes.logoTop, '')}
									alt="Logo"
									src={LogoTopbar}
								/>
							</RouterLink>

							<div className={classes.flexGrow} />
							{renderSelects()}
						</div>
					</Toolbar>
				</AppBar>
			</Slide>
			{/* no coloca menu a HOME */}
			{moduleId !== '000000000000000000000000' ? (
				<>
					<Toolbar />
					<Box
						sx={{
							minHeight: 70,
							width: '100%',
							// backgroundColor: 'tomato',
							position: 'fixed',
							zIndex: trigger1 ? 1000 : 2,
							top: trigger1 ? 0 : 'auto',
							transition: 'all 1s linear',
							boxShadow: theme.shadows[2],
						}}
					>
						{/* <MenuV3Slider moduleId={moduleId} expand={trigger1} /> */}
					</Box>
				</>
			) : null}
		</>
	);
};

export default Topbar;
