import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import { Topbar } from './components';
import { makeStyles } from '@mui/styles';
import classes from './Minimal.module.scss';
import clsx from 'clsx';	

const useStyles = makeStyles((theme) => ({
	root: {
		// display: 'flex',
		height: '100%',
		// paddingTop: 64,
		[theme.breakpoints.down('sm')]: {
			backgroundColor: theme.palette.background.paper,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		height: '100%',
		paddingRight: theme.spacing(0),
		// paddingLeft: theme.spacing(9),
		// [theme.breakpoints.up('xs')]: {
		// 	paddingLeft: theme.spacing(9),
		// },
		paddingLeft: theme.spacing(0),
		paddingBottom: 0,
	},
}));

const Minimal = (props) => {
	const { children } = props;

	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CssBaseline />
			<Topbar />
			<div className={classes.content}>
				<main className={clsx('contentStyle')}>
					<div className={classes.toolbar} />
					{children}
				</main>
			</div>
		</div>
	);
};

Minimal.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default Minimal;
