import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText,
  TextFieldWidgetSelectControllerHorizontal,
} from "../../../components";
import { Card, CardContent, Divider, TextField } from "@mui/material";
import { BsRow, BsCol } from "../../../layouts/components";
import { CloseButtonEditionArea } from "../../../components/EditionAreaWidget";
import {
  alterArrayGetFields,
  alterArrayAddLabels,
  alphabeticalSort,
} from "../../../helpers";
import "../../../assets/scss/form.scss";
import viewsTexts from "../../../common/viewsTexts.json";
import { AxiosContext } from "../../../context/AxiosContext";
import { unitProductiveAdd } from "../PuDatos/PuDatos";
import { specieData } from "../Options/OptionsPu";
import { Context } from "../../../context/Context";

const PuEdit = (props) => {
  const { userName } = useContext(Context);

  const require = true;

  // console.log(props.rowEdit, " <----llega row a edit");
  const {
    client,
    clientValue,
    agroBusiness,
    agroBusinessValue,
    orchard,
    cc,
    specie,
    specieValue,
    variety,
    varietyValue,
    rootStock,
    rootStockValue,
    quarter,
    season,
    idUnitProductive,
  } = props.rowEdit;

  // console.log(props.rowEdit, "<<---props.rowEdit en PuEdit------------");

  const { editFunc, closeFunc } = props;
  const [loadingFiltersState, setLoadingFiltersState] = useState(false);
  const [filtersDataState, setFiltersDataState] = useState(null);
  const [clientDataState, setClientDataState] = useState([]);
  const [agroBusinessDataState, setAgroBusinessDataState] = useState([]);
  const [loadingDataState, setLoadingDataState] = useState(false);
  // Asume que quieres el rootStock de la primera variedad de la primera especie
  const [rootStockDataState, setRootStockDataState] = useState([]);
  //const [rootStockDataState, setRootStockDataState] = useState([]);
  const [varietyDataState, setVarietyDataState] = useState([]);
  const [specieDataState, setSpecieDataState] = useState(specieData);
  const [orchardsDataState, setOrchardsDataState] = useState([]);
  const [ccDataState, setCcDataState] = useState([]);
  const [showOtherCcState, setShowOtherCcState] = useState(false);
  const axiosContext = useContext(AxiosContext);

  const [loadingQueryState, setLoadingQueryState] = useState(false);
  const [successQueryState, setSuccessQueryState] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    reset,
    watch,
  } = useForm();

  // const watchRut = watch('rut');
  // const watchClient = watch('client');
  // const watchAgroBusiness = watch('agroBusiness');
  // const watchOrchard = watch('orchard');
  const watchSpecie = watch("specie");
  const watchVariety = watch("variety");
  // const watchRootStock = watch('rootStock');

  useEffect(() => {
    reset({
      client: client,
      orchard: orchard,
      cc: cc,
      variety: varietyValue,
      specie: specieValue,
      rootStock: rootStockValue,
      agroBusiness: agroBusinessValue,
      quarter: quarter,
      season: season,
      idUnitProductive: idUnitProductive,
    });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [
    agroBusiness,
    agroBusinessValue,
    orchard,
    cc,
    specie,
    variety,
    rootStock,
    client,
    quarter,
    idUnitProductive,
    reset,
    varietyValue,
    specieValue,
    rootStockValue,
    season,
  ]);

  const onSubmit = async (values) => {
    setLoadingQueryState(true);

    let clientObj = clientDataState.find((ele) => ele.value === values.client);

    // console.log(values, "<<----clientObj");

    let agroBusinessObj = agroBusinessDataState.find(
      (ele) => ele.value === values.agroBusiness
    );

    let orchardObj = orchardsDataState.find(
      (ele) => ele.value === values.orchard
    );

    let specieObj = specieDataState.find((ele) => ele.value === values.specie);

    let varietyObj = varietyDataState.find(
      (ele) => ele.value === values.variety
    );

    let rootStockObj = rootStockDataState.find(
      (ele) => ele.value === values.rootStock
    );

    const query = {
      idUnitProductive: values.idUnitProductive,
      cc: values.cc,
      quarter: values.quarter,
      rootStockValue: values.rootStock,
      rootStock: rootStockObj.rootStock,
      specieValue: values.specie,
      specie: specieObj.specie,
      varietyValue: values.variety,
      variety: varietyObj.variety,
      agroBusinessValue: agroBusinessObj.agroBusinessValue,
      agroBusiness: agroBusinessObj.agroBusiness,
      // TODO: mandar status
      // statusUnitActive: true,
      //username: userName,
      /*clientValue: clientObj.clientValue ,
			client: clientObj.client,
			agroBusinessValue: agroBusinessObj.agroBusinessValue,
			agroBusiness: agroBusinessObj.agroBusiness,
			orchard: values.orchard,
			cc: values.cc,
			specie: values.specie,
			variety: values.variety,
			rootStock: values.rootStock,
			quarter: values.quarter,
			season:values.season,*/
    };

    // console.log(query, "<<---query edit unidad");

    try {
      const response = await axiosContext.authAxios.put(
        "v1/unitProductive/update",
        query
      );

      if (response.status === 200) {
        // alert('listo');
        editFunc(response.data);
        setSuccessQueryState(true);
      }

      setLoadingQueryState(false);
    } catch (err) {
      setLoadingQueryState(false);
    }
  };
  useEffect(() => {
    if (watchSpecie) {
      const resultSpecie = specieDataState.find(
        (ele) => ele.value === watchSpecie
      );
      if (resultSpecie) {
        // Actualiza las opciones de variedad con las nuevas opciones
        setVarietyDataState(
          alphabeticalSort(
            alterArrayAddLabels(resultSpecie.varietyData, [
              "varietyValue",
              "variety",
            ]),
            "label"
          )
        );
        setRootStockDataState(
          alphabeticalSort(
            alterArrayAddLabels(resultSpecie.rootStockData, [
              "rootStockValue",
              "rootStock",
            ]),
            "label"
          )
        );
      } else {
        setVarietyDataState([]);
      }
    }
  }, [watchSpecie, specieDataState]);

  useEffect(() => {
    getDataFiltersAsync();
  }, []);

  const getDataFiltersAsync = async () => {
    setLoadingFiltersState(true);
    try {
      let response = await axiosContext.authAxios.post(
        "v1/unitProductive/filter-create"
      );

      const { data, status } = response;

      if (status === 200) {
        setFiltersDataState(data);
      }
    } catch (error) {
      console.log(error);
      // setLoadingVarietyFilterState(false);
    } finally {
      setLoadingFiltersState(false);
    }
  };

  // console.log(filtersDataState, "<<----filtersDataState en EDIT");

  const handleCloseSnackBarsComponent = () => {
    setErrorState(false);
  };

  useEffect(() => {
    if (filtersDataState) {
      setClientDataState(
        alterArrayAddLabels(filtersDataState.clientData, [
          "clientValue",
          "client",
        ])
      );
      setSpecieDataState(
        alterArrayAddLabels(filtersDataState.specieData, [
          "specieValue",
          "specie",
        ])
      );

      //   cargar data de agrobusiness del cliente

      let clientObj = filtersDataState.clientData.find(
        (ele) => ele.clientValue === clientValue
      );
      console.log(clientObj, "<<------------clientObj");

      let agroBusinessData = clientObj?.agroBusinessData;
      setAgroBusinessDataState(
        alterArrayAddLabels(agroBusinessData, [
          "agroBusinessValue",
          "agroBusiness",
        ])
      );
    }
  }, [filtersDataState, clientValue]);

  const handleCloseSuccess = () => {
    setSuccessQueryState(false);
    closeFunc();
  };

  return (
    <Card elevation={0}>
      <CardHeaderWidget
        title={
          viewsTexts.titles.productiveUnit.puTextEdit +
          " " +
          idUnitProductive +
          " ->" +
          client +
          " " +
          agroBusiness
        }
      />
      <CloseButtonEditionArea closeFunc={props.closeFunc} />
      <Divider />
      <CardContent>
        <BsRow>
          <BsCol class="col-md-12">
            <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
              <BsRow>
                <BsCol class="col-md-6">
                  <TextFieldWidgetText
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="client"
                    //labeltext={viewsTexts.forms.orchard}
                    labeltext="Cliente"
                    req={false}
                    control={control}
                    errors={errors.client}
                    defaultValue=""
                    disabled={true}
                  />
                </BsCol>
                <BsCol class="col-md-6">
                  {/* <TextFieldWidgetText
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name='agroBusiness'
                    labeltext='Agricola'
                    req={false}
                    control={control}
                    errors={errors.agroBusiness}
                    defaultValue=''
                    disabled={true}
                  /> */}
                  <TextFieldWidgetSelectControllerHorizontal
                    options={agroBusinessDataState}
                    titleOption="label"
                    control={control}
                    name="agroBusiness"
                    labeltext="Agricola"
                    errors={errors.agroBusiness}
                    defaultValue=""
                    loading={loadingFiltersState}
                    req
                    withDefault
                    variant="outlined"
                  />
                </BsCol>
              </BsRow>
              <BsRow>
                <BsCol class="col-md-6">
                  <TextFieldWidgetText
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="orchard"
                    //labeltext={viewsTexts.forms.orchard}
                    labeltext="Huerto"
                    req={false}
                    control={control}
                    errors={errors.orchard}
                    defaultValue=""
                    disabled={true}
                  />
                </BsCol>
                <BsCol class="col-md-6">
                  <TextFieldWidgetText
                    name="cc"
                    //labeltext={viewsTexts.forms.cc}
                    labeltext="Centro de costo"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    req={true}
                    control={control}
                    errors={errors.cc}
                    defaultValue=""
                  />
                </BsCol>
              </BsRow>
              <BsRow>
                <BsCol class="col-md-6">
                  <TextFieldWidgetText
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="quarter"
                    //labeltext={viewsTexts.forms.specie}
                    labeltext="Cuartel"
                    req={true}
                    control={control}
                    errors={errors.quarter}
                    defaultValue=""
                  />
                </BsCol>
                <BsCol class="col-md-6">
                  <TextFieldWidgetSelectControllerHorizontal
                    options={specieDataState}
                    titleOption="label"
                    control={control}
                    name="specie"
                    labeltext="Especie"
                    errors={errors.specie}
                    defaultValue=""
                    loading={loadingFiltersState}
                    req
                    withDefault
                    variant="outlined"
                  />
                </BsCol>
              </BsRow>
              <BsRow>
                <BsCol class="col-md-6">
                  <TextFieldWidgetSelectControllerHorizontal
                    options={varietyDataState}
                    titleOption="label"
                    control={control}
                    name="variety"
                    labeltext="Variedad"
                    errors={errors.variety}
                    defaultValue=""
                    loading={loadingFiltersState}
                    req
                    withDefault
                    variant="outlined"
                  />
                </BsCol>
                <BsCol class="col-md-6">
                  <TextFieldWidgetSelectControllerHorizontal
                    options={rootStockDataState}
                    titleOption="label"
                    control={control}
                    name="rootStock"
                    labeltext="Porta injerto"
                    errors={errors.rootStock}
                    multiple={false}
                    defaultValue=""
                    loading={loadingFiltersState}
                    req
                    withDefault
                    variant="outlined"
                  />
                </BsCol>
              </BsRow>
              <BsRow class="mt-5">
                <BsCol class="col-md-12 text-center">
                  <SaveAction
                    title="Guardar"
                    color="secondary"
                    size="large"
                    className="roundedButton ml-md-2"
                    variant="contained"
                    disabled={loadingQueryState}
                    messageType="edit"
                    actionClose={handleCloseSuccess}
                    mutationLoading={loadingQueryState}
                    success={successQueryState}
                  />
                </BsCol>
              </BsRow>
            </form>
          </BsCol>
        </BsRow>
      </CardContent>
    </Card>
  );
};

export default PuEdit;
