import React, {
	useState,
	Fragment,
	useContext,
	useRef,
	useEffect,
} from 'react';
import { NavLink, useRouteMatch, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import {
	Link,
	List,
	ListItem,
	Tooltip,
	Divider,
	Hidden,
	ListItemButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import iconJson from '../../../../common/iconSVG.json';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SvgIcon from '@mui/material/SvgIcon';
import classesModule from './SidebarNav.module.scss';
import { useTheme, withStyles, makeStyles } from '@mui/styles';
import { Context } from '../../../../context/Context';
import _ from 'lodash';

import { renderMaterialIcon } from '../../../../layouts';

// const ListItemCustom = withStyles((theme) => ({
//   root: {
//     '&:hover': {
//       // backgroundColor: 'blue',
//       '& .MuiListItemIcon-root': {
//         // color: theme.palette.primary.dark,
//       },
//       '& .MuiTypography-root': {
//         color: theme.palette.primary.dark,
//       },
//     },
//     '&.active': {
//       // backgroundColor: theme.palette.primary.dark,
//       // color: theme.palette.primary.dark,
//       '& .MuiListItemIcon-root': {
//         color: theme.palette.white,
//         backgroundColor: theme.palette.primary.dark,
//       },
//       '& .MuiTypography-root': {
//         color: theme.palette.primary.dark,
//       },
//     },
//     '&.activeSub': {
//       // backgroundColor: theme.palette.primary.dark,
//       // color: theme.palette.primary.dark,
//       '& .MuiListItemIcon-root': {
//         color: theme.palette.white,
//         backgroundColor: theme.palette.primary.dark,
//       },
//       '& .MuiTypography-root': {
//         color: theme.palette.primary.dark,
//       },
//     },
//   },
// }))(ListItem);

const CollapseCustom = styled(Collapse, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	backgroundColor: theme.palette.grey[50],
	boxShadow: theme.shadows[25],
	'& .MuiTypography-root': {
		fontWeight: 500,
	},
	'& .Mui-selected, & .active': {
		// backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.main,
		// borderRadius: 8,
		'& .MuiListItemIcon-root': {
			color: theme.palette.primary.main,
		},
		'& .MuiTypography-root': {
			color: theme.palette.primary.main,
			fontWeight: 500,
		},
		'&:hover': {
			// backgroundColor: theme.palette.primary.dark,
		},
	},
}));

const useStyles = makeStyles((theme) => ({
	listItemStyle: {},
	listItemButtonStyle: {
		minHeight: 40,
		'& .MuiTypography-root': {
			fontWeight: 500,
		},
		'&.Mui-selected, &.active': {
			backgroundColor: theme.palette.primary.main,
			color: 'white',
			borderRadius: 8,
			'& .MuiListItemIcon-root': {
				color: 'white',
			},
			'& .MuiTypography-root': {
				color: 'white',
				fontWeight: 500,
			},
			'&:hover': {
				backgroundColor: theme.palette.primary.dark,
			},
		},
	},
}));

const SidebarNav = (props) => {
	const { pages, className, open, ...rest } = props;

	const { logout } = useContext(Context);

	// const [activeIdItem, setActiveIdItem] = useState(null);

	// crear un useRef que mantenda el valor del IdItem activo
	const activeIdItem = useRef(null);

	const classes = useStyles();

	const [openState, setOpenState] = useState({
		values: {},
	});

	const theme = useTheme();

	// console.log(pages, '--------asi llega pages a SidebarNav');

	const handleClickSubItem = (pageId, submenuId) => {
		// setActiveIdItem(pageId);

		activeIdItem.current = pageId;

		setOpenState((openState) => ({
			values: {
				...openState.values,
				[submenuId]: !openState.values[submenuId],
			},
		}));
	};

	const handleClickParentSubmenu = (id) => {
		// console.log(id, '<-----id click1', openState);
		// setActiveIdItem(id);
		setOpenState((openState) => ({
			values: {
				...!openState.values,
				[id]: !openState.values[id],
			},
		}));
	};

	const handleClickNoSub = (id) => {
		// console.log(id, '<-----id handleClickNoSub');
		// setActiveIdItem(id);

		activeIdItem.current = id;

		setOpenState((openState) => ({
			values: {
				...!openState.values,
				[id]: !openState.values[id],
			},
		}));
	};

	const handleClickParentSubSubmenu = (idMenu, idSubmenu) => {
		// console.log(idMenu, '<-----handleClickParentSubSubmenu', idSubmenu);
		// setActiveIdItem(id);
		setOpenState((openState) => ({
			values: {
				...!openState.values,
				[idMenu]: openState.values[idMenu],
				[idSubmenu]: !openState.values[idSubmenu],
			},
		}));
	};

	const sortedPages = _.sortBy(pages, _.property('menuOrder'));

	// console.log(sortedPages, '<<<<<<-----------pages sorted');
	const sortedPagesWithoutHarvest = sortedPages.filter(
		(ele) => ele.id !== '6581eae55fee8e0236a4dcdc'
	);

	const HarvestModuleAssigned = sortedPages.filter(
		(ele) => ele.id === '6581eae55fee8e0236a4dcdc'
	);

	let arraySubSubsHarvest = [];

	// obtiene los id de los submenus de cosecha
	const activeIdsSubSubHarvest = _.chain(arraySubSubsHarvest)
		.flatMap()
		.map('id')
		.filter(Boolean)
		.value();

	// console.log(
	//   activeIdsSubSubHarvest,
	//   '<<<<<<-----------activeIdsSubSubHarvest'
	// );

	// const isCurrentPath = useRouteMatch('/library');
	// console.log(isCurrentPath, '<<<<<<-----------isCurrentPath');

	// controla si la ruta actual coinicde con la ruta del menu Item Button
	const handleActiveRoute = (path) => {
		const isCurrentPath = useRouteMatch(`/${path}`);

		if (isCurrentPath?.isExact) {
			const menuActive = sortedPagesWithoutHarvest.find(
				(page) => page.href === path
			);

			console.log(menuActive, '----menuActive cuando es active SIMPLE');
			activeIdItem.current = menuActive.id;
		}

		return isCurrentPath?.isExact || false;
	};

	function findParentMenu(array, href) {
		if (array.submenu && Array.isArray(array.submenu)) {
			return array.submenu.some((item) => item.href === href);
		}
		return false;
	}

	const handleActiveRouteWithSub = (path) => {
		const menuActive = sortedPagesWithoutHarvest.find(
			(page) => page.href === path
		);

		// definir una variable que captura la url actual
		const location = useLocation();

		const menuObtained = findParentMenu(menuActive, location.pathname);

		// if (menuObtained) {
		//   console.log(menuActive, '----menuActive cuando es active');
		// } else {
		//   console.log(menuActive, '----menuActive cuando no es active');
		// }

		return menuObtained;
	};

	useEffect(() => {
		// console.log(activeIdItem.current, '<<<<<<-----------activeIdItem useEffect');

		// find en sortedPagesWithoutHarvest del id que coincida con activeIdItem.current
		const menuActive = sortedPagesWithoutHarvest.find(
			(page) => page.id === activeIdItem.current
		);

		if (!menuActive?.submenu) {
			const updatedValues = Object.keys(openState.values).reduce(
				(acc, key) => {
					acc[key] = false;
					return acc;
				},
				{}
			);
			setOpenState({ values: updatedValues });
		}
	}, [activeIdItem.current]);

	return (
		<List {...rest} className={clsx(className)}>
			{/* <ListItem
				button
				activeClassName={classes.active}
				className={classes.item}
				key={1000}
				selected={false}
				component={NavLink}
				to="/"
				onClick={() => handleClick(1000)}
				name={1000}>
				<ListItemIcon className={classes.icon}><DashboardIcon /></ListItemIcon>
				<ListItemText primary="Inicio" />
			</ListItem> */}
			{sortedPagesWithoutHarvest.map((page) => (
				<Fragment key={page.id + 'fragment'}>
					{page.submenu ? (
						<>
							<Tooltip
								title={page.title}
								aria-label={page.title}
								placement="right"
								arrow
								key={page.id}
								disableHoverListener={open}
							>
								<ListItem
									key="sub"
									disablePadding
									className={classes.listItemStyle}
									sx={{ display: 'block', px: 0.5 }}
									name={page.id}
								>
									<ListItemButton
										selected={handleActiveRouteWithSub(
											page.href
										)}
										component={Link}
										onClick={() =>
											handleClickParentSubmenu(page.id)
										}
										className={classes.listItemButtonStyle}
										sx={{
											justifyContent: open
												? 'initial'
												: 'center',
										}}
									>
										<ListItemIcon
											sx={{
												minWidth: 0,
												mr: open ? 2 : 'auto',
												justifyContent: 'center',
											}}
										>
											{page.iconType === 'material' ? (
												renderMaterialIcon(page.icon)
											) : (
												<SvgIcon viewBox="0 -1 18 22">
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d={
															iconJson.icon[
																page.icon
															]
														}
													/>
												</SvgIcon>
											)}
										</ListItemIcon>
										<ListItemText
											primary={page.title}
											sx={{ opacity: open ? 1 : 0 }}
										/>
										{openState.values[page.id] ? (
											<ExpandLess />
										) : (
											<ExpandMore />
										)}
									</ListItemButton>
								</ListItem>
							</Tooltip>
							<CollapseCustom
								// className={classesModule.Collapse}
								in={openState.values[page.id]}
								timeout="auto"
								sx={{ mx: 0.5 }}
								unmountOnExit
								key={page.id + 'submenu'}
							>
								<List
									component="div"
									disablePadding
									dense={true}
								>
									{page.submenu.map((submenu) => (
										<React.Fragment key={submenu.id}>
											<Tooltip
												title={`${page.title} > ${submenu.title}`}
												aria-label={`${page.title} > ${submenu.title}`}
												placement="right"
												arrow
												disableHoverListener={open}
											>
												<ListItemButton
													sx={{ pl: open ? 3 : 2 }}
													component={NavLink}
													to={submenu.href}
													selected={useRouteMatch({
														path: `${submenu.href}/*`,
														end: true,
													})}
													onClick={() =>
														handleClickSubItem(
															page.id,
															submenu.id
														)
													}
													// selected={activeIdItem === page.id}
													name={submenu.id}
													// data-menu={page.id}
												>
													<ListItemIcon
														sx={{
															minWidth: 0,
															mr: open
																? 2
																: 'auto',
															justifyContent:
																'center',
														}}
													>
														{submenu.iconType ===
														'material' ? (
															renderMaterialIcon(
																submenu.icon
															)
														) : (
															<SvgIcon
																width="24"
																height="24"
																viewBox="0 0 24 24"
															>
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	// stroke="#66788E"
																	// stroke-width="2"
																	d={
																		iconJson
																			.icon[
																			submenu
																				.icon
																		]
																	}
																/>
															</SvgIcon>
														)}
													</ListItemIcon>
													<ListItemText
														primary={submenu.title}
														sx={{
															opacity: open
																? 1
																: 0,
														}}
													/>
												</ListItemButton>
											</Tooltip>
											{(submenu.id === 82 ||
												submenu.id === 84) && (
												<Divider
													style={{
														backgroundColor:
															'white',
													}}
												/>
											)}
										</React.Fragment>
									))}
								</List>
							</CollapseCustom>
						</>
					) : (
						<>
							<Tooltip
								title={page.title}
								aria-label={page.title}
								placement="right"
								arrow
								disableHoverListener={open}
							>
								<ListItem
									key={page.id}
									disablePadding
									sx={{ display: 'block', px: 0.5 }}
									className={classes.listItemStyle}
									name={page.id}
								>
									<ListItemButton
										// selected={activeIdItem === page.id}
										selected={handleActiveRoute(page.href)}
										component={NavLink}
										to={page.href ? page.href : 'null'}
										onClick={() =>
											handleClickNoSub(page.id)
										}
										className={classes.listItemButtonStyle}
										sx={{
											justifyContent: open
												? 'initial'
												: 'center',
										}}
									>
										<ListItemIcon
											sx={{
												minWidth: 0,
												mr: open ? 2 : 'auto',
												justifyContent: 'center',
											}}
										>
											{page.iconType === 'material' ? (
												renderMaterialIcon(page.icon)
											) : (
												<SvgIcon viewBox="0 -1 18 22">
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														// stroke="#66788E"
														// stroke-width="2"
														d={
															iconJson.icon[
																page.icon
															]
														}
													/>
												</SvgIcon>
											)}
										</ListItemIcon>
										<ListItemText
											primary={page.title}
											sx={{
												opacity: open ? 1 : 0,
											}}
										/>
									</ListItemButton>
								</ListItem>
							</Tooltip>
						</>
					)}
				</Fragment>
			))}

			<Hidden only={['sm', 'md', 'lg', 'xl']}>
				<Divider className="my-3" />
				<ListItem disablePadding dense={true}>
					<ListItemButton
						key={1000}
						selected={false}
						component={NavLink}
						to="/"
						onClick={() => logout()}
						name={1000}
					>
						<ListItemIcon>
							<ExitToAppIcon />
						</ListItemIcon>
						<ListItemText primary="Cerrar Sesión" />
					</ListItemButton>
				</ListItem>
			</Hidden>
		</List>
	);
};

export default SidebarNav;
