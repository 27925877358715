import React, { useState, useEffect, useMemo, useContext } from "react";
import {
  Card,
  CardContent,
  Divider,
  IconButton,
  Paper,
  TextField,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
  SearchBasicTable,
  ModalWidget,
  ModalWidgetDetailTable,
  DataTableWidget,
  DialogDelete,
  DialogState,
  IconActionTableDelete,
  IconActionTableState,
  IconActionTableEdit,
  IconActionTableDetail,
  BreadCrumbs,
  CardHeaderWidget,
  EditionAreaWidget,
  LabelChip,
  TextFieldWidgetSelect,
  SearchBasicSelect,
} from "../../../components";
import {
  // handleActionDetail,
  handleActionState,
  handleActionEdit,
  handleActionDelete,
  concatObjectConfigDatatable,
  alterArrayGetFields,
  alphabeticalSort,
  handleActionEditNew,
} from "../../../helpers";

import { BsContainer, BsRow, BsCol } from "../../../layouts/components";
// import '../../../assets/scss/list.scss';
import PuEdit from "../PuEdit";
import viewsTexts from "../../../common/viewsTexts.json";
import { AxiosContext } from "../../../context/AxiosContext";
import { Context } from "../../../context/Context";
import { map, result, set } from "lodash";

const useStyles = makeStyles((theme) => ({
  tableContainerStyle: {
    [theme.breakpoints.up("sm")]: {
      paddingRight: 34,
    },
  },
  selectStyle: {
    marginTop: 0,
    marginBottom: 0,
  },
  divider: {
    height: "28px",
    margin: "4px",
  },
  iconButton: {
    padding: "8px",
  },
  actionColumn: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
}));

const handleActionUpdateState = (newObject, allObjects) => {
  const newArrayToEdit = [...allObjects];
  // find in data the index of the object to edit
  const index = newArrayToEdit.findIndex((obj) => obj.id === newObject.id);
  // replace the object in the array with the new edited object
  newArrayToEdit[index] = {
    ...newObject,
  };

  console.log(newArrayToEdit, "<----newArrayToEdit");

  return newArrayToEdit;
};

const PuList = () => {
  const theme = useTheme();

  const axiosContext = useContext(AxiosContext);

  const { userName } = useContext(Context);

  const [loadingDataTableState, setLoadingDataTableState] = useState(false);
  const [tableDataState, setTableDataState] = useState([]);
  const [openDialogDeleteState, setOpenDialogDeleteState] = useState(false);
  const [openDialogStateState, setOpenDialogStateState] = useState(false);
  const [resetPaginationToggleState, setResetPaginationToggleState] =
    useState(false);

  console.log(tableDataState, "<----tableDataState", userName);

  const [limitState, setLimitState] = useState(10);
  const [sortState, setSortState] = useState({
    field: "client",
    order: "asc",
  });
  const [pageState, setPageState] = useState(1);
  const [countState, setCountState] = useState(0);

  const [editionState, setEditionState] = useState(false);
  const [rowToHandleState, setRowToHandleState] = useState("");
  const [rowToEditState, setRowToEditState] = useState("");
  const [modalState, setModalState] = useState({
    openDetail: false,
    detailModal: "",
  });

  const [busyState, setBusyState] = useState(false);

  const [loadingDeleteState, setLoadingDeleteState] = useState(false);
  const [successDeleteState, setSuccessDeleteState] = useState(false);

  const [loadingStateState, setLoadingStateState] = useState(false);
  const [successStateState, setSuccessStateState] = useState(false);

  const [clientDataState, setClientDataState] = useState([]);
  //const [profileDataState, setProfileDataState] = useState([]);
  const [agroBusinessDataState, setAgroBusinessDataState] = useState([]);
  const [quarterDataState, setQuarterDataState] = useState([]);
  const [orchardDataState, setOrchardDataState] = useState([]);
  const [orchardFullDataState, setOrchardFullDataState] = useState([]);
  const [loadingDataState, setLoadingDataState] = useState(false);

  //const [filterTextUsernameState, setFilterTextUsernameState] = useState(null);
  //const [filterProfileIdState, setFilterProfileIdState] = useState(null);

  const [filterClientIdState, setFilterClientIdState] = useState(null);
  //cliente huerto especie ese orden.. y poner el estado
  const [filterOrchardState, setFilterOrchardState] = useState(null);
  const [filterTextQuarterState, setFilterTextQuarterState] = useState(null);
  const [filterTextSpecieState, setFilterTextSpecieState] = useState(null);
  const [filterTextAgrobusinessState, setFilterTextAgrobusinessState] =
    useState(null);

  //const [formProfileState, setFormProfileState] = useState('');
  //const [formUsernameState, setFormUsernameState] = useState('');

  const [formClientState, setFormClientState] = useState("");
  const [formOrchardState, setFormOrchardState] = useState("");
  const [formSpecieState, setFormSpecieState] = useState("");
  const [formQuarterState, setFormQuarterState] = useState("");

  const classes = useStyles();

  const customStyles = {
    headCells: {
      style: {
        color: "white",
        backgroundColor: theme.palette.primary.light,
        "&: nth-last-child(1)": {
          backgroundColor: theme.palette.primary.main,
          borderBottom: "4px solid",
          borderColor: theme.palette.secondary.light,
        },
        div: {
          whiteSpace: "normal",
          wordWrap: "break-word",
        },
      },
    },
  };

  const searchFunc = async (filterText, type) => {
    let filter = {};

    if (filterText !== "" && type === "client") {
      const resultClient = clientDataState.find((ele) => ele.id === filterText);
      setFilterClientIdState(resultClient.value);
    }
    if (filterText === "" && type === "resetClient") {
      setFilterClientIdState(null);
    }
    if (filterText !== "" && type === "quarter") {
      setFilterTextQuarterState(filterText);
    }
    if (filterText === "" && type === "resetQuarter") {
      setFilterTextQuarterState(null);
    }
    if (filterText !== "" && type === "orchard") {
      const resultOrchard = orchardDataState.find(
        (ele) => ele.value === filterText
      );
      const resultClient = clientDataState.find(
        (ele) => ele.id === formClientState
      );
      // console.log(formClientState, '---------formClientState');
      setFilterClientIdState(resultClient?.value);
      setFilterOrchardState(resultOrchard.value);
    }
    if (filterText === "" && type === "resetOrchard") {
      setFilterOrchardState(null);
    }
  };

  const subHeaderComponentMemo = (
    <>
      <div className="mr-md-4">
        <SearchBasicTable
          resetFunc={() => {
            searchFunc("", "resetQuarter");
            setFormQuarterState("");
          }}
          id="quarter"
          valuetext={formQuarterState}
          placeholder="Cuartel"
          filterFunc={() => searchFunc(formQuarterState, "quarter")}
          onFilter={(value) => setFormQuarterState(value)}
        />
      </div>
      <SearchBasicSelect
        resetFunc={() => {
          searchFunc("", "resetClient");
          setFormClientState("");
        }}
        id="client"
        loading={loadingDataState}
        options={clientDataState}
        valuetext={formClientState}
        placeholder="Por Cliente"
        filterFunc={() => searchFunc(formClientState, "client")}
        onFilter={(value) => setFormClientState(value)}
      />
      <div className="mr-md-4">
        <SearchBasicSelect
          resetFunc={() => {
            searchFunc("", "resetOrchard");
            setFormOrchardState("");
          }}
          id="orchard"
          loading={loadingDataState}
          options={orchardDataState}
          valuetext={formOrchardState}
          placeholder="Por Huerto"
          filterFunc={() => searchFunc(formOrchardState, "orchard")}
          onFilter={(value) => {
            setFormOrchardState(value);
          }}
        />
      </div>
    </>
  );

  const columnsConfig = useMemo(
    () => [
      {
        name: "Cliente",
        selector: (row) => row.client,
        sortField: "client",
        // hide: 'sm',
        sortable: true,
        id: 1,
      },
      {
        name: "Agricola",
        selector: (row) => row.agroBusiness,
        sortField: "agroBusiness",
        sortable: false,
        id: 2,
      },
      {
        name: "Huerto",
        selector: (row) => row.orchard,
        sortField: "orchard",
        sortable: true,
        id: 3,
      },
      {
        name: "Centro de Costo",
        selector: (row) => row.cc,
        sortField: "cc",
        sortable: false,
        id: 4,
      },
      {
        name: "Cuartel",
        selector: (row) => row.quarter,
        sortField: "quarter",
        sortable: true,
        id: 5,
      },
      {
        name: "ID UNIDAD",
        selector: (row) => row.idUnitProductive,
        sortField: "idUnitProductive",
        sortable: false,
        id: 6,
      },
      {
        name: "Especie",
        selector: (row) => row.specie,
        sortField: "specie",
        sortable: false,
        id: 7,
      },
      {
        name: "Variedad",
        selector: (row) => row.variety,
        sortField: "variety",
        sortable: false,
        id: 8,
      },
      {
        name: "Porta Injerto",
        selector: (row) => row.rootStock,
        sortField: "rootStock",
        sortable: false,
        id: 9,
      },
      // {
      // 	name: 'Estado',
      // 	selector: (row) => row.statusUnitActive,
      // 	cell: (row) => (
      // 		<LabelChip
      // 			label={row.statusUnitActive ? 'Activo' : 'Inactivo'}
      // 		/>
      // 	),
      // 	sortable: false,
      // 	id: 10,
      // },
    ],
    []
  );

  const actionsConfig = [
    {
      name: "Acciones",
      width: "200px",
      id: 11,
      cell: (row) => (
        <div className={classes.actionColumn}>
          <IconActionTableEdit
            handleActionEdit={() => {
              setRowToEditState(row);
              setEditionState(true);
            }}
          />
          <IconActionTableState
            tooltip="Activar/Desactivar Años"
            moduleName="productiveUnit"
            rowState={row.statusUnitActive}
            handleActionState={() => DialogStateHandler(row)}
          />
          {/* <IconActionTableDelete
						handleActionDelete={() => DialogDeleteHandler(row)}
					/> */}
        </div>
      ),
    },
  ];
  const actions = concatObjectConfigDatatable(actionsConfig[0]);
  const columns = columnsConfig.concat(actions);

  async function queryData(page = pageState, sort = sortState) {
    setLoadingDataTableState(true);
    try {
      let objQuery = {
        clientValue: filterClientIdState,
        orchardValue: filterOrchardState,
        search: formQuarterState,
        page: page,
        limit: limitState,
        sortColumn: sort ? sort.key : sortState.key,
        sortOrder: sort ? sort.option : sortState.option,
      };

      console.log(objQuery, "<----objQuery");

      const response = await axiosContext.authAxios.post(
        "/v1/unitProductive/list",
        objQuery
      );

      const { data, status } = response;

      console.log(data, "<-----response queryData");

      // setCountState(data.totalPassengers);
      if (status === 200) {
        setTableDataState(data.data);
        setCountState(data.count);
        setPageState(page);
        setSortState(sort);
      }
      setLoadingDataTableState(false);
    } catch (err) {
      setTableDataState([]);
      setLoadingDataTableState(false);
      console.log("-----------", err);
    } finally {
      setBusyState(false);
    }
  }

  // state
  const DialogStateHandler = (row) => {
    setEditionState(false);
    setRowToHandleState(row);
    setOpenDialogStateState(!openDialogStateState);
  };

  const stateActionDialogHandler = async (activeYears, newYears) => {
    setLoadingStateState(true);

    try {
      const bodyStatus = {
        idUnitProductive: rowToHandleState.idUnitProductive,
        username: userName,
        seasons: activeYears,
        newSeasons: newYears,
      };

      const responseSta = await axiosContext.authAxios.post(
        "v1/unitProductive/delete/",
        bodyStatus
      );

      if (responseSta.status === 200) {
        const seasonsResponse = responseSta.data;

        const newObj = {
          ...rowToHandleState,
          seasons: seasonsResponse?.seasons,
          statusUnitActive: seasonsResponse?.statusUnitActive,
        };

        const newUsers = handleActionUpdateState(newObj, tableDataState);

        setTableDataState(newUsers);

        setSuccessStateState(true);

        // setLoadingStateState(false);
      }
    } catch (err) {
      console.log("-----------", err);
    } finally {
      // setSuccessStateState(false);
      setLoadingStateState(false);
    }
  };

  // const DialogDeleteHandler = (row) => {
  // 	setRowToHandleState(row);
  // 	setEditionState(false);
  // 	setOpenDialogDeleteState(!openDialogDeleteState);
  // };

  // const deleteActionDialogHandler = async () => {
  // 	setLoadingDeleteState(true);
  // 	try {
  // 		// let objDel = {
  // 		// 	id: rowToHandleState.id,
  // 		// };

  // 		// console.log(objDel, '----------------------la query que va');

  // 		const responseDel = await axiosContext.authAxios.delete(
  // 			'v1/unitProductive/delete/' + rowToHandleState.id
  // 		);

  // 		if (responseDel.status === 200) {
  // 			setSuccessDeleteState(true);
  // 			setLoadingDeleteState(false);
  // 			setSuccessDeleteState(false);
  // 		}
  // 	} catch (err) {
  // 		console.log('-----------', err);
  // 		setLoadingDeleteState(false);
  // 	}
  // 	setCountState(countState - 1);
  // 	const newUsers = handleActionDelete(rowToHandleState, tableDataState);
  // 	setTableDataState(newUsers);
  // };

  const closeEditionAreaHandler = () => {
    setEditionState(false);
  };

  const editActionHandler = (newObject) => {
    // console.log('asi pasa al editAction', newObject);
    const newUsers = handleActionEditNew(newObject, tableDataState);

    setTableDataState(newUsers);
  };

  //const searchTool = false;

  // table functions
  const handleOnSort = async (column, sortDirection) => {
    // console.log('handleSort', column, sortDirection);
    let nameColumn = column.sortField;
    // console.log(column.selector.toString().split(".")[1], "---");
    // let nameColumn = column.selector.toString().split('.')[1];

    if (nameColumn !== null) {
      if (!busyState) {
        console.log("queryData en handleOnSort");
        queryData(pageState, {
          key: nameColumn,
          option: sortDirection,
        });
      }
    }
  };

  const handleChangePagination = (page, totalRows) => {
    // setPageState(page);
    if (!busyState) {
      console.log("queryData en handleChangePagination");
      queryData(page);
    }
  };

  const handleChangeRows = async (currentRowsPerPage, currentPage) => {
    setLoadingDataTableState(false);
    try {
      let objQuery = {
        clientValue: filterClientIdState,
        orchardValue: filterOrchardState,
        search: formQuarterState,
        page: currentPage,
        limit: currentRowsPerPage,
        sortColumn: sortState?.key,
        sortOrder: sortState?.option,
      };

      console.log("queryData en handleChangeRows");

      const response = await axiosContext.authAxios.post(
        "v1/unitProductive/list",
        objQuery
      );
      const { data, status } = response;

      // console.log(response, "<-----response change rows")

      // setCountState(data.totalPassengers);
      if (status === 200) {
        // setTableDataState(data.data);
        setTableDataState(data.data);
        // setCountState(data.count);
        setLimitState(currentRowsPerPage);
      }
      setLoadingDataTableState(false);
    } catch (err) {
      setTableDataState([]);
      setLoadingDataTableState(false);
    }
  };

  const handleCloseModal = () => {
    setModalState({
      ...modalState,
      openDetail: false,
    });
  };

  const getClientsAsync = async () => {
    setLoadingDataState(true);
    try {
      let objQuery = {
        page: 1,
        limit: 2000,
      };

      let response = await axiosContext.authAxios.post(
        "v1/client/list-clients",
        objQuery
      );

      const { data, status } = response;

      if (status === 200) {
        setClientDataState(alphabeticalSort(data.data, "label"));
      }
    } catch (error) {
      console.log(error);
      // setLoadingVarietyFilterState(false);
    } finally {
      setLoadingDataState(false);
    }
  };
  const getOrchardAsync = async () => {
    setLoadingDataState(true);
    try {
      let objQuery = {
        page: 1,
        limit: 2000,
      };
      let response = await axiosContext.authAxios.post(
        "v1/orchard/list-orchard-platform",
        objQuery
      );
      const { data, status } = response;
      if (status === 200) {
        setOrchardFullDataState(data.data);

        const orchardDataFinal = alterArrayGetFields(data.data, [
          "label",
          "value",
        ]);
        const orchardData = map(orchardDataFinal, (orchard) => {
          // console.log(orchard, "----orchard en map")
          return {
            label: orchard.label,
            value: orchard.value,
            id: orchard.value,
          };
        });
        // setOrchardDataState(alphabeticalSort(orchardData, 'label'));
        setLoadingDataState(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingDataState(false);
    }
  };

  useEffect(() => {
    // queryData(pageState);
    getClientsAsync();
    getOrchardAsync();
  }, []);

  useEffect(() => {
    if (!busyState) {
      console.log("queryData en useEffect 2 filters");
      queryData();
    }
  }, [filterClientIdState, filterTextQuarterState, filterOrchardState]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!busyState) {
        console.log("queryData en useEffect timer");
        queryData();
      }
    }, 500);
    return () => clearTimeout(timeOut);
  }, [formQuarterState]);

  // hacer un use effect para que ante cambios de formUsernameState
  // se haga la busqueda con un debounce de 300ms

  // TODO: cuando cambia cliente, tiene valor filtra huertos
  useEffect(() => {
    if (formClientState) {
      // console.log(orchardDataState, 'orchardDataState', formClientState);
      const resultClient = clientDataState.find(
        (ele) => ele.id === formClientState
      );
      // console.log(resultClient.value, '---resultClient', orchardFullDataState);

      console.log(resultClient, "----resultClient", orchardFullDataState);

      const filteredOrchardData = orchardFullDataState.filter(
        (ele) => ele.clientValue === resultClient.value
      );

      const orchardDataFinal = alterArrayGetFields(filteredOrchardData, [
        "label",
        "value",
      ]);
      const orchardData = map(orchardDataFinal, (orchard) => {
        // console.log(orchard, "----orchard en map")
        return {
          label: orchard.label,
          value: orchard.value,
          id: orchard.value,
        };
      });
      setOrchardDataState(alphabeticalSort(orchardData, "label"));
    } else {
      // console.log('no hay cliente');
      const orchardDataFinal = alterArrayGetFields(orchardFullDataState, [
        "label",
        "value",
      ]);
      const orchardData = map(orchardDataFinal, (orchard) => {
        // console.log(orchard, "----orchard en map")
        return {
          label: orchard.label,
          value: orchard.value,
          id: orchard.value,
        };
      });
      // setOrchardDataState(alphabeticalSort(orchardData, 'label'));
      setOrchardDataState([]);
    }
  }, [formClientState]);

  const onCloseSnack = () => {
    setSuccessStateState(false);
    setSuccessDeleteState(false);
  };

  return (
    <div className="form-style">
      <BsContainer fluid class="px-0 px-md-3">
        <BsRow class="no-gutters">
          <BsCol class="col-md-12">
            {editionState && (
              <EditionAreaWidget
                component={
                  <PuEdit
                    rowEdit={rowToEditState}
                    closeFunc={closeEditionAreaHandler}
                    editFunc={editActionHandler}
                  />
                }
                visible={editionState}
              />
            )}
            <Card elevation={0}>
              <CardHeaderWidget
                title={viewsTexts.titles.productiveUnit.list.title}
                subheader={<BreadCrumbs subTitle="" />}
              />
              <CardContent>
                {/* <GenericToolbar search={searchTool} /> */}
                <div
                  style={{
                    minHeight: "300px",
                  }}
                >
                  <DataTableWidget
                    totalRows={countState}
                    handleOnSort={handleOnSort}
                    fixedHeader={true}
                    fixedHeaderScrollHeight={"590px"}
                    // conditionalRowStyles={conditionalRowStyles}
                    // sortFunction={customSort}
                    changePagination={handleChangePagination} //onChangePage
                    rowsChange={handleChangeRows} //onChangeRowsPerPage
                    columns={columns}
                    sortField="1"
                    filteredItems={tableDataState}
                    subHeaderComponentMemo={subHeaderComponentMemo}
                    loading={loadingDataTableState}
                    loadingCount={loadingDataTableState}
                    customStyles={customStyles}
                    moduleName="Usuarios"
                    resetPaginationToggle={resetPaginationToggleState}
                  />
                </div>
                <DialogState
                  openDialog={openDialogStateState}
                  rowState={rowToHandleState}
                  actionState={stateActionDialogHandler}
                  moduleName="productiveUnit"
                  variant="success"
                  loading={loadingStateState}
                  success={successStateState ? successStateState : undefined}
                  openState={setOpenDialogStateState}
                  actionClose={onCloseSnack}
                />
                {/* <DialogDelete
									openDialog={openDialogDeleteState}
									rowDelete={rowToHandleState}
									actionDelete={deleteActionDialogHandler}
									variant="success"
									loading={loadingDeleteState}
									success={
										successDeleteState
											? successDeleteState
											: undefined
									}
									openState={setOpenDialogDeleteState}
								/> */}
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default PuList;
