import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Divider,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import SyncIcon from "@mui/icons-material/Sync";
import SnackBarsComponent from "../SnackBarsComponent";
import CircularProgress from "@mui/material/CircularProgress";

import { IsDesktopHandler } from "../../helpers";
import dialogMessages from "../../common/dialogMessages.json";
import classes from "./DialogState.module.scss";

// const years = Array.from(
// 	{ length: 11 },
// 	(_, index) => new Date().getFullYear() - index
// );
// const yearsUnites = [2020, 2021, 2022, 2023, 2024];

const years = Array.from(
  { length: 11 },
  (_, index) => new Date().getFullYear() - index
);

const DialogState = (props) => {
  const {
    openDialog,
    rowState,
    actionState,
    success,
    loading,
    openState,
    moduleName,
    actionClose,
  } = props;
  const [valueState, setValueState] = useState(false);
  const [disableState, setDisableState] = useState(loading);
  const [successState, setSuccessState] = useState(false);

  const [unitListYears, setUnitListYears] = useState([]);

  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedNewYears, setSelectedNewYears] = useState([]);

  const isDesktop = IsDesktopHandler("md");
  useEffect(() => {
    if (openDialog) {
      setValueState(true);
      setDisableState(false);
    }
  }, [openDialog]);

  useEffect(() => {
    setDisableState(loading);
  }, [loading]);

  useEffect(() => {
    if (success !== undefined) {
      setSuccessState(true);
    }
    // console.log(success, '--------success');
  }, [success]);

  const handleClick = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    } else {
      setValueState(false);
      openState(false);
    }
  };

  const handleClickConfirm = async () => {
    if (moduleName === "productiveUnit") {
      const resultActives = unitListYears.map((year) => ({
        season: year,
        statusUnitActive: selectedYears.includes(year),
      }));

      console.log(selectedNewYears, "selectedNewYears");

      await actionState(resultActives, selectedNewYears);
    } else {
      await actionState();
    }

    openState(false);
    setValueState(false); //cierra el dialo
    setSelectedNewYears([]);
  };

  // const handleCloseSnackBarsComponent = () => {
  // 	setSuccessState(false);
  // };

  const handleCloseSnackBarsComponent = () => {
    setSuccessState(false);
    const closeAction = actionClose || (() => {});
    closeAction();
  };

  // console.log(rowState, 'rowState');

  const handleYearChange = (year) => (event) => {
    if (event.target.checked) {
      setSelectedYears([...selectedYears, year]);
    } else {
      setSelectedYears(selectedYears.filter((y) => y !== year));
    }
  };

  const handleNewYearChange = (year) => (event) => {
    if (event.target.checked) {
      setSelectedNewYears([...selectedNewYears, year]);
    } else {
      setSelectedNewYears(selectedNewYears.filter((y) => y !== year));
    }
  };

  const textPrompt = useRef("");

  useEffect(() => {
    if (rowState && rowState.seasons && moduleName === "productiveUnit") {
      console.log(rowState.seasons, "firstYear");

      const yearsForChecks = rowState.seasons.map((item) => item.season);

      // order yearsForChecks de mayor a menor
      yearsForChecks.sort((a, b) => b - a);

      setUnitListYears(yearsForChecks);

      const activeYears = rowState.seasons
        .filter((item) => item.statusUnitActive)
        .map((item) => item.season);

      setSelectedYears(activeYears);
    }

    switch (moduleName) {
      case "Usuarios":
        textPrompt.current = `${rowState?.name} ${rowState?.lastname} (${rowState?.username})`;
        break;

      case "productiveUnit":
        textPrompt.current = `${rowState?.idUnitProductive}`;
        break;

      default:
        textPrompt.current = rowState?.id;
        break;
    }
  }, [rowState, moduleName]);

  console.log(rowState, "rowState", unitListYears);

  return (
    <React.Fragment>
      <Dialog
        fullScreen={!isDesktop}
        open={valueState}
        classes={{
          paper: classes.paperStyle,
        }}
        onClose={handleClick}
        // disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="dialog-title">
          {moduleName !== "productiveUnit"
            ? dialogMessages.message.state.title
            : "Cambiar Temporadas"}
        </DialogTitle>
        <DialogContent>
          {moduleName === "productiveUnit" ? (
            <div>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Indique las temporadas que desea mantener activas
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginLeft: 10,
                  }}
                >
                  {unitListYears &&
                    unitListYears.map((year) => {
                      return (
                        <FormControlLabel
                          key={year}
                          control={
                            <Checkbox
                              checked={selectedYears.includes(year)}
                              onChange={handleYearChange(year)}
                              color="primary"
                            />
                          }
                          label={year.toString()}
                        />
                      );
                    })}

                  <Divider />
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                      marginTop: 15,
                    }}
                  >
                    Agregue aquí nuevas temporadas a la Unidad
                  </Typography>

                  {years.map((year) => {
                    let stringYear = year.toString();
                    return (
                      <FormControlLabel
                        key={stringYear}
                        control={
                          <Checkbox
                            checked={selectedNewYears.includes(stringYear)}
                            onChange={handleNewYearChange(stringYear)}
                            color="primary"
                            disabled={unitListYears.includes(stringYear)}
                          />
                        }
                        label={stringYear}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}

          <DialogContentText component="div" className={classes.description}>
            <p className="mb-0">{dialogMessages.message.state.subtitle}</p>
            <p style={{ fontWeight: "bold" }}>{textPrompt.current}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClick} disabled={disableState}>
            Cancelar
          </Button>
          <div className={classes.wrapper}>
            <Button
              onClick={() =>
                moduleName === "productiveUnit"
                  ? handleClickConfirm(selectedYears)
                  : handleClickConfirm()
              }
              variant="contained"
              color="warning"
              autoFocus
              disabled={
                moduleName === "productiveUnit" ? disableState : disableState
              }
              startIcon={<SyncIcon />}
            >
              Cambiar
            </Button>
            {disableState && (
              <CircularProgress
                style={{ color: "white" }}
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={successState}
        onClose={handleCloseSnackBarsComponent}
        autoHideDuration={4500}
        key={Math.random()}
      >
        <div>
          <SnackBarsComponent
            variant={props.variant}
            message={dialogMessages.message.state.confirmation}
            handleClose={handleCloseSnackBarsComponent}
          />
        </div>
      </Snackbar>
    </React.Fragment>
  );
};

export default DialogState;
