import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
// import { amber, green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent';
import WarningIcon from '@mui/icons-material/Warning';
import classes from './SnackBarsComponent.module.scss';
// import { makeStyles } from '@mui/material/styles';

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
};

function MySnackbarContentWrapper(props) {
	const { className, message, onClose, variant, ...other } = props;
	const Icon = variantIcon[variant];

	const actionButton = (
		<IconButton
			key="close"
			aria-label="close"
			color="inherit"
			onClick={onClose}
		>
			<CloseIcon className={classes.icon} />
		</IconButton>
	);

	return (
		<SnackbarContent
			className={clsx(classes[variant], className)}
			aria-describedby="client-snackbar"
			message={
				<span id="client-snackbar" className={classes.message}>
					<Icon className={clsx(classes.icon, classes.iconVariant)} />
					{message}
				</span>
			}
			action={actionButton}
			{...other}
		/>
	);
}

MySnackbarContentWrapper.propTypes = {
	className: PropTypes.string,
	message: PropTypes.string,
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(['error', 'info', 'success', 'warning'])
		.isRequired,
};

export default function Snackbars(props) {
	return (
		<MySnackbarContentWrapper
			variant={props.variant}
			className={classes.margin}
			message={props.message}
			onClose={props.handleClose}
		/>
	);
}
