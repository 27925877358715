import React from 'react';
import ReactDOM from 'react-dom/client';

import { LicenseManager } from '@ag-grid-enterprise/core';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';

import * as serviceWorker from './serviceWorker';
import App from './App';

LicenseManager.setLicenseKey(
	'Using_this_{AG_Grid}_Enterprise_key_{AG-064287}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Cer_Analytics_SpA}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{CER_Analytics}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{CER_Analytics}_need_to_be_licensed___{CER_Analytics}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{29_July_2025}____[v3]_[01]_MTc1Mzc0MzYwMDAwMA==16f94ce0ce8b0a90ac61a7e411a0eb60'
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

serviceWorker.unregister();
