import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import CircleIcon from '@mui/icons-material/Circle';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Context } from '../../../../context/Context';
import { BsCol, BsContainer, BsRow } from 'layouts/components';
import { IsDesktopHandler } from 'helpers';

const useStyles = makeStyles((theme) => ({
	boxStyle: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		borderRadius:
			theme.shape.borderRadius +
			'px' +
			theme.shape.borderRadius +
			'px' +
			'0px' +
			'0px',
		'&:hover': {
			backgroundColor: theme.palette.grey[50],
			cursor: 'pointer',
		},
		'& h5': {
			color: theme.palette.grey[500],
			fontWeight: 400,
		},
		'&.active': {
			borderBottom: '2px solid' + theme.palette.secondary.main,
			'& h5': {
				color: theme.palette.secondary.main,
				fontWeight: 700,
			},
		},
	},
}));

const WrapperButtons = (props) => {
	const { moduleId, children } = props;

	const { moduleUser } = useContext(Context);

	const isDesktop = IsDesktopHandler('md');

	let history = useHistory();

	const classes = useStyles();

	// console.log(moduleUser, 'moduleUser', moduleId);

	// TODO: filtra con el ID recibido en moduleUser
	// si en process tiene más de un proceso con status true, debe armar submenú
	// arma tipo tabs con botones Link por cada proceso
	const moduleObj = _.find(moduleUser, { id: moduleId });

	// console.log(moduleObj, 'moduleObj');

	const handleButtonClick = (process) => {
		// console.log(process, '<----------CLICK EN BOTON');
		if (process.href) {
			history.push({
				pathname: process.href,
			});
		}
	};

	const handleActiveRoute = (path) => {
		// const isCurrentPath = useRouteMatch(`/${path}`);
		const isCurrentPath = null;
		// console.log(isCurrentPath, '<<<<<<-----------isCurrentPath', path);
		return isCurrentPath?.isExact || false;
	};

	return (
		<>
			{moduleObj?.subMenu ? (
				<BsContainer fluid>
					<BsRow class="mt-3">
						<BsCol class="col-md-12">
							<Grid
								container
								spacing={2}
								direction="row"
								justifyContent="flex-start"
								alignItems="center"
							>
								{moduleObj.process.length &&
									moduleObj.process
										.filter((process) => process.assigned)
										.map((process) => {
											return (
												<Grid item key={process.id}>
													<Box
														className={clsx(
															classes.boxStyle,
															handleActiveRoute(
																process.href
															) && 'active'
														)}
														onClick={() =>
															handleButtonClick(
																process
															)
														}
													>
														<Typography variant="h5">
															{handleActiveRoute(
																process.href
															) ? (
																<CircleIcon
																	sx={{
																		color: 'secondary.main',
																		fontSize: 12,
																		marginRight: 1,
																	}}
																/>
															) : null}{' '}
															{process.name}
														</Typography>
													</Box>
												</Grid>
											);
										})}
							</Grid>
						</BsCol>
					</BsRow>
				</BsContainer>
			) : null}
			{children}
		</>
	);
};

export default WrapperButtons;
