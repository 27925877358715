import React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import classes from './SearchBasicTable.module.scss';

const SearchBasicTable = ({
  onFilter,
  filterFunc,
  placeholder,
  valuetext,
  resetFunc
}) => (
  <Paper component="form" className={classes.root}>
    <InputBase
      id="search"
      type="text"
      role="search"
      value={valuetext}
      placeholder={placeholder}
      onChange={e => onFilter(e.target.value)}
      inputProps={{ 'aria-label': 'Buscar' }}
      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    />
    <IconButton
      onClick={resetFunc}
      className={classes.iconButton}
      color="default"
      aria-label="reset">
      <ClearIcon />
    </IconButton>
    <Divider className={classes.divider} orientation="vertical" />
    <IconButton
      onClick={filterFunc}
      className={classes.iconButton}
      color="primary"
      aria-label="buscar">
      <SearchIcon />
    </IconButton>
  </Paper>
);
export default SearchBasicTable;
