import React, { useState, useEffect, useRef } from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
	useTheme,
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import DeleteIcon from '@mui/icons-material/Delete';
import SnackBarsComponent from '../SnackBarsComponent';
import CircularProgress from '@mui/material/CircularProgress';

import { IsDesktopHandler } from '../../helpers';
import dialogMessages from '../../common/dialogMessages.json';
import classes from './DialogDelete.module.scss';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	paperStyle: {
		borderRadius: theme.shape.borderRadius,
		minWidth: 400,
	},
	// buttonDelete: {
	// 	backgroundColor: theme.palette.error.dark,
	// },
}));

const DialogDelete = (props) => {
	const {
		openDialog,
		rowDelete,
		actionDelete,
		loading,
		success,
		openState,
		moduleName,
		actionClose,
	} = props;
	const [valueState, setValueState] = useState(false);
	const [disableState, setDisableState] = useState(loading);
	const [successState, setSuccessState] = useState(success);

	const theme = useTheme();

	const textPrompt = useRef('');

	// console.log(theme)

	const classesUI = useStyles();

	const isDesktop = IsDesktopHandler('md');
	useEffect(() => {
		if (openDialog) {
			setValueState(true);
			setDisableState(false);
		}
	}, [openDialog]);

	useEffect(() => {
		setDisableState(loading);
	}, [loading]);

	useEffect(() => {
		if (success !== undefined) {
			setSuccessState(true);
		}
	}, [success]);

	const handleClick = (event, reason) => {
		if (reason === 'backdropClick') {
			return false;
		} else {
			setValueState(false);
			openState(false);
		}
	};

	const handleClickConfirm = async () => {
		await actionDelete();
		openState(false);
		setValueState(false);
	};

	// const handleCloseSnackBarsComponent = () => {
	// 	setSuccessState(false);
	// };

	const handleCloseSnackBarsComponent = () => {
		setSuccessState(false);
		const closeAction = actionClose || (() => {});
		closeAction();
	};

	useEffect(() => {
		switch (moduleName) {
			case 'Usuarios':
				textPrompt.current = `${rowDelete?.name} ${rowDelete?.lastname} (${rowDelete?.username})`;
				break;

			case 'Clientes':
				textPrompt.current = `${rowDelete?.label}`;
				break;

			case 'Agrícolas':
				textPrompt.current = `${rowDelete?.label} (${rowDelete?.client})`;
				break;

			case 'Huerto':
				textPrompt.current = `${rowDelete?.label} (${rowDelete?.client})`;
				break;

			default:
				textPrompt.current = rowDelete?.id;
				break;
		}
	}, [rowDelete, moduleName]);

	console.log(rowDelete, 'en el dialog');

	return (
		<React.Fragment>
			<Dialog
				fullScreen={!isDesktop}
				open={valueState}
				classes={{
					paper: classesUI.paperStyle,
				}}
				onClose={handleClick}
				// disableBackdropClick
				disableEscapeKeyDown
				aria-labelledby="dialog-title"
			>
				<DialogTitle id="dialog-title" className={classes.title}>
					{dialogMessages.message.delete.title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText
						component="div"
						className={classes.description}
					>
						<p className="mb-0">
							{dialogMessages.message.delete.subtitle}
						</p>
						<p style={{ fontWeight: 'bold' }}>
							{textPrompt.current}
						</p>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						autoFocus
						onClick={handleClick}
						disabled={disableState}
					>
						Cancelar
					</Button>
					<div className={classes.wrapper}>
						<Button
							onClick={handleClickConfirm}
							variant="contained"
							color="error"
							className={classesUI.buttonDelete}
							autoFocus
							disabled={disableState}
							startIcon={<DeleteIcon />}
						>
							Eliminar
						</Button>
						{disableState && (
							<CircularProgress
								style={{ color: 'white' }}
								size={24}
								className={classes.buttonProgress}
							/>
						)}
					</div>
				</DialogActions>
			</Dialog>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={successState}
				onClose={handleCloseSnackBarsComponent}
				autoHideDuration={4500}
				key={Math.random()}
			>
				<div>
					<SnackBarsComponent
						variant={props.variant}
						message={dialogMessages.message.delete.confirmation}
						handleClose={handleCloseSnackBarsComponent}
					/>
				</div>
			</Snackbar>
		</React.Fragment>
	);
};

export default DialogDelete;
