import React, { useState } from 'react';
import Alert from '@mui/lab/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
// import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import classes from './Alert.module.scss';
import alertMessages from '../../common/alertMessages.json';

const AlertMessages = props => {
  const [openState, setOpenState] = useState(true);
  const { severity, alertTitle, closeFunc } = props;

  console.log(closeFunc, "----closeFunc")

  const actionClose = closeFunc ? <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenState(false);
                  closeFunc();
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton> :  null;

  return (
    <React.Fragment>
      <Collapse in={openState} style={{ marginBottom: '20px' }}>
        <Alert
          severity={severity}
          variant="filled"
          classes={{
            message: classes.messageStyle
          }}
          action={actionClose}>
          {alertMessages.message[alertTitle].title}
          <p>{alertMessages.message[alertTitle].subtitle}</p>
        </Alert>
      </Collapse>
    </React.Fragment>
  );
};

export default AlertMessages;
