import { Avatar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import AVATAR from '../../assets/avatar_mapa.jpg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classesModule from './MapCarouselButton.module.scss';

const useStyles = makeStyles((theme) => ({
	buttonWrapper: {
		// width: '210px',
		height: '100%',
		backgroundColor: theme.palette.mapbutton.main,
		// backgroundColor: 'tomato',
		display: 'flex',
		paddingLeft: 8,
		paddingRight: 8,
		alignItems: 'center',
		justifyContent: 'flex-start',
		position: 'relative',
		cursor: 'pointer',
		'&&.active': {
			boxShadow: 'inset 7px 7px 44px -26px rgba(0,0,0,0.46)',
		},
	},
	nameArea: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		// flexGrow: 1,
		height: '100%',
		// minWidth: '200px',
	},
	name: {
		fontWeight: 500,
	},
	avatar: {
		width: theme.spacing(7),
		height: theme.spacing(7),
		marginRight: 10,
	},
	buttonBackdrop: {
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: 995,
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		opacity: 0,
		'&&.active': {
			opacity: 1,
		},
	},
}));

const MapCarouselButton = (props) => {
	const {
		handleClick,
		selectedId,
		idOrchard,
		name,
		icon,
		handleClose,
	} = props;
	const classes = useStyles();

	// console.log(selectedId, '<---selectedId--', idOrchard);

	return (
		<div
			className={clsx(
				classes.buttonWrapper,
				selectedId === idOrchard && selectedId && 'active'
			)}
		>
			{/* {selectedId && (
				<div
					className={clsx(
						classes.buttonBackdrop,
						selectedId === idOrchard && selectedId && 'active'
					)}
				></div>
			)} */}
			<Avatar
				alt={name}
				src={icon}
				className={classes.avatar}
				onClick={() => handleClick(1)}
			></Avatar>
			<div className={classes.nameArea} onClick={() => handleClick(2)}>
				<Typography variant="subtitle1" className={classes.name}>
					{name}
				</Typography>
				{/* <KeyboardArrowDownIcon /> */}
			</div>
		</div>
	);
};

export default MapCarouselButton;
