import React, { useState, useContext } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
// import CircularProgress from '@mui/material/CircularProgress';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { Typography, Link, InputLabel } from '@mui/material';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LogoSign from '../../assets/Logologo.png';
import { SaveAction, TextFieldWidgetTextBig } from '../../components';
import classes from './RecoveryPassword.module.scss';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import Snackbar from '@mui/material/Snackbar';
import SnackBarsComponent from '../../components/SnackBarsComponent';
import snackBarsMessages from '../../common/snackBarsMessages.json';
import viewsTexts from '../../common/viewsTexts.json';
import generalTexts from '../../common/generalTexts.json';
import { AxiosContext } from '../../context/AxiosContext';

const RecoveryPassword = (props) => {
	const { history } = props;
	const [successState, setSuccessState] = useState(false);

	const [loadingState, setLoadingState] = useState(false);

	const axiosContext = useContext(AxiosContext);

	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = useForm();
	const snackBarMessage = snackBarsMessages.message['recoverPassword'];

	const handleCloseSnackBarsComponent = () => {
		setSuccessState(false);
		handleBack();
	};

	const handleBack = () => {
		history.goBack();
	};

	const onSubmit = async (event) => {
		const { email } = event;
		setLoadingState(true);
		const objQuery = {
			email: email,
		};

		try {
			const response = await axiosContext.publicAxios.post(
				'v1/user/recover-password-user',
				objQuery
			);

			//   console.log(response, '------response');
			const { status, data } = response;
			if (status === 200) {
				setValue('email', '');
				setSuccessState(true);
				setLoadingState(false);
			}
		} catch (error) {
			console.log(error, 'error');
			setLoadingState(false);
		}
	};

	return (
		<div className={classes.root}>
			<BsContainer
				fluid
				class="h-100 px-0 px-md-0"
				style={{ height: '100%' }}
			>
				<BsRow class="h-100 justify-content-center align-items-center justify-content-md-end no-gutters">
					<BsCol class={['col-xl-5 col-lg-6'].join(' ')}>
						<div className={classes.content}>
							<div className={classes.contentBody}>
								<img
									src={LogoSign}
									alt="analytics"
									className={clsx(
										'mb-4 mb-md-3',
										classes.imgPhone
									)}
								/>
								<form
									className={classes.form}
									onSubmit={handleSubmit(onSubmit)}
								>
									<Typography
										className={classes.title}
										variant="h3"
										gutterBottom
									>
										{
											viewsTexts.titles.recoveryPassword
												.title
										}
									</Typography>
									<Typography
										align="left"
										className={classes.sugestion}
										// color="textSecondary"
										variant="h5"
									>
										{
											viewsTexts.titles.recoveryPassword
												.sugestion
										}
									</Typography>
									<div className="mt-5 mt-md-5">
										<InputLabel
											error={Boolean(errors.email)}
										>
											Su email
										</InputLabel>
										<TextFieldWidgetTextBig
											name="email"
											placeholder="email"
											// label={viewsTexts.forms.email}
											label=""
											req
											margin="dense"
											defaultValue=""
											control={control}
											errors={errors.email}
											// autoComplete="email"
										/>
										<div className={classes.wrapper}>
											<SaveAction
												title="Recuperar"
												color="secondary"
												size="large"
												className="bigButton"
												fullWidth={true}
												variant="contained"
												messageType="add"
												mutationLoading={loadingState}
												success={false}
											/>
										</div>
									</div>

									<div className="text-right">
										<Typography variant="body1">
											{generalTexts.textLinkSignin}{' '}
											<Link
												component={RouterLink}
												to="/sign-in"
												variant="h6"
												color="secondary"
											>
												{generalTexts.linkSignin}
											</Link>
										</Typography>
									</div>
								</form>
							</div>
						</div>
					</BsCol>
				</BsRow>
			</BsContainer>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={successState}
				autoHideDuration={6000}
			>
				<SnackBarsComponent
					variant="success"
					message={snackBarMessage}
					handleClose={handleCloseSnackBarsComponent}
				/>
			</Snackbar>
		</div>
	);
};

export default withRouter(RecoveryPassword);
