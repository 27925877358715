import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
	SaveAction,
	CardHeaderWidget,
	TextFieldWidgetText,
	TextFieldWidgetSelectControllerHorizontal,
} from '../../../components';
import { Card, CardContent, Divider } from '@mui/material';
import { BsRow, BsCol } from '../../../layouts/components';
import { CloseButtonEditionArea } from '../../../components/EditionAreaWidget';
import { alterArrayGetFields, alphabeticalSort } from '../../../helpers';
import '../../../assets/scss/form.scss';
import viewsTexts from '../../../common/viewsTexts.json';
import { AxiosContext } from '../../../context/AxiosContext';
import { Region } from '../LocationData/api.js';

const OmEdit = (props) => {
	const {
		location: { lat, lng },
	} = props.rowEdit;

	console.log(props.rowEdit, ' <----llega row a edit');
	const {
		id,
		client,
		clientValue,
		label,
		comuna,
		comunaValue,
		provincia,
		provinciaValue,
		agroBusinessFilter,
		region,
		regionValue,
		icon,
		edit,
	} = props.rowEdit;

	const { editFunc, closeFunc } = props;
	const [clientDataState, setClientDataState] = useState([]);
	const [orchardDataState, setOrchardDataState] = useState([]);
	const [agroBusinessDataState, setAgroBusinessDataState] = useState([]);
	const [comunaDataState, setComunaDataState] = useState([]);
	const [provinciaDataState, setProvinciaDataState] = useState([]);
	const [regionDataState, setRegionDataState] = useState(
		alphabeticalSort(Region, 'label')
	);

	const [loadingDataState, setLoadingDataState] = useState(false);
	const axiosContext = useContext(AxiosContext);

	const [loadingQueryState, setLoadingQueryState] = useState(false);
	const [successQueryState, setSuccessQueryState] = useState(false);
	const [selectedClient, setSelectedClient] = useState('');

	const {
		handleSubmit,
		control,
		formState: { errors },
		register,
		getValues,
		reset,
		watch,
		setValue,
	} = useForm();

	const watchClient = watch('clientValue');
	const watchRegion = watch('region');
	const watchProvincia = watch('provincia');

	useEffect(() => {
		// console.log(agroBusinessFilter, '----agroBusinessFilter');
		if (clientDataState && clientDataState.length) {
			reset({
				id: id,
				client: client,
				clientValue: clientValue,
				label: label,
				agroBusiness:
					agroBusinessFilter && agroBusinessFilter.length
						? agroBusinessFilter.map((agro) => agro.value)
						: [],
				comuna: comunaValue || '',
				provincia: provinciaValue || '',
				region: regionValue || '',
				icon: icon,
				lat: lat,
				lng: lng,
			});

			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		}
	}, [
		id,
		client,
		clientValue,
		agroBusinessFilter,
		label,
		comuna,
		comunaValue,
		provincia,
		provinciaValue,
		region,
		regionValue,
		icon,
		lat,
		lng,
		reset,
		clientDataState,
	]);

	useEffect(() => {
		if (watchClient) {
			const resultClient = clientDataState.filter(
				(ele) => ele.value === watchClient
			);
			setAgroBusinessDataState(resultClient[0]?.agrobusiness);
			setSelectedClient(resultClient[0]?.value);
		}
	}, [watchClient, clientDataState]);

	useEffect(() => {
		if (watchRegion) {
			const resultRegion = regionDataState.find(
				(ele) => ele.value === watchRegion
			);
			setProvinciaDataState(
				alphabeticalSort(resultRegion?.provincias, 'label')
			);
		}
	}, [watchRegion, regionDataState]);

	useEffect(() => {
		if (watchRegion || watchProvincia) {
			if (provinciaDataState) {
				const resultComuna = provinciaDataState.find(
					(ele) => ele.value === watchProvincia
				);
				setComunaDataState(
					alphabeticalSort(resultComuna?.comunas, 'label')
				);
			}
		}
	}, [watchProvincia, provinciaDataState]);

	const onSubmit = async (values) => {
		setLoadingQueryState(true);

		/*const clientSelected = clientDataState.filter(
			(ele) => ele.value === values.client
		);*/

		let clientObj = clientDataState.filter(
			(ele) => ele.value === values.client
		);

		clientObj = alterArrayGetFields(clientObj, ['id', 'label', 'value']);

		const agroBusinessArray = values.agroBusiness.map((agro) => {
			const dataAgro = agroBusinessDataState.filter(
				(ele) => ele.value === agro
			);
			return {
				label: dataAgro[0]?.label,
				value: dataAgro[0]?.value,
			};
		});

		const selectedComuna = comunaDataState.find(
			(comuna) => comuna.value === values.comuna
		);
		const comunaLabel = selectedComuna ? selectedComuna.label : '';

		const selectedProvincia = provinciaDataState.find(
			(provincia) => provincia.value === values.provincia
		);
		const provinciaLabel = selectedProvincia ? selectedProvincia.label : ''; // Obtiene el label de la comuna seleccionada o una cadena vacía si no se encuentra

		const selectedRegion = regionDataState.find(
			(region) => region.value === values.region
		);
		const regionLabel = selectedRegion ? selectedRegion.label : ''; // Obtiene el label de la comuna seleccionada o una cadena vacía si no se encuentra

		const query = {
			// Asegúrate de incluir el objeto Input con los datos necesarios
			id: values.id,
			clientValue: values.client,
			agroBusiness: agroBusinessArray,
			//agroBusiness: [{
			// label: values.agroBusiness,
			//value: agroBusinessValue,
			//}],
			label: values.label,
			comunaValue: values.comuna,
			provinciaValue: values.provincia,
			regionValue: values.region,
			comuna: comunaLabel,
			provincia: provinciaLabel,
			region: regionLabel,
			icon: values.icon,
			lat: parseFloat(values.lat),
			lng: parseFloat(values.lng),
		};

		try {
			const response = await axiosContext.authAxios.put(
				'v1/orchard/update-orchard-platform',
				query
			);

			console.log(response, '<---respuesta de query edit user');

			if (response.status === 200) {
				// alert('listo');
				editFunc(response.data);
				setSuccessQueryState(true);
			}
		} catch (err) {
		} finally {
			setLoadingQueryState(false);
		}
	};

	const getClientsAsync = useCallback(async () => {
		setLoadingDataState(true);
		try {
			let response = await axiosContext.authAxios.post(
				'v1/client/list-clients',
				{
					limit: 500,
				}
			);

			// console.log(response, '<<----respo clientes');

			const { data, status } = response;

			if (status === 200) {
				setClientDataState(alphabeticalSort(data.data, 'label'));
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoadingDataState(false);
		}
	}, [axiosContext, setLoadingDataState, setClientDataState]);

	const getAgroBusinessAsync = async () => {
		setLoadingDataState(true);

		try {
			let objQuery = {
				page: 1,
				limit: 500,
				clientValue: watchClient,
			};
			let response = await axiosContext.authAxios.post(
				'v1/agrobusiness/list',
				objQuery
			);

			const { data, status } = response;

			if (status === 200) {
				setAgroBusinessDataState(alphabeticalSort(data.data, 'label'));
				setLoadingDataState(false);
			}
		} catch (error) {
			console.log(error);
			setLoadingDataState(false);
			// setLoadingVarietyFilterState(false);
		}
	};

	useEffect(() => {
		getClientsAsync();
	}, []);

	useEffect(() => {
		if (watchClient) {
			getAgroBusinessAsync();
		}
	}, [watchClient]);

	const onCloseSnack = () => {
		setSuccessQueryState(false);
	};

	return (
		<Card elevation={0}>
			<CardHeaderWidget
				title={
					viewsTexts.titles.orchard.orchardTextEdit +
					' ' +
					client +
					' ' +
					label
				}
			/>
			<CloseButtonEditionArea closeFunc={props.closeFunc} />
			<Divider />
			<CardContent>
				<BsRow>
					<BsCol class="col-md-12">
						<form
							autoComplete="on"
							onSubmit={handleSubmit(onSubmit)}
						>
							<BsRow>
								<BsCol class="col-md-6">
									<TextFieldWidgetSelectControllerHorizontal
										options={clientDataState}
										titleOption="label"
										control={control}
										name="clientValue"
										labeltext="Cliente"
										errors={errors.client}
										defaultValue=""
										loading={loadingDataState}
										req
										disabled
										withDefault
										variant="outlined"
									/>
								</BsCol>
								<BsCol class="col-md-6">
									<TextFieldWidgetSelectControllerHorizontal
										options={agroBusinessDataState}
										titleOption="label"
										control={control}
										name="agroBusiness"
										labeltext="Agrícola"
										errors={errors.agroBusiness}
										defaultValue={[]}
										loading={loadingDataState}
										req={false}
										multiple={true}
										withDefault
										variant="outlined"
										disabled={!edit}
									/>
								</BsCol>
							</BsRow>
							<BsRow>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="label"
										//labeltext={viewsTexts.forms.orchard}
										labeltext="Huerto"
										req={false}
										control={control}
										errors={errors.label}
										defaultValue=""
										disabled={!edit}
									/>
								</BsCol>
								<BsCol class="col-md-6">
									<TextFieldWidgetSelectControllerHorizontal
										options={regionDataState}
										titleOption="label"
										control={control}
										name="region"
										labeltext="Region"
										errors={errors.region}
										defaultValue=""
										loading={false}
										req={false}
										disabled={false}
										withDefault
										variant="outlined"
										///////////////////////////////////
									/>
								</BsCol>
							</BsRow>
							<BsRow>
								<BsCol class="col-md-6">
									<TextFieldWidgetSelectControllerHorizontal
										options={provinciaDataState}
										titleOption="label"
										control={control}
										name="provincia"
										labeltext="Provincia"
										errors={errors.provincia}
										defaultValue=""
										loading={false}
										req={false}
										withDefault
										variant="outlined"
										disabled={false}
										///////////////////////////////////
									/>
								</BsCol>
								<BsCol class="col-md-6">
									<TextFieldWidgetSelectControllerHorizontal
										options={comunaDataState}
										titleOption="label"
										control={control}
										name="comuna"
										labeltext="Comuna"
										errors={errors.comuna}
										defaultValue=""
										disabled={false}
										loading={false}
										req={false}
										withDefault
										variant="outlined"
										///////////////////////////////////
									/>
								</BsCol>
							</BsRow>
							<BsRow>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="icon"
										//labeltext={viewsTexts.forms.orchard}
										labeltext="Icono"
										req={false}
										control={control}
										errors={errors.icon}
										defaultValue=""
										disabled={false}
									/>
								</BsCol>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="lat"
										//labeltext={viewsTexts.forms.orchard}
										labeltext="Latitud"
										req={true}
										control={control}
										errors={errors.lat}
										defaultValue=""
										disabled={false}
									/>
								</BsCol>
							</BsRow>
							<BsRow>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="lng"
										//labeltext={viewsTexts.forms.orchard}
										labeltext="Longitud"
										req={true}
										control={control}
										errors={errors.lng}
										defaultValue=""
										disabled={false}
									/>
								</BsCol>
							</BsRow>
							<BsRow class="mt-5">
								<BsCol class="col-md-12 text-center">
									<SaveAction
										title="Guardar"
										color="secondary"
										size="large"
										className="roundedButton ml-md-2"
										variant="contained"
										disabled={loadingQueryState}
										messageType="edit"
										actionClose={() => {
											closeFunc();
											onCloseSnack();
										}}
										mutationLoading={loadingQueryState}
										success={successQueryState}
									/>
								</BsCol>
							</BsRow>
						</form>
					</BsCol>
				</BsRow>
			</CardContent>
		</Card>
	);
};

export default OmEdit;
