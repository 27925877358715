import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
	SaveAction,
	CardHeaderWidget,
	TextFieldWidgetText,
	TextFieldWidgetSelectControllerHorizontal,
} from '../../../components';
import {
	Card,
	CardContent,
	Divider,
	IconButton,
	InputAdornment,
	TextField,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { BsRow, BsCol } from '../../../layouts/components';
import { CloseButtonEditionArea } from '../../../components/EditionAreaWidget';
import { alterArrayGetFields, alphabeticalSort } from '../../../helpers';
import '../../../assets/scss/form.scss';
import viewsTexts from '../../../common/viewsTexts.json';
import { AxiosContext } from '../../../context/AxiosContext';

const UserEdit = (props) => {
	const {
		id,
		email,
		name,
		lastname,
		phone,
		username,
		client,
		agrobusiness,
		profile,
	} = props.rowEdit;

	console.log(props.rowEdit, '<<---------props.rowEdit');

	const { editFunc, closeFunc } = props;
	const [profileDataState, setProfileDataState] = useState([]);
	const [clientDataState, setClientDataState] = useState([]);
	const [agroBusinessDataState, setAgroBusinessDataState] = useState([]);
	const [loadingDataState, setLoadingDataState] = useState(false);

	const axiosContext = useContext(AxiosContext);

	const [showPassword, setShowPassword] = useState(false);

	const [loadingQueryState, setLoadingQueryState] = useState(false);
	const [successQueryState, setSuccessQueryState] = useState(false);

	const [hideClientDataState, setHideClientDataState] = useState(false);

	const {
		handleSubmit,
		control,
		formState: { errors },
		register,
		getValues,
		reset,
		watch,
		setValue,
	} = useForm();

	// console.log(props.rowEdit, ' llega row a edit');
	const watchClient = watch('client');
	const watchProfile = watch('profile');

	useEffect(() => {
		// console.log(profile, '----profile');
		if (profile && profile.value === 'Administrador_Panel') {
			setHideClientDataState(true);
		} else {
			setHideClientDataState(false);
		}

		if (
			profileDataState &&
			profileDataState.length &&
			clientDataState &&
			clientDataState.length
		) {
			reset({
				id: id,
				profile: profile?.value,
				// rut: formatRut(rut),
				name: name,
				lastName: lastname,
				userName: username,
				// maternalLastname: maternalLastname,
				email: email,
				phone: phone || '',
				client:
					profile.value !== 'Administrador_Panel'
						? client?.value
						: null,
				agroBusiness:
					agrobusiness && agrobusiness.length
						? agrobusiness.map((agro) => agro.value)
						: [],
				// projectAssigned: alterArrayForMulti(project)
			});
		}

		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [
		id,
		email,
		// rut,
		name,
		lastname,
		username,
		agrobusiness,
		// maternalLastname,
		profile,
		phone,
		client,
		reset,
		profileDataState,
		clientDataState,
	]);

	useEffect(() => {
		if (watchClient) {
			const resultClient = clientDataState.filter(
				(ele) => ele.value === watchClient
			);
			console.log(resultClient, '<<---resultClient');
			// Actualizar la lista de agrícolas disponibles
			const newAgroBusinessData = alphabeticalSort(
				resultClient[0]?.agrobusiness,
				'label'
			);
			setAgroBusinessDataState(newAgroBusinessData);

			// Filtrar las agrícolas seleccionadas que pertenezcan solo al cliente actual
			const currentSelectedValues = getValues('agroBusiness') || [];
			console.log(currentSelectedValues, '<<---currentSelectedValues');
			const validAgroBusinessValues = currentSelectedValues.filter(
				(value) =>
					newAgroBusinessData.some((item) => item.value === value)
			);

			// Actualizar el valor del formulario para mantener solo las agrícolas válidas
			setValue('agroBusiness', validAgroBusinessValues);
		}
	}, [watchClient, clientDataState, setValue, getValues]);

	const onSubmit = async (values) => {
		// console.log(values, '<<---values')

		setLoadingQueryState(true);

		const profileSelected = profileDataState.filter(
			(ele) => ele.value === values.profile
		);

		// saca los objetos
		let clientObj = values.client
			? clientDataState.filter((ele) => ele.value === values.client)
			: null;

		clientObj = clientObj
			? alterArrayGetFields(clientObj, ['id', 'label', 'value'])
			: null;

		// const agroBusinessObj = agroBusinessDataState.filter(
		// 	(ele) => ele.value === values.agroBusiness
		// );
		console.log(agroBusinessDataState, '<<---agroBusinessDataState');
		console.log(values.agroBusiness, '<<---values.agroBusiness');
		const agroBusinessArray = values.agroBusiness
			? values.agroBusiness.reduce((acc, agro) => {
					const dataAgro = agroBusinessDataState.find(
						(item) => item.value === agro
					);
					if (dataAgro) {
						acc.push(dataAgro);
					}
					return acc;
			  }, [])
			: [];
		const query = {
			id: values.id,
			email: values.email,
			// rut: formatRut(values.rut),
			name: values.name,
			lastname: values.lastName,
			username: values.userName,
			// maternalLastname: values.maternalLastname,
			phone: values.phone,
			password: values.password || null,
			profile: profileSelected[0],
			agrobusiness: agroBusinessArray,
			client: clientObj ? clientObj[0] : null,
		};

		// console.log(query, '<<---query');

		try {
			const response = await axiosContext.authAxios.post(
				'v1/user/update-user',
				query
			);

			// console.log(response, '<---resp');

			if (response.status === 200) {
				// alert('listo');
				editFunc(response.data);
				setSuccessQueryState(true);
			}

			setLoadingQueryState(false);
		} catch (err) {
			setLoadingQueryState(false);
		}
	};

	const getProfilesAsync = useCallback(async () => {
		setLoadingDataState(true);
		try {
			let response = await axiosContext.authAxios.post(
				'v1/profile/list-profiles'
			);

			const { data, status } = response;

			if (status === 200) {
				let dataProfiles = alphabeticalSort(data.data, 'label');

				dataProfiles = dataProfiles.map((profile) => {
					if (profile.value === 'Administrador') {
						return {
							...profile,
							label: `${profile.label} (JTP)`,
						};
					}
					return profile;
				});

				setProfileDataState(dataProfiles);
			}
		} catch (error) {
			console.log(error);
			// setLoadingVarietyFilterState(false);
		} finally {
			setLoadingDataState(false);
		}
	}, [axiosContext, setLoadingDataState, setProfileDataState]);

	const getClientsAsync = useCallback(async () => {
		setLoadingDataState(true);
		try {
			let response = await axiosContext.authAxios.post(
				'v1/client/list-clients',
				{
					limit: 500,
				}
			);

			// console.log(response, '<<----respo clientes');

			const { data, status } = response;

			if (status === 200) {
				setClientDataState(alphabeticalSort(data.data, 'label'));
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoadingDataState(false);
		}
	}, [axiosContext, setLoadingDataState, setClientDataState]);

	// endpoints
	useEffect(() => {
		getProfilesAsync();
		getClientsAsync();
	}, []);

	// TOMA EL VALOR DE PASSWORD
	const { password } = getValues();

	useEffect(() => {
		// console.log(watchProfile, '----watchProfile')
		if (watchProfile && watchProfile === 'Administrador_Panel') {
			setHideClientDataState(true);
		} else {
			setHideClientDataState(false);
		}
	}, [watchProfile]);

	const onCloseSnack = () => {
		setSuccessQueryState(false);
	};

	const handleClickShowPassword = () => {
		setShowPassword((prevState) => !prevState);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<Card elevation={0}>
			<CardHeaderWidget
				title={
					viewsTexts.titles.user.userTextEdit +
					' ' +
					name +
					' ' +
					lastname
				}
			/>
			<CloseButtonEditionArea closeFunc={props.closeFunc} />
			<Divider />
			<CardContent>
				<BsRow>
					<BsCol class="col-md-12">
						<form
							autoComplete="on"
							onSubmit={handleSubmit(onSubmit)}
						>
							<BsRow>
								<BsCol class="col-md-12">
									<TextFieldWidgetSelectControllerHorizontal
										options={profileDataState}
										titleOption="label"
										control={control}
										name="profile"
										labeltext="Perfil"
										errors={errors.profile}
										defaultValue=""
										loading={loadingDataState}
										req
										withDefault
										variant="outlined"
									/>
								</BsCol>
							</BsRow>
							{!hideClientDataState ? (
								<BsRow>
									<BsCol class="col-md-6">
										<TextFieldWidgetSelectControllerHorizontal
											options={clientDataState}
											titleOption="label"
											control={control}
											name="client"
											labeltext="Cliente"
											errors={errors.client}
											defaultValue=""
											loading={loadingDataState}
											req
											withDefault
											variant="outlined"
										/>
									</BsCol>
									<BsCol class="col-md-6">
										<TextFieldWidgetSelectControllerHorizontal
											options={agroBusinessDataState}
											titleOption="label"
											control={control}
											name="agroBusiness"
											labeltext="Agrícola"
											errors={errors.agroBusiness}
											defaultValue={[]}
											multiple={true}
											loading={false}
											req={false}
											withDefault
											variant="outlined"
										/>
									</BsCol>
								</BsRow>
							) : null}
							<BsRow>
								<BsCol class="col-md-12">
									{/* <TextFieldWidgetText
										InputLabelProps={{ shrink: true }}
										name="rut"
										autoComplete="rut"
										onChange={formatOnChangeRut}
										labeltext={viewsTexts.forms.rut}
										req={require}
										control={control}
										placeholder="11.111.111-1"
										errors={errors.rut}
										defaultValue=""
									/> */}
									<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="id"
										labeltext=""
										control={control}
										style={{
											display: 'none',
										}}
										defaultValue={id}
									/>
									<TextFieldWidgetText
										name="userName"
										labeltext={viewsTexts.forms.userName}
										InputLabelProps={{
											shrink: true,
										}}
										req={true}
										defaultValue=""
										minLength={3}
										maxLength={12}
										control={control}
										errors={errors.userName}
									/>
								</BsCol>
								{/* <BsCol class="col-md-6"></BsCol> */}
							</BsRow>
							<BsRow>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="name"
										labeltext={viewsTexts.forms.name}
										req={false}
										minLength={3}
										maxLength={100}
										control={control}
										errors={errors.name}
										autoComplete="given-name"
										defaultValue=""
									/>
								</BsCol>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										name="lastName"
										labeltext={viewsTexts.forms.lastName}
										InputLabelProps={{
											shrink: true,
										}}
										req={false}
										minLength={3}
										maxLength={100}
										control={control}
										autoComplete="family-name"
										errors={errors.lastName}
										defaultValue=""
									/>
								</BsCol>
							</BsRow>
							<BsRow>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="email"
										labeltext={viewsTexts.forms.email}
										req={false}
										control={control}
										errors={errors.email}
										autoComplete="email"
										defaultValue=""
									/>
								</BsCol>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="phone"
										type="number"
										labeltext={viewsTexts.forms.phone}
										req={false}
										minLength={8}
										maxLength={11}
										control={control}
										placeholder="56999999999"
										autoComplete="phone"
										errors={errors.phone}
										defaultValue=""
									/>
								</BsCol>
							</BsRow>
							<BsRow>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										name="password"
										type={
											showPassword ? 'text' : 'password'
										}
										autoComplete="new-password"
										minLength={6}
										maxLength={12}
										labeltext={viewsTexts.forms.password}
										control={control}
										errors={errors.password}
										defaultValue=""
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														color="default"
														onClick={
															handleClickShowPassword
														}
														onMouseDown={
															handleMouseDownPassword
														}
													>
														{showPassword ? (
															<Visibility />
														) : (
															<VisibilityOff />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</BsCol>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										name="password2"
										type={
											showPassword ? 'text' : 'password'
										}
										equalto={password}
										autoComplete="new-password"
										minLength={6}
										maxLength={12}
										labeltext={viewsTexts.forms.password2}
										control={control}
										errors={errors.password2}
										defaultValue=""
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														color="default"
														onClick={
															handleClickShowPassword
														}
														onMouseDown={
															handleMouseDownPassword
														}
													>
														{showPassword ? (
															<Visibility />
														) : (
															<VisibilityOff />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</BsCol>
							</BsRow>
							<BsRow class="mt-5">
								<BsCol class="col-md-12 text-center">
									<SaveAction
										title="Guardar"
										color="secondary"
										size="large"
										className="roundedButton ml-md-2"
										variant="contained"
										disabled={loadingQueryState}
										messageType="edit"
										actionClose={() => {
											closeFunc();
											onCloseSnack();
										}}
										mutationLoading={loadingQueryState}
										success={successQueryState}
									/>
								</BsCol>
							</BsRow>
						</form>
					</BsCol>
				</BsRow>
			</CardContent>
		</Card>
	);
};

export default UserEdit;
