import React, { useState, useEffect } from 'react';
import esLocale from 'date-fns/locale/es';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { IsDesktopHandler } from '../../../helpers';
import errorMessage from '../../../common/errorMessages.json';
import { Controller } from 'react-hook-form';
import { TextField, InputLabel, FormControl } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
  return {
    formControl: {
      margin: theme.spacing(1),
      minWidth: 210,
      maxWidth: 230,
      [theme.breakpoints.down('sm')]: {
        minWidth: '95%',
      },
    },
    rootLabel: {
      fontSize: 18,
    },
    textFieldStyle: {
      marginTop: 22,
    },
  };
});

const TextFieldWidgetDateController = (props) => {
  const {
    errors,
    success,
    labeltext,
    name,
    loading,
    disabled,
    control,
    defaultValue,
    format = 'dd-MM',
    req,
    views,
    ...others
  } = props;

  const { classes } = useStyles();
  const [stateMessage, setStateMessage] = useState('');
  const [stateError, setStateError] = useState(false);
  const esLocaleDate = esLocale;
  // const [selectedDate, setSelectedDate] = useState('');
  // const handleDateChange = date => {
  //   setSelectedDate(date);
  // };
  useEffect(() => {
    if (errors && errors.message) {
      setStateMessage(errors.message);
      setStateError(true);
    } else {
      setStateMessage('');
      setStateError(false);
    }
  }, [errors]);

  // useEffect(() => {
  //   if (success) {
  //     setSelectedDate('');
  //   }
  // }, [success]);

  let labelText = null;
  const isDesktop = false;
  if (!isDesktop) {
    labelText = labeltext;
  } else {
    labelText = null;
  }

  return (
    <FormControl className={classes.formControl} error={stateError}>
      {/* <div className={classes.labelDesktopStyle}>{labeltext}</div> */}
      {loading && <p>cargando</p>}
      <InputLabel shrink={true} id={labelText} className={classes.rootLabel}>
        {props.labeltext} {loading && '...cargando'}
      </InputLabel>

      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        rules={{
          required: errorMessage.message[name],
        }}
        render={({ field }) => {
          // console.log(field, '-----');
          const { ref, ...otherfields } = field;
          return (
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={esLocaleDate}
            >
              <DatePicker
                clearable
                name={name}
                placeholder='25-10-2021'
                // format='dd-MM-yyyy'
                format={format}
                label=''
                views={views || [('year', 'month', 'day')]}
                variant='standard'
                inputVariant='standard'
                className={classes.textFieldStyle}
                slotProps={{ textField: { variant: 'standard' } }}
                disabled={disabled}
                {...otherfields}
                // onChange={onChange}
                // value={value}
                {...others}
                fullWidth
                TextFieldComponent={(params) => (
                  <TextField inputRef={ref} {...params} />
                )}
                InputLabelProps={{ shrink: true }}
                invalidDateMessage='Fecha no válida'
                // margin='dense'
                // onChange={handleDateChange}
                // inputRef={register({
                //   required: errorMessage.message[name]
                // })}
                error={stateError}
                helperText={stateMessage}
              />
            </LocalizationProvider>
          );
        }}
      />
    </FormControl>
  );
};
export default TextFieldWidgetDateController;
