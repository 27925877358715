import React, { useState, Fragment, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link, List, ListItem, Tooltip, Divider, Hidden } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import iconJson from '../../../../../../common/iconSVG.json';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import SvgIcon from '@mui/material/SvgIcon';
import EventIcon from '@mui/icons-material/Event';
import DialpadIcon from '@mui/icons-material/Dialpad';
import SettingsIcon from '@mui/icons-material/Settings';
import ContactsIcon from '@mui/icons-material/Contacts';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import NatureIcon from '@mui/icons-material/Nature';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import classesModule from './SidebarNav.module.scss';
import { useTheme, withStyles } from '@mui/styles';
import { Context } from '../../../../../../context/Context';

const ListItemCustom = withStyles((theme) => ({
	root: {
		'&:hover': {
			// backgroundColor: 'blue',
			'& .MuiListItemIcon-root': {
				// color: theme.palette.primary.dark,
			},
			'& .MuiTypography-root': {
				color: theme.palette.primary.dark,
			},
		},
		'&.active': {
			// backgroundColor: theme.palette.primary.dark,
			// color: theme.palette.primary.dark,
			'& .MuiListItemIcon-root': {
				color: theme.palette.white,
				backgroundColor: theme.palette.primary.dark,
			},
			'& .MuiTypography-root': {
				color: theme.palette.primary.dark,
			},
		},
	},
}))(ListItem);

const CollapseCustom = withStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
		boxShadow: theme.shadows[25],
		'& .MuiListItem-root.active': {
			// backgroundColor: 'tomato',
			'& .MuiListItemIcon-root': {
				color: theme.palette.primary.dark,
			},
			'& .MuiTypography-root': {
				color: theme.palette.primary.dark,
			},
		},
	},
}))(Collapse);

const SidebarNav = (props) => {
	const { pages, className, open, ...rest } = props;

	const { logout } = useContext(Context);

	const [activeIdItem, setActiveIdItem] = useState(null);

	const [openState, setOpenState] = useState({
		values: {},
	});

	const theme = useTheme();

	// console.log(theme, 'el theme');

	const handleClickSubItem = (pageId, submenuId) => {
		setActiveIdItem(pageId);
		setOpenState((openState) => ({
			values: {
				...openState.values,
				[submenuId]: !openState.values[submenuId],
			},
		}));
	};

	const handleClickParentSubmenu = (id) => {
		// console.log(id, '<-----id click1', openState);
		// setActiveIdItem(id);
		setOpenState((openState) => ({
			values: {
				...!openState.values,
				[id]: !openState.values[id],
			},
		}));
	};

	const handleClickNoSub = (id) => {
		// console.log(id, '<-----id handleClickNoSub');
		setActiveIdItem(id);
		setOpenState((openState) => ({
			values: {
				...!openState.values,
				[id]: !openState.values[id],
			},
		}));
	};

	// console.log(activeIdItem, '------activeIdItem--------------------------');

	const renderMaterialIcon = (icon) => {
		switch (icon) {
			case 'SettingsIcon':
				return <SettingsIcon />;

			case 'DashboardIcon':
				return <DashboardIcon />;

			case 'PersonIcon':
				return <PersonIcon />;

			case 'AssignmentIndIcon':
				return <AssignmentIndIcon />;

			case 'EventIcon':
				return <EventIcon />;

			case 'DialpadIcon':
				return <DialpadIcon />;

			case 'ContactsIcon':
				return <ContactsIcon />;

			case 'NatureIcon':
				return <NatureIcon />;

			case 'HowToRegIcon':
				return <HowToRegIcon />;

			case 'AddIcon':
				return <AddIcon />;

			case 'ListIcon':
				return <ListIcon />;

			case 'AssignmentTurnedInIcon':
				return <AssignmentTurnedInIcon />;

			default:
				break;
		}
	};

	// console.log(openState, '<<<<<<-----------openState');

	return (
		<List {...rest} className={clsx(classesModule.root, className)}>
			{/* <ListItem
				button
				activeClassName={classes.active}
				className={classes.item}
				key={1000}
				selected={false}
				component={NavLink}
				to="/"
				onClick={() => handleClick(1000)}
				name={1000}>
				<ListItemIcon className={classes.icon}><DashboardIcon /></ListItemIcon>
				<ListItemText primary="Inicio" />
			</ListItem> */}
			{pages.map((page) => (
				<Fragment key={page.id + 'fragment'}>
					{page.submenu ? (
						<>
							<Tooltip
								title={page.title}
								aria-label={page.title}
								placement="right"
								arrow
								key={page.id}
								disableHoverListener={open}
							>
								<ListItemCustom
									button
									// activeClassName={
									// 	page.submenu ? '' : classesModule.active
									// }
									component={Link}
									className={
										activeIdItem === page.id ? 'active' : ''
									}
									onClick={() =>
										handleClickParentSubmenu(page.id)
									}
									name={page.id}
								>
									<ListItemIcon
										className={clsx(classesModule.icon)}
									>
										{page.iconType === 'material' ? (
											renderMaterialIcon(page.icon)
										) : (
											<SvgIcon viewBox="0 -1 18 22">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d={iconJson.icon[page.icon]}
												/>
											</SvgIcon>
										)}
									</ListItemIcon>
									<ListItemText primary={page.title} />
									{openState.values[page.id] ? (
										<ExpandLess />
									) : (
										<ExpandMore />
									)}
								</ListItemCustom>
							</Tooltip>
							<CollapseCustom
								// className={classesModule.Collapse}
								in={openState.values[page.id]}
								timeout="auto"
								unmountOnExit
								key={page.id + 'submenu'}
							>
								<List component="div" disablePadding>
									{page.submenu.map((submenu) => (
										<Tooltip
											title={`${page.title} > ${submenu.title}`}
											aria-label={`${page.title} > ${submenu.title}`}
											placement="right"
											arrow
											key={submenu.id}
											disableHoverListener={open}
										>
											<ListItem
												button
												className={
													classesModule.subitem
												}
												component={NavLink}
												to={submenu.href}
												onClick={() =>
													handleClickSubItem(
														page.id,
														submenu.id
													)
												}
												name={submenu.id}
												// data-menu={page.id}
											>
												<ListItemIcon
													className={clsx(
														classesModule.icon
													)}
												>
													{submenu.iconType ===
													'material' ? (
														renderMaterialIcon(
															submenu.icon
														)
													) : (
														<SvgIcon viewBox="0 -1 18 22">
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																// stroke="#66788E"
																// stroke-width="2"
																d={
																	iconJson
																		.icon[
																		submenu
																			.icon
																	]
																}
															/>
														</SvgIcon>
													)}
												</ListItemIcon>
												<ListItemText
													primary={submenu.title}
												/>
											</ListItem>
										</Tooltip>
									))}
								</List>
							</CollapseCustom>
						</>
					) : (
						<>
							<Tooltip
								title={page.title}
								aria-label={page.title}
								placement="right"
								arrow
								disableHoverListener={open}
							>
								<ListItemCustom
									button
									key={page.id}
									component={NavLink}
									to={page.href ? page.href : 'null'}
									onClick={() => handleClickNoSub(page.id)}
									name={page.id}
								>
									<ListItemIcon
										className={clsx(classesModule.icon)}
									>
										{page.iconType === 'material' ? (
											renderMaterialIcon(page.icon)
										) : (
											<SvgIcon viewBox="0 -1 18 22">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													// stroke="#66788E"
													// stroke-width="2"
													d={iconJson.icon[page.icon]}
												/>
											</SvgIcon>
										)}
									</ListItemIcon>
									<ListItemText primary={page.title} />
								</ListItemCustom>
							</Tooltip>
						</>
					)}
				</Fragment>
			))}
			{/* <Tooltip
				title="HOLA"
				aria-label="HOLA"
				placement="right"
				arrow
				disableHoverListener={open}
			>
				<ListItemCustom
					button
					key="1234567"
					to="intelligence"
					component={NavLink}
					onClick={() => handleClick('1')}
					name="1"
				>
					<ListItemIcon className={clsx(classesModule.icon)}>
						<SvgIcon viewBox="0 -1 18 22">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d={iconJson.icon['intelligence']}
							/>
						</SvgIcon>
					</ListItemIcon>
					<ListItemText primary="HOLA" />
				</ListItemCustom>
			</Tooltip> */}
			<Hidden only={['sm', 'md', 'lg', 'xl']}>
				<Divider className="my-3" />
				<ListItem
					button
					key={1000}
					selected={false}
					component={NavLink}
					to="/"
					onClick={() => logout()}
					name={1000}
				>
					<ListItemIcon>
						<ExitToAppIcon />
					</ListItemIcon>
					<ListItemText primary="Cerrar Sesión" />
				</ListItem>
			</Hidden>
		</List>
	);
};

SidebarNav.propTypes = {
	className: PropTypes.string,
	pages: PropTypes.array.isRequired,
};

export default SidebarNav;
