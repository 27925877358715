import { useContext } from "react";
import { Switch, Redirect } from "react-router-dom";
// import { hotjar } from 'react-hotjar';
// import gtag from 'ga-gtag';
// import TagManager from 'react-gtm-module';
import { Context } from "./context/Context";
import { RouteWithLayout } from "./components";
import {
  Main as MainLayout,
  MainV2 as MainLayoutV2,
  MinimalNoTopbar as MinimalNoTopbarLayout,
} from "./layouts";

import {
  SignIn as SignInView,
  RecoveryPassword as RecoveryPasswordView,
  NotFound as NotFoundView,
  UserAdd as UserAddView,
  UserList as UserListView,
  UserPrivilege as UserPrivilegeView,
  Dashboard as DashboardView,
  DataAdd as DataAddView,
  DataList as DataListView,
  Report as ReportView,
  PuAdd as PuAddView,
  PuList as PuListView,
  HdAdd as HdAddView,
  HdList as HdListView,
  HdEdit as HdEditView,
  CmAdd as ClientAddView,
  CmEdit as ClientEditView,
  CmList as ClientListView,
  LoadExcel as LoadExcelView,
  AmAdd as AmAddView,
  AmList as AmListView,
  AmEdit as AmEditView,
  OmAdd as OmAddView,
  OmList as OmListView,
  OmEdit as OmEditView,
  MeList as MeListView,
  MeExamine as MeExamineView,
  InitialUpload as InitialUploadView,
  YieldCalculator as YieldCalculatorView,
  Identification as IdentificationView,
  Description as DescriptionView,
  BreakBudDormancy as BreakBudDormancyView,
  LeafAnalysis as LeafAnalysisView,
  SoilAnalysis as SoilAnalysisView,
  RootReservoir as RootReservoirView,
  WaterAnalysis as WaterAnalysisView,
  Categorization as CategorizationView,
  Reception as ReceptionView,
  Packing as PackingView,
} from "./views";

const Routes = () => {
  const { token } = useContext(Context);

  // const location = useLocation();

  // let siteIdHjStaging = '3215663';
  // let siteIdHj = '3215659';
  // let hjsv = '6';

  // useEffect(() => {
  // 	hotjar.initialize(siteIdHj, hjsv);
  // }, []);

  // useEffect(() => {
  // 	if (hotjar.initialized()) {
  // 		hotjar.identify('USER_ID', { userProperty: userId });
  // 	}
  // }, [userId]);

  // useEffect(() => {
  // if (userId) {
  // const tagManagerArgs = {

  // const tagManagerArgs = {
  // 	gtmId: 'GTM-M2XNPBM',
  // 	dataLayer: {
  // 		userId: userId,
  // 		clientName: 'Garces',
  // 	},
  // };
  // TagManager.initialize(tagManagerArgs);
  // 	}
  // }, [userId]);

  return (
    <Switch>
      {!token && <Redirect from="/" to="/sign-in" exact />}
      <Redirect from="/" to="/dashboard" exact />

      {token && <Redirect from="/sign-in-rol" to="/dashboard" exact />}
      {token && <Redirect from="/sign-in" to="/dashboard" exact />}
      {/* -----DASHBOARD----- */}
      {!token && <Redirect from="/dashboard" to="/sign-in" exact />}

      {/* -----ACCOUNT----- */}
      {/* {!token && <Redirect from="/account" to="/sign-in" exact />} */}
      {!token && <Redirect from="/settings" to="/sign-in" exact />}

      {/* -----PARAMETERS-----
			{!token && <Redirect from="/parameters-edit" to="/sign-in" exact />} */}

      {/* -----PRODUCTIVE_UNIT----- */}
      {!token && <Redirect from="/pu-add" to="/sign-in" exact />}
      {!token && <Redirect from="/pu-list" to="/sign-in" exact />}

      {/* -----CLIENT_MODULE----- */}
      {!token && <Redirect from="/client-add" to="/sign-in" exact />}
      {!token && <Redirect from="/client-edit" to="/sign-in" exact />}
      {!token && <Redirect from="/client-list" to="/sign-in" exact />}

      {/* -----LOAD_EXCEL----- */}
      {!token && <Redirect from="/load-excel" to="/sign-in" exact />}

      {/* -----MASSIVE_EDITION----- */}
      {!token && <Redirect from="/me-list" to="/sign-in" exact />}
      {!token && <Redirect from="/me-examine" to="/sign-in" exact />}

      {/* -----INITIAL_UPLOAD----- */}
      {!token && <Redirect from="/initial-upload" to="/sign-in" exact />}

      {/* -----DATA_HISTORICA----- */}
      {!token && <Redirect from="/hd-add" to="/sign-in" exact />}
      {!token && <Redirect from="/hd-list" to="/sign-in" exact />}
      {!token && <Redirect from="/hd-edit" to="/sign-in" exact />}
      {/* -----AGRO_BUSINESS----- */}
      {!token && <Redirect from="/am-add" to="/sign-in" exact />}
      {!token && <Redirect from="/am-list" to="/sign-in" exact />}
      {!token && <Redirect from="/am-edit" to="/sign-in" exact />}

      {/* -----ORCHARD_MODULE----- */}
      {!token && <Redirect from="/om-add" to="/sign-in" exact />}
      {!token && <Redirect from="/om-list" to="/sign-in" exact />}
      {!token && <Redirect from="/om-edit" to="/sign-in" exact />}

      {/* -----user----- */}
      {!token && <Redirect from="/user-add" to="/sign-in" exact />}
      {!token && <Redirect from="/user-list" to="/sign-in" exact />}
      {!token && <Redirect from="/user-privilege" to="/sign-in" exact />}

      {/* -----data----- */}
      {!token && <Redirect from="/data-add" to="/sign-in" exact />}
      {!token && <Redirect from="/data-list" to="/sign-in" exact />}

      {/* -----report----- */}
      {!token && <Redirect from="/report" to="/sign-in" exact />}

      {/* -----bd Collection----- */}
      {!token && (
        <Redirect from="/bdcollection-yield-calculator" to="/sign-in" exact />
      )}
      {!token && (
        <Redirect from="/bdcollection-identification" to="/sign-in" exact />
      )}
      {!token && (
        <Redirect from="/bdcollection-description" to="/sign-in" exact />
      )}
      {!token && (
        <Redirect from="/bdcollection-break-bud-dormancy" to="/sign-in" exact />
      )}
      {!token && (
        <Redirect from="/bdcollection-leaf-analysis" to="/sign-in" exact />
      )}
      {!token && (
        <Redirect from="/bdcollection-soil-analysis" to="/sign-in" exact />
      )}
      {!token && (
        <Redirect from="/bdcollection-root-reservoir" to="/sign-in" exact />
      )}
      {!token && (
        <Redirect from="/bdcollection-water-analysis" to="/sign-in" exact />
      )}
      {!token && (
        <Redirect from="/bdcollection-categorization" to="/sign-in" exact />
      )}
      {!token && (
        <Redirect from="/bdcollection-reception" to="/sign-in" exact />
      )}
      {!token && <Redirect from="/bdcollection-packing" to="/sign-in" exact />}
      {/* -----SIGN_IN----- */}

      {/* -----PRODUCTIVE_UNIT----- */}
      <RouteWithLayout
        component={PuAddView}
        exact
        layout={MainLayoutV2}
        path="/pu-add"
      />

      <RouteWithLayout
        component={PuListView}
        exact
        layout={MainLayoutV2}
        path="/pu-list"
      />

      {/* -----DATA_HISTORICA----- */}
      <RouteWithLayout
        component={HdAddView}
        exact
        layout={MainLayoutV2}
        path="/hd-add"
      />

      <RouteWithLayout
        component={HdListView}
        exact
        layout={MainLayoutV2}
        path="/hd-list"
      />
      <RouteWithLayout
        component={HdEditView}
        exact
        layout={MainLayoutV2}
        path="/hd-edit"
      />

      {/* -----CLIENT_MODULE----- */}
      <RouteWithLayout
        component={ClientAddView}
        exact
        layout={MainLayoutV2}
        path="/client-add"
      />

      <RouteWithLayout
        component={ClientEditView}
        exact
        layout={MainLayoutV2}
        path="/client-edit"
      />

      <RouteWithLayout
        component={ClientListView}
        exact
        layout={MainLayoutV2}
        path="/client-list"
      />

      {/* -----LOAD_EXCEL----- */}
      <RouteWithLayout
        component={LoadExcelView}
        exact
        layout={MainLayoutV2}
        path="/load-excel"
      />

      {/* -----MASSIVE_EDITION----- */}

      <RouteWithLayout
        component={MeListView}
        exact
        layout={MainLayoutV2}
        path="/me-list"
      />

      <RouteWithLayout
        component={MeExamineView}
        exact
        layout={MainLayoutV2}
        path="/me-examine"
      />

      {/* -----INITIAL_UPLOAD----- */}
      <RouteWithLayout
        component={InitialUploadView}
        exact
        layout={MainLayoutV2}
        path="/initial-upload"
      />

      {/* -----AGRO_BUSINESS----- */}
      <RouteWithLayout
        component={AmAddView}
        exact
        layout={MainLayoutV2}
        path="/am-add"
      />
      <RouteWithLayout
        component={AmListView}
        exact
        layout={MainLayoutV2}
        path="/am-list"
      />
      <RouteWithLayout
        component={AmEditView}
        exact
        layout={MainLayoutV2}
        path="/am-edit"
      />
      {/* -----ORCHARD_MODULE----- */}
      <RouteWithLayout
        component={OmAddView}
        exact
        layout={MainLayoutV2}
        path="/om-add"
      />

      <RouteWithLayout
        component={OmListView}
        exact
        layout={MainLayoutV2}
        path="/om-list"
      />

      <RouteWithLayout
        component={OmEditView}
        exact
        layout={MainLayoutV2}
        path="/om-edit"
      />

      {/* -----USER----- */}
      <RouteWithLayout
        component={UserAddView}
        exact
        layout={MainLayoutV2}
        path="/user-add"
      />

      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayoutV2}
        path="/user-list"
      />

      <RouteWithLayout
        component={UserPrivilegeView}
        exact
        layout={MainLayoutV2}
        path="/user-privilege"
      />
      {/* -----USER----- */}

      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayoutV2}
        path="/dashboard"
      />

      {/* -----DATA----- */}
      <RouteWithLayout
        component={DataAddView}
        exact
        layout={MainLayoutV2}
        path="/data-add"
      />

      <RouteWithLayout
        component={DataListView}
        exact
        layout={MainLayoutV2}
        path="/data-list"
      />

      {/* -----REPORT----- */}
      <RouteWithLayout
        component={ReportView}
        exact
        layout={MainLayoutV2}
        path="/report"
      />

      {/* ------BD COLLECTION----- */}

      <RouteWithLayout
        component={IdentificationView}
        exact
        layout={MainLayoutV2}
        path="/bdcollection-identification"
      />

      <RouteWithLayout
        component={DescriptionView}
        exact
        layout={MainLayoutV2}
        path="/bdcollection-description"
      />

      <RouteWithLayout
        component={YieldCalculatorView}
        exact
        layout={MainLayoutV2}
        path="/bdcollection-yield-calculator"
      />

      <RouteWithLayout
        component={BreakBudDormancyView}
        exact
        layout={MainLayoutV2}
        path="/bdcollection-break-bud-dormancy"
      />

      <RouteWithLayout
        component={LeafAnalysisView}
        exact
        layout={MainLayoutV2}
        path="/bdcollection-leaf-analysis"
      />

      <RouteWithLayout
        component={SoilAnalysisView}
        exact
        layout={MainLayoutV2}
        path="/bdcollection-soil-analysis"
      />

      <RouteWithLayout
        component={RootReservoirView}
        exact
        layout={MainLayoutV2}
        path="/bdcollection-root-reservoir"
      />

      <RouteWithLayout
        component={WaterAnalysisView}
        exact
        layout={MainLayoutV2}
        path="/bdcollection-water-analysis"
      />

      <RouteWithLayout
        component={CategorizationView}
        exact
        layout={MainLayoutV2}
        path="/bdcollection-categorization"
      />

      <RouteWithLayout
        component={ReceptionView}
        exact
        layout={MainLayoutV2}
        path="/bdcollection-reception"
      />

      <RouteWithLayout
        component={PackingView}
        exact
        layout={MainLayoutV2}
        path="/bdcollection-packing"
      />

      {/* <RouteWithLayout
				component={UserAddView}
				exact
				layout={MainLayout}
				path="/user-add"
			/>
			<RouteWithLayout
				component={UserListView}
				exact
				layout={MainLayout}
				path="/user-list"
			/>

			<RouteWithLayout
				component={UserPrivilegeView}
				exact
				layout={MainLayout}
				path="/user-privilege"
			/> */}

      {/* <RouteWithLayout
				component={AccountView}
				exact
				layout={MainLayout}
				path="/account"
			/> */}

      {/* <RouteWithLayout
				component={ParametersView}
				exact
				layout={MainLayout}
				path="/parameters-edit"
			/> */}

      {/* <RouteWithLayout
				component={SignUpView}
				exact
				layout={MinimalLayout}
				path="/sign-up"
			/> */}
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalNoTopbarLayout}
        path="/sign-in"
      />

      <RouteWithLayout
        component={RecoveryPasswordView}
        exact
        layout={MinimalNoTopbarLayout}
        path="/recovery-password"
      />

      {/* <RouteWithLayout
				component={ValidateAccountView}
				exact
				layout={MinimalLayout}
				path="/validate-account"
			/> */}

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MainLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
