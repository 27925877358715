import { numberFormatDecimals } from 'helpers';
import { validationTypes } from './validationRules';
import {
  breakBudDormancyDictionary,
  categorizationDictionary,
  descriptionDictionary,
  leafAnalysisDictionary,
  packingDictionary,
  receptionDictionary,
  rootReservoirDictionary,
  soilAnalysisDictionary,
  waterAnalysisDictionary,
  yieldCalculatorDictionary,
} from './dictionary';

const FIELD_CONFIGS = {
  ...yieldCalculatorDictionary,
  ...descriptionDictionary,
  ...breakBudDormancyDictionary,
  ...leafAnalysisDictionary,
  ...soilAnalysisDictionary,
  ...rootReservoirDictionary,
  ...waterAnalysisDictionary,
  ...categorizationDictionary,
  ...receptionDictionary,
  ...packingDictionary,
};

// Generador de formatters
const createFormatters = (configs) => {
  const formatters = {};
  Object.values(configs).forEach((moduleFields) => {
    Object.values(moduleFields).forEach((field) => {
      formatters[field.field] = (value) => {
        if (value === null || value === undefined) return ''; // Manejo de null/undefined

        if (typeof value === 'boolean') {
          return value ? true : false; // Formatear booleanos
        }

        if (typeof value === 'string') {
          return value.trim(); // Formatear cadenas de texto eliminando espacios innecesarios
        }

        if (typeof value === 'number') {
          // Formatear números
          // const formatted = Number.isInteger(value)
          //   ? value
          //   : numberFormatDecimals(value, field.decimals);

          // TODO: revisar, pero todos debe recibir formato número
          const formatted = numberFormatDecimals(value, field.decimals);

          let result = formatted;
          if (field.prefix) result = `${field.prefix} ${result}`;
          if (field.suffix) result = `${result} ${field.suffix}`;
          return result;
        }

        if (Array.isArray(value)) {
          // Formatear arrays como una cadena separada por comas
          return value.join(', ');
        }

        if (typeof value === 'object') {
          // Formatear objetos como JSON stringified (puede ajustarse según la necesidad)
          return JSON.stringify(value);
        }

        // Para otros tipos no esperados
        console.warn(
          `Formato no definido para el campo ${field.field}:`,
          value
        );
        return value.toString(); // Convertir a cadena como último recurso
      };
    });
  });
  return formatters;
};

// Generador de validators
const createValidators = (configs) => {
  const validators = {};
  Object.values(configs).forEach((moduleFields) => {
    Object.values(moduleFields).forEach((field) => {
      validators[field.field] = (value) => {
        const errors = [];
        const rules = field.validationRules || [];

        rules.forEach((rule) => {
          if (validationTypes[rule.type]) {
            if (!validationTypes[rule.type](value, rule)) {
              errors.push(rule.message || 'Error de validación');
            }
          } else if (rule.type === 'custom' && rule.validate) {
            // Si es una validación personalizada
            if (!rule.validate(value)) {
              errors.push(rule.message || 'Error de validación personalizada');
            }
          }
        });

        return {
          valid: errors.length === 0,
          message: errors,
        };
      };
    });
  });
  return validators;
};

export const formatters = createFormatters(FIELD_CONFIGS);
export const validators = createValidators(FIELD_CONFIGS);
export const FIELDS = FIELD_CONFIGS;
