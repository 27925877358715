import React from "react";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import classes from "./BreadCrumbs.module.scss";
import breadCrumbsNames from "../../common/breadCrumbsNames.json";

const BreadCrumbs = (props) => {
  const pathNames = props.match.url.split("/");
  const moduleName = pathNames[1].split("-");
  const pathToList = moduleName[0] + "-list";
  let subModule = null;
  switch (moduleName[1]) {
    case "add":
      subModule = "Agregar";
      break;
    case "edit":
      subModule = "Editar";
      break;
    case "privilege":
      subModule = "Privilegios";
      break;
    case "examine":
      subModule = "Examinar";
      break;
    default:
      subModule = "Listar";
      break;
  }

  return (
    <React.Fragment>
      <Breadcrumbs
        className={classes.BreadCrumbs}
        aria-label="breadcrumb"
        component="div"
      >
        <Link to="/">Home</Link>
        {moduleName[0] === "bdcollection" ? null : (
          <Link to={pathToList}>
            {breadCrumbsNames.modules[moduleName[0]].name}
          </Link>
        )}

        <Typography color="textPrimary">{subModule}</Typography>
      </Breadcrumbs>
      <p className={classes.description}>{props.subTitle}</p>
    </React.Fragment>
  );
};

export default withRouter(BreadCrumbs);
