import _ from "lodash";
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useForm } from "react-hook-form";
import {
  Card,
  CardContent,
  Divider,
  Typography,
  TextField,
  FormGroup,
} from "@mui/material";
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetSelectController,
  BreadCrumbs,
  AutoCompleteController,
  CheckboxWidgetControlled,
  SelectMultipleWidget,
  ButtonAction,
  SimpleIconButton,
  SearchInput,
  LoadingWidget,
} from "../../../components";
import { SimpleTabs, TabPanel } from "../../../components/TabPanel";
import { BsContainer, BsRow, BsCol } from "../../../layouts/components";
import classes from "./UserPrivilege.module.scss";
import viewsTexts from "../../../common/viewsTexts.json";
import { PROFILES, USERS, HUERTOS } from "helpers/Mockups";
import { AxiosContext } from "../../../context/AxiosContext";
import {
  alphabeticalSort,
  alterArrayAddAssigned,
  alterArrayAddAssignedModules,
  alterArrayForMulti,
  alterArrayForMultiUsers,
  alterArrayModulesAssignProcess,
} from "../../../helpers";
import { FiltersContext } from "../../../context/filters/FiltersContext";
import { Context } from "../../../context/Context";
import clsx from "clsx";
import { useDebouncedSearch } from "customHooks/useDebouncedSearch";

const UserPrivilege = () => {
  const [profileDataState, setProfileDataState] = useState([]);
  const [modulesDataState, setModulesDataState] = useState([]);
  const [usersDataState, setUsersDataState] = useState([]);
  const [clientDataState, setClientDataState] = useState([]);
  const [clientOrchardDataState, setClientOrchardDataState] = useState([]);
  const [usersDataComboState, setUsersDataComboState] = useState([]);
  const [orchardsDataState, setOrchardsDataState] = useState([]);

  const [loadingDataState, setLoadingDataState] = useState(false);

  const [profileSelectedState, setProfileSelectedState] = useState(0);
  const [userSelectedState, setUserSelectedState] = useState(null);
  const [checkboxesState, setCheckboxesState] = useState([]);
  const [valueTabState, setValueTabState] = useState(0);

  const [loadingMutationState, setLoadingMutationState] = useState(false);
  const [successQueryState, setSuccessQueryState] = useState(false);

  const axiosContext = useContext(AxiosContext);

  // definir un useRef que mantenga el estado del toggle, con valor default false
  const toggleRef = useRef(false);
  const toggleProcessRefs = useRef({});
  const toggleRefOrchard = useRef(false);

  const {
    state: { userOrchard_filter, isLoading },
    // getMyOrchardsInfo,
    addFilter,
  } = useContext(FiltersContext);

  const context = useContext(Context);
  const userOrchard = context.userOrchard;

  const tabItems = ["Huertos", "Módulos", "Procesos"];

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const watchProfile = watch("profile", "");
  const watchusercombo = watch("usercombo", "");
  const watchClient = watch("client", "");

  // useEffect(() => {
  // 	setUsersDataState([]);
  // }, []);

  const getProfilesAsync = useCallback(async () => {
    setLoadingDataState(true);
    try {
      let response = await axiosContext.authAxios.post(
        "v1/profile/list-profiles"
      );

      const { data, status } = response;

      if (status === 200) {
        setProfileDataState(alphabeticalSort(data.data, "label"));
      }
    } catch (error) {
      console.log(error);
      // setLoadingVarietyFilterState(false);
    } finally {
      setLoadingDataState(false);
    }
  }, [axiosContext, setLoadingDataState, setProfileDataState]);

  const getClientsAsync = useCallback(async () => {
    setLoadingDataState(true);
    try {
      let response = await axiosContext.authAxios.post(
        "v1/client/list-clients",
        {
          limit: 500,
        }
      );

      // console.log(response, '<<----respo clientes');

      const { data, status } = response;

      if (status === 200) {
        setClientDataState(alphabeticalSort(data.data, "label"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDataState(false);
    }
  }, [axiosContext, setLoadingDataState, setClientDataState]);

  const getModulesAsync = useCallback(async () => {
    setLoadingDataState(true);
    try {
      let response = await axiosContext.authAxios.post("v1/module/list-module");

      const { data, status } = response;

      if (status === 200) {
        setModulesDataState(data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDataState(false);
    }
  }, [axiosContext, setLoadingDataState, setModulesDataState]);

  const getOrchardsAsync = useCallback(async () => {
    setLoadingDataState(true);
    try {
      const bodyPost = {
        orchard: userOrchard,
        // clientValue: userClient?.value || null,
      };

      let response = await axiosContext.authAxios.post(
        "v1/orchard/admin",
        bodyPost
      );

      const { data, status } = response;

      if (status === 200) {
        setOrchardsDataState(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDataState(false);
    }
  }, [axiosContext, setLoadingDataState, setOrchardsDataState, userOrchard]);

  const getUsersAsync = async (idClient) => {
    setLoadingDataState(true);
    // console.log(idClient, '-----idClient');
    try {
      let objQuery = {
        client: idClient,
        limit: 2000,
      };

      // console.log(objQuery, '----------------------la query que va');

      const response = await axiosContext.authAxios.post(
        "v1/user/list-users",
        objQuery
      );

      const { data, status } = response;

      // console.log(response.data, '<-----response query');

      if (status === 200) {
        setUsersDataState(data.data);
        setLoadingDataState(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingDataState(false);
      // setLoadingVarietyFilterState(false);
    }
  };

  useEffect(() => {
    // addFilter({
    // 	isLoading: true,
    // });
    getProfilesAsync();
    getClientsAsync();
    getModulesAsync();
    getOrchardsAsync();
    // getMyOrchardsInfo(userOrchard);
    console.log(userOrchard, "--------firstRender");
  }, []);

  useEffect(() => {
    // console.log('checkboxesState: ', checkboxesState);
  }, [checkboxesState]);

  // para huerto
  const {
    searchDebouncedHandler,
    searchResults,
    searchTerm,
    clearTerm,
    updateDataListHandler,
  } = useDebouncedSearch([], "label");

  useEffect(() => {
    setUserSelectedState(null);
    setCheckboxesState([]);
    setValue("usercombo", null);

    if (profileSelectedState) {
      // console.log(profileSelectedState, '----');

      // TODO: cuando tengan profile, filtrar el profile selected
      // const resultUsers = usersDataState.filter(
      // 	(ele) => ele.profile?.value === profileSelectedState
      // );

      const resultUsers = usersDataState;

      const resultProfileFiltered = resultUsers.filter(
        (ele) => ele.profile?.value === profileSelectedState
      );

      // console.log(resultProfileFiltered, '-----resultProfileFiltered');

      setUsersDataComboState(alterArrayForMultiUsers(resultProfileFiltered));
    } else {
      setUsersDataComboState([]);
    }
  }, [profileSelectedState, usersDataState]);

  useEffect(() => {
    if (userSelectedState) {
      // const adminOrchardsFilterByClient = userOrchard_filter.filter(
      // 	(ele) => ele.clientValue === userSelectedState?.client?.value
      // );

      const adminOrchardsFilterByClient = orchardsDataState.filter(
        (ele) => ele.clientValue === userSelectedState?.client?.value
      );

      // console.log(userOrchard_filter, userSelectedState, '-----');

      const arrayOrchardChecks = alterArrayAddAssigned(
        adminOrchardsFilterByClient,
        userSelectedState.orchard
      );

      // console.log(arrayOrchardChecks, '-----------arrayOrchardChecks');

      setClientOrchardDataState(arrayOrchardChecks);

      updateDataListHandler(arrayOrchardChecks);

      const arrayModulesChecks = alterArrayModulesAssignProcess(
        alterArrayAddAssignedModules(
          modulesDataState,
          userSelectedState.moduleProcess
        ),
        userSelectedState.moduleProcess
      );

      // console.log(arrayModulesChecks, '--------arrayModulesChecks');
      setCheckboxesState(arrayModulesChecks);
    }
  }, [userSelectedState, orchardsDataState]);

  const handleChangeTab = (event, newValue) => {
    setValueTabState(newValue);
  };

  const onSubmit = async (values) => {
    console.log(values, "valores que llegan", clientOrchardDataState);
    setLoadingMutationState(true);

    const resultOrchards = clientOrchardDataState.filter((ele) => ele.assigned);
    const arrayOrchard = resultOrchards.map((orchard) => {
      return {
        orchard: orchard.value,
      };
    });

    const assignedModules = checkboxesState.filter((ele) => ele.assigned);

    const alterArrayAssignedModulesProcess = (assignedModules) => {
      const newArray = assignedModules.map((module) => {
        const newModule = { ...module };
        let result = [];
        if (newModule.process) {
          result = newModule.process.filter((ele) => ele.assigned);
          result = result.map((process) => _.omit(process, ["assigned"]));
        }

        const { assigned, ...rest } = newModule;

        return {
          ...rest,
          process: result,
        };
      });
      return newArray;
    };

    const variable = {
      userInputModuleProcess: alterArrayAssignedModulesProcess(assignedModules),
      userInputOrchardModule: arrayOrchard,
      // id: parseInt(values.userId)
      id: userSelectedState.id,
    };
    // console.log('todos los datos: ', variable);
    try {
      const response = await axiosContext.authAxios.post(
        "v1/user/update-privilege-user",
        variable
      );
      // const bodyInfo = response.data;

      // 	// setProfileSelectedState('');
      // console.log(response, '<-----response');
      // 	// let response;

      if (response.status === 200) {
        setValue("usercombo", "");
        // setValue('profile', '');
        // setValue('client', '');
        // // setProfileSelectedState(0);
        setUserSelectedState(null);
        setClientOrchardDataState([]);
        setCheckboxesState([]);
        setValue("profile", "");
        setValue("client", "");
        setSuccessQueryState(true);
      }
      setLoadingMutationState(false);
    } catch (err) {
      console.log(err, "-------error");
      setLoadingMutationState(false);
    } finally {
      clearTerm();
    }
  };

  // render checks Orchard
  const handleChangeOrchard = (e) => {
    // console.log(e.target.value, '------------');
    const newArrayEdit = clientOrchardDataState.map((check) => {
      if (check.id !== e.target.value) {
        return check;
      } else {
        const checkNew = {
          ...check,
          assigned: e.target.checked,
        };
        return checkNew;
      }
    });
    setClientOrchardDataState(newArrayEdit);
  };

  const handleChange = (e) => {
    // console.log(e.target, '----', checkboxesState);
    const newArrayEdit = checkboxesState.map((check) => {
      if (check.id !== e.target.value) {
        return check;
      } else {
        const checkNew = {
          ...check,
          assigned: e.target.checked,
        };
        return checkNew;
      }
    });
    // console.log(newArrayEdit, '----newArrayEdit');
    setCheckboxesState(newArrayEdit);
  };

  const handleToggleModulesProcess = () => {
    // si el toggle esta en false, se asignan todos los modulos
    if (!toggleRef.current) {
      const newArrayEdit = checkboxesState.map((check) => {
        const newProcess = check.process.map((pro) => {
          const proNew = {
            ...pro,
            assigned: true,
          };
          return proNew;
        });
        return {
          ...check,
          assigned: true,
          process: newProcess,
        };
      });
      setCheckboxesState(newArrayEdit);
      toggleRef.current = true;
    } else {
      const newArrayEdit = checkboxesState.map((check) => {
        const newProcess = check.process.map((pro) => {
          const proNew = {
            ...pro,
            assigned: false,
          };
          return proNew;
        });
        return {
          ...check,
          assigned: false,
          process: newProcess,
        };
      });
      setCheckboxesState(newArrayEdit);
      toggleRef.current = false;
    }
  };

  const handleToggleModuleProcesses = (idModule) => {
    // Inicializar el toggle para este módulo si no existe
    if (toggleProcessRefs.current[idModule] === undefined) {
      toggleProcessRefs.current[idModule] = false;
    }

    if (!toggleProcessRefs.current[idModule]) {
      const newArrayEdit = checkboxesState.map((check) => {
        if (check.id === idModule) {
          const newProcess = check.process.map((pro) => ({
            ...pro,
            assigned: true,
          }));
          return {
            ...check,
            assigned: true,
            process: newProcess,
          };
        }
        return check;
      });
      setCheckboxesState(newArrayEdit);
      toggleProcessRefs.current[idModule] = true;
    } else {
      const newArrayEdit = checkboxesState.map((check) => {
        if (check.id === idModule) {
          const newProcess = check.process.map((pro) => ({
            ...pro,
            assigned: false,
          }));
          return {
            ...check,
            assigned: false,
            process: newProcess,
          };
        }
        return check;
      });
      setCheckboxesState(newArrayEdit);
      toggleProcessRefs.current[idModule] = false;
    }
  };

  const handleToggleOrchards = () => {
    // si el toggle esta en false, se asignan todos los modulos
    if (!toggleRefOrchard.current) {
      const newArrayEdit = clientOrchardDataState.map((check) => {
        const checkNew = {
          ...check,
          assigned: true,
        };
        return checkNew;
      });
      setClientOrchardDataState(newArrayEdit);
      toggleRefOrchard.current = true;
    } else {
      const newArrayEdit = clientOrchardDataState.map((check) => {
        const checkNew = {
          ...check,
          assigned: false,
        };
        return checkNew;
      });
      setClientOrchardDataState(newArrayEdit);
      toggleRefOrchard.current = false;
    }
  };

  const handleChangeProcess = (e) => {
    // console.log(e.target, '---process');
    const newArrayEditProcess = checkboxesState.map((check) => {
      const newProcess = check.process.map((pro) => {
        if (pro.id !== e.target.value) {
          return pro;
        } else {
          const proNew = {
            ...pro,
            assigned: e.target.checked,
          };
          return proNew;
        }
      });
      return {
        ...check,
        process: newProcess,
      };
    });
    setCheckboxesState(newArrayEditProcess);
  };

  const renderCheckboxesOrchard = () => {
    if (clientOrchardDataState.length === 0) {
      return (
        <p
          style={{
            marginTop: "50px",
          }}
        >
          Seleccione Perfil y usuario para cargar módulos.
        </p>
      );
    }

    // filtrar que los clientOrchardDataState existan en searchResults
    // const result = clientOrchardDataState.filter(
    // 	(ele) =>
    // 		searchResults.filter((search) => search.value === ele.value)
    // 			.length > 0
    // );

    return clientOrchardDataState
      .filter(
        (ele) =>
          searchResults.filter((search) => search.value === ele.value).length >
          0
      )
      .map((aCheckbox, index) => {
        return (
          <label key={aCheckbox.id} className={classes.labels}>
            <CheckboxWidgetControlled
              name="orchardUser"
              value={aCheckbox.id}
              color="primary"
              onChangeHandler={handleChangeOrchard}
              checked={aCheckbox.assigned}
              control={control}
              errors="orchardUser"
            />
            <Typography color="textSecondary" variant="body1">
              {aCheckbox.label}
            </Typography>
          </label>
        );
      });
  };

  const renderCheckboxes = () => {
    if (checkboxesState.length === 0) {
      return (
        <p style={{ marginTop: "50px" }}>
          Seleccione Perfil y usuario para cargar módulos.
        </p>
      );
    }

    return checkboxesState.map((aCheckbox, index) => {
      return (
        <label key={aCheckbox.id} className={classes.labels}>
          <CheckboxWidgetControlled
            name="modulesUser"
            value={aCheckbox.id}
            color="primary"
            onChangeHandler={handleChange}
            checked={aCheckbox.assigned}
            control={control}
            errors="modulesUser"
          />
          <Typography color="textSecondary" variant="body1">
            {aCheckbox.namePrint}
          </Typography>
        </label>
      );
    });
  };

  const renderCheckboxesProcess = () => {
    if (checkboxesState.length === 0) {
      return (
        <p style={{ marginTop: "50px" }}>
          Seleccione Perfil y usuario para cargar procesos.
        </p>
      );
    }

    const checkboxes = checkboxesState.map((aCheckbox) => {
      if (aCheckbox.assigned) {
        return (
          <div className={classes.processBox} key={aCheckbox.id}>
            <div
              style={{
                fontWeight: "bold",
              }}
            >
              {aCheckbox.namePrint}{" "}
              <SimpleIconButton
                labelButton="Todos Procesos"
                icon={
                  <i
                    className={clsx(
                      toggleProcessRefs.current[aCheckbox.id]
                        ? "far fa-check-square"
                        : "far fa-square"
                    )}
                  ></i>
                }
                actionOnClick={() => handleToggleModuleProcesses(aCheckbox.id)}
              />
            </div>
            {aCheckbox.process.map((pros) => {
              return (
                <label key={pros.id} className={classes.labels}>
                  <CheckboxWidgetControlled
                    name="processUser"
                    value={pros.id}
                    color="primary"
                    onChangeHandler={handleChangeProcess}
                    checked={pros.assigned}
                    control={control}
                    errors="processUser"
                  />
                  <Typography color="textSecondary" variant="body1">
                    {pros.namePrint}
                  </Typography>
                </label>
              );
            })}
          </div>
        );
      } else {
        return null;
      }
    });
    return checkboxes;
  };

  // Actualiza el Estado del Select, cuando cambia el select
  useEffect(() => {
    // console.log(watchProfile, "<----profile")
    setProfileSelectedState(watchProfile);

    // // filtra por el perfil
    // if (watchProfile) {
    // 	console.log(
    // 		usersDataState,
    // 		'---------usersDataComboState',
    // 		watchProfile
    // 	);
    // }
  }, [watchProfile]);

  useEffect(() => {
    if (watchusercombo !== null) {
      // console.log('------watch', watchusercombo);
      setUserSelectedState(watchusercombo);
    }
  }, [watchusercombo]);

  // busca usuarios del cliente
  useEffect(() => {
    if (watchClient) {
      // console.log(watchClient, '------');
      setCheckboxesState([]);
      setValue("usercombo", null);
      setClientOrchardDataState([]);
      // const result = HUERTOS.filter((ele) => ele.value === watchClient);
      // setClientOrchardDataState(
      // 	result[0]?.orchard
      // 		? alterArrayAddAssigned(result[0].orchard)
      // 		: []
      // );

      const resultClient = clientDataState.filter(
        (ele) => ele.value === watchClient
      );

      getUsersAsync(resultClient[0].id);
    }
  }, [watchClient]);

  // if (isLoading) {
  // 	return (
  // 		<div
  // 			style={{
  // 				display: 'flex',
  // 				justifyContent: 'center',
  // 				alignItems: 'center',
  // 				textAlign: 'center',
  // 				flexDirection: 'column',
  // 			}}
  // 		>
  // 			<LoadingWidget />
  // 			<Typography variant="h6" gutterBottom className="mb-5">
  // 				Actualizando Huertos..
  // 			</Typography>
  // 		</div>
  // 	);
  // }

  console.log(checkboxesState, "-----------checkboxesState");

  return (
    <div className="form-style">
      <BsContainer fluid class="px-0 px-md-3">
        <BsRow class="no-gutters">
          <BsCol class="col-md-12">
            <Card elevation={0}>
              <CardHeaderWidget
                title={viewsTexts.titles.user.userTextPrivilege}
                subheader={<BreadCrumbs subTitle="" />}
              />
              {/* <Divider /> */}
              <CardContent>
                <BsRow>
                  <BsCol class="col-md-3">
                    <TextFieldWidgetSelectController
                      options={clientDataState}
                      titleOption="label"
                      control={control}
                      name="client"
                      labeltext="Cliente"
                      errors={errors.client}
                      defaultValue=""
                      loading={loadingDataState}
                      // req
                      withDefault
                      variant="standard"
                    />
                  </BsCol>
                  <BsCol class="col-md-3">
                    <TextFieldWidgetSelectController
                      options={profileDataState}
                      titleOption="label"
                      control={control}
                      name="profile"
                      labeltext="Perfil"
                      errors={errors.profile}
                      defaultValue=""
                      loading={loadingDataState}
                      disabled={loadingDataState}
                      // req
                      withDefault
                      variant="standard"
                    />
                  </BsCol>
                  <BsCol class="col-md-3">
                    <SelectMultipleWidget
                      errors={errors.usercombo}
                      loading={false}
                      control={control}
                      menuPortalTarget={document.body}
                      labeltext="Usuario"
                      name="usercombo"
                      isSearchable
                      isSingle
                      defaultValue=""
                      options={usersDataComboState}
                      placeholder="Seleccione Usuario"
                    />
                  </BsCol>
                </BsRow>
                <BsRow>
                  <BsCol class="col-md-12 pt-3">
                    {checkboxesState.length ? (
                      <div
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "20px",
                          zIndex: "100",
                        }}
                      >
                        <SimpleIconButton
                          labelButton="Todos/Ningún Huertos"
                          style={{
                            marginRight: "10px",
                          }}
                          icon={
                            <i
                              className={clsx(
                                toggleRefOrchard.current
                                  ? "far fa-check-square"
                                  : "far fa-square"
                              )}
                            ></i>
                          }
                          actionOnClick={handleToggleOrchards}
                        />
                        <SimpleIconButton
                          labelButton="Todos/Ningún Módulo/Proceso"
                          icon={
                            <i
                              className={clsx(
                                toggleRef.current
                                  ? "far fa-check-square"
                                  : "far fa-square"
                              )}
                            ></i>
                          }
                          actionOnClick={handleToggleModulesProcess}
                        />
                      </div>
                    ) : null}
                    <SimpleTabs
                      tabitems={tabItems}
                      value={valueTabState}
                      toggler={handleChangeTab}
                      tabsDisabled={loadingDataState}
                    >
                      <TabPanel value={valueTabState} index={0}>
                        {/* <p>
													Lorem ipsum dolor sit amet
													consectetur adipisicing
													elit. Maxime consequuntur
													obcaecati inventore tempora
													nisi, excepturi eius, iste
													harum pariatur quasi
													nesciunt nulla saepe
													laudantium, est totam vel
													adipisci. Quos, autem.
												</p> */}
                        <Typography variant="h6" gutterBottom>
                          Seleccione los Huertos del Usuario
                        </Typography>
                        {checkboxesState.length ? (
                          <SearchInput
                            searchTerm={searchTerm}
                            searchFunc={searchDebouncedHandler}
                            clearFunc={clearTerm}
                            placeholder="Buscar Huerto..."
                          />
                        ) : null}
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <FormGroup className={classes.groupLabel}>
                            {renderCheckboxesOrchard()}
                          </FormGroup>
                        </div>
                      </TabPanel>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <TabPanel value={valueTabState} index={1}>
                          <Typography variant="h6" gutterBottom>
                            Seleccione los Módulos del Usuario
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <FormGroup className={classes.groupLabel}>
                              {renderCheckboxes()}
                            </FormGroup>
                          </div>
                        </TabPanel>
                        <TabPanel value={valueTabState} index={2}>
                          <Typography variant="h6" gutterBottom>
                            Seleccione los Procesos de cada Módulo
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <FormGroup className={classes.groupLabel}>
                              {renderCheckboxesProcess()}
                            </FormGroup>
                          </div>
                          {checkboxesState.length === 0 ? <hr /> : null}
                        </TabPanel>
                        <div className="text-center">
                          <SaveAction
                            title="Guardar"
                            color="secondary"
                            size="large"
                            className="roundedButton ml-md-2"
                            variant="contained"
                            disabled={
                              loadingMutationState || !userSelectedState
                            }
                            messageType="add"
                            mutationLoading={loadingMutationState}
                            success={successQueryState}
                          />
                        </div>
                      </form>
                    </SimpleTabs>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default UserPrivilege;
