import React from 'react';
import classes from './SimpleIconButton.module.scss';
import {  Button } from '@mui/material';


const SimpleIconButton = props => {
  const {
    disabled = false,
    actionOnClick,
    labelButton,
    size = "small",
    variant = "outlined",
    color = "primary",
    icon,
    style
  } = props;

  return (
    <Button
      size={size}
      disabled={disabled}
      variant={variant}
      classes={{
        root: classes.buttonStyle
      }}
      style={{ ...style }}
      color={color}
      startIcon={icon ? icon : null}
      onClick={actionOnClick}
    >
      {labelButton}
    </Button>
  );
};

export default SimpleIconButton;
