import React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	tableContainerStyle: {
		[theme.breakpoints.up('sm')]: {
			paddingRight: 34,
		},
	},
	selectStyle: {
		marginTop: 0,
		marginBottom: 0,
	},
	divider: {
		height: '28px',
		margin: '4px',
	},
	iconButton: {
		padding: '8px',
	},
}));

const SearchBasicSelect = ({
	onFilter,
	filterFunc,
	placeholder,
	valuetext,
	resetFunc,
	loading,
	options,
}) => {
	const classes = useStyles();
	return (
		<Paper
			component="form"
			style={{
				width: '300px',
				display: 'flex',
				alignItems: 'center',
				padding: '0px 9px',
			}}
		>
			<TextField
				variant="outlined"
				select
				margin="dense"
				size="small"
				label=""
				className={classes.selectStyle}
				disabled={loading}
				value={valuetext}
				onChange={(e) => onFilter(e.target.value)}
				fullWidth
				SelectProps={{
					native: true,
				}}
			>
				<option value="">{placeholder}</option>
				{options &&
					options.map((option) => (
						<option key={option.id} value={option.id}>
							{option.label}
						</option>
					))}
			</TextField>
			<IconButton
				onClick={resetFunc}
				className={classes.iconButton}
				color="default"
				aria-label="reset"
			>
				<ClearIcon />
			</IconButton>
			<Divider className={classes.divider} orientation="vertical" />
			<IconButton
				onClick={filterFunc}
				className={classes.iconButton}
				color="primary"
				aria-label="buscar"
			>
				<SearchIcon />
			</IconButton>
		</Paper>
	);
};
export default SearchBasicSelect;
