import React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { IconButton, Tooltip } from '@mui/material';
import UserStatus from '../../../common/userStatus.json';

const IconActionTableStateOn = (props) => {
	const { handleActionState, rowState, moduleName = '' } = props;

	return (
		<Tooltip title={props.tooltip ? props.tooltip : 'Cambio de estado'}>
			{moduleName !== 'productiveUnit' ? (
				<IconButton
					sx={{
						color: 'white',
						backgroundColor: rowState ? 'success.main' : 'grey.300',
						'&:hover': {
							backgroundColor: 'primary.dark',
						},
						borderRadius: 1,
						padding: 1,
						margin: 0.5,
					}}
					aria-label="state"
					onClick={handleActionState}
				>
					{(rowState === UserStatus.UserStatus[0].active && (
						<CheckBoxIcon fontSize="small" />
					)) ||
						(rowState === UserStatus.UserStatus[1].active && (
							<CheckBoxOutlineBlankIcon fontSize="small" />
						))}
				</IconButton>
			) : (
				<IconButton
					// classes={{
					// 	label: 'buttonContainer buttonPrimary',
					// 	root: 'buttonRoot',
					// }}
					sx={{
						color: 'white',
						backgroundColor: rowState
							? 'secondary.main'
							: 'grey.300',
						'&:hover': {
							backgroundColor: 'primary.dark',
						},
						borderRadius: 1,
						padding: 1,
						margin: 0.5,
					}}
					aria-label="state"
					onClick={handleActionState}
				>
					<EventAvailableIcon fontSize="small" />
				</IconButton>
			)}
		</Tooltip>
	);
};

export default IconActionTableStateOn;
