import { formatters, validators } from "./fieldsConfig";

export const createColumnDef = ({
  headerName,
  field,
  dataType = "text",
  allowEmpty = false,
  editable = true,
  suppressFillHandle = null,
  handleClickAlert = () => {},
  aggFunc = null,
  editValueSetterExcel = false,
}) => {
  return {
    headerName,
    field,
    editable: (params) => {
      let editableParam = editable;

      // console.log(params.data, "--------");

      // Se ocupa en el modulo de YieldCalculator para las propiedades de los campos
      if (editValueSetterExcel) {
        editableParam =
          params.data[params.colDef.field] &&
          !params.data[`edited_${params.colDef.field}`]
            ? false
            : true;
      }
      return editableParam;
    },
    cellDataType: dataType,
    aggFunc,
    suppressFillHandle:
      suppressFillHandle !== null ? suppressFillHandle : dataType === "text",
    filter: false,
    cellClassRules: {
      "edited-cell": (params) => {
        const field = `edited_${params.colDef.field}`;
        return params.data && params.data[field] ? true : false;
      },
    },
    valueFormatter: (params) => {
      // console.log(params.data, "--------FORMATTER");

      // Se ocupa en el modulo de YieldCalculator para las propiedades de los campos
      if (editValueSetterExcel) {
        let dataExcel = params.data[params.colDef.field];
        if (!dataExcel) {
          dataExcel = params.data[`${params.colDef.field}Excel`];
        }
        return formatters && formatters[params.colDef.field]
          ? formatters[params.colDef.field](dataExcel)
          : dataExcel;
      }
      return formatters && formatters[params.colDef.field]
        ? formatters[params.colDef.field](params.value)
        : params.value;
    },
    valueSetter: (params) => {
      // Manejo de valores vacíos según tipo y configuración
      if (params.newValue === "" || params.newValue === null) {
        if (allowEmpty) {
          params.data[params.colDef.field] = null;
          if (params.data[params.colDef.field] !== params.oldValue) {
            params.data[`edited_${params.colDef.field}`] = true;
          }
          return true;
        } else {
          return false;
        }
      }
      // Validación del valor
      const validation = validators[params.colDef.field](params.newValue);
      if (!validation.valid) {
        handleClickAlert("warning", {
          message:
            validation.message instanceof Array
              ? validation.message.join(", ")
              : validation.message,
          field: params.colDef.headerName,
        });
        return false;
      }

      // Asignar valor según tipo de dato
      if (dataType === "number") {
        params.data[params.colDef.field] = Number(params.newValue);
      } else {
        params.data[params.colDef.field] = params.newValue;
      }

      // Marcar como editado
      params.data[`edited_${params.colDef.field}`] = true;
      return true;
    },
    cellStyle: (params) => {
      if (params.node?.level) return "";
      return null;
    },
  };
};

export const handleCellValueChanged = (params, options) => {
  const {
    setRowData,
    rowData,
    alertRef,
    setCopyButtonState,
    markEmptyAsEdited = false,
  } = options;

  const { data, colDef, newValue, oldValue } = params;

  if (newValue !== oldValue) {
    // Marcar la celda como editada
    data[`edited_${colDef.field}`] = true;
    // Opcional: determinar si valores vacíos se marcan como editados o no
    if (!markEmptyAsEdited && oldValue === newValue) {
      data[`edited_${colDef.field}`] = false;
    }

    // Refrescar la celda en la grilla
    params.api.refreshCells({
      rowNodes: [params.node],
      columns: [colDef.field],
      force: true,
    });

    // Actualizar estados de UI si se proporcionan
    if (alertRef) alertRef.current = true;
    if (setCopyButtonState) setCopyButtonState(true);

    // Actualizar el rowData si se proporcionan las funciones necesarias
    if (setRowData && rowData) {
      const updatedRowData = [...rowData];
      const rowIndex = updatedRowData.findIndex((row) => row.id === data.id);
      if (rowIndex !== -1) {
        updatedRowData[rowIndex] = data;
        setRowData(updatedRowData);
      }
    }
  }
};
