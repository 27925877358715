import { VALIDATION_RULES } from "./validationRules";

export const yieldCalculatorDictionary = {
  YIELD_CALCULATOR: {
    ARMS: {
      field: "arms",
      headerName: "Laterales",
      decimals: 2,
      prefix: "",
      suffix: "",
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(400)],
    },
    DART_PRE_PODA: {
      field: "dartPrePoda",
      headerName: "Dardos Pre Poda",
      decimals: 4,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(6000)],
    },
    DART_POST_PODA: {
      field: "dartPostPoda",
      headerName: "Dardos Post Poda",
      decimals: 4,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(3000)],
    },
    TWIG_PRE_PODA: {
      field: "twigPrePoda",
      headerName: "Ramillas Pre Poda",
      decimals: 4,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(1000)],
    },
    TWIG_POST_PODA: {
      field: "twigPostPoda",
      headerName: "Ramillas Post Poda",
      decimals: 4,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    CALIBER_ESTIMATE: {
      field: "caliberEstimate",
      headerName: "Calibre Estimado",
      decimals: 2,
      suffix: "",
      validationRules: [VALIDATION_RULES.min(5), VALIDATION_RULES.max(20)],
    },
    CALIBER_REAL: {
      field: "caliberReal",
      headerName: "Calibre Real",
      decimals: 2,
      suffix: "",
      validationRules: [VALIDATION_RULES.min(5), VALIDATION_RULES.max(20)],
    },
    BUD_DART: {
      field: "budDart",
      headerName: "budDart",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(15)],
    },
    DISBUDDING: {
      field: "disbudding",
      headerName: "disbudding",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(15)],
    },
    FLOWERS_DART: {
      field: "flowersDart",
      headerName: "flowersDart",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(100)],
    },
    FLOWERS_TWIG: {
      field: "flowersTwig",
      headerName: "flowersTwig",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(100)],
    },
    FROST_DART: {
      field: "frostDart",
      headerName: "frostDart",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(1000)],
    },
    FROST_TWIG: {
      field: "frostTwig",
      headerName: "frostTwig",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(1000)],
    },
    FRUITS_PLANT_POST_RALEO: {
      field: "fruitsPlantPostRaleo",
      headerName: "fruitsPlantPostRaleo",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(7000)],
    },
    FRUITS_PLANT_PRE_RALEO: {
      field: "fruitsPlantPreRaleo",
      headerName: "fruitsPlantPreRaleo",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(7000)],
    },
    FRUITSET_ESTIMATE: {
      field: "fruitsetEstimate",
      headerName: "fruitsetEstimate",
      decimals: 2,
      suffix: "%",
      validationRules: [VALIDATION_RULES.max(100)],
    },
    GUIDELINE_THINNING: {
      field: "guidelineThinning",
      headerName: "guidelineThinning",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(1000)],
    },
    YIELD_REAL: {
      field: "yieldReal",
      headerName: "yieldReal",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(30000)],
    },
    DATE_START_HARVEST: {
      field: "dateStartHarvest",
      headerName: "Fecha Inicio Cosecha",
      validationRules: [VALIDATION_RULES.validDate],
    },
    DATE_END_HARVEST: {
      field: "dateEndHarvest",
      headerName: "Fecha Cierre Cosecha",
      validationRules: [VALIDATION_RULES.validDate],
    },
  },
};

export const descriptionDictionary = {
  DESCRIPTION: {
    SURFACE: {
      field: "surface",
      headerName: "Superficie",
      decimals: 2,
      suffix: "ha",
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(1000)],
    },
    PLANTS_HA: {
      field: "plantsHa",
      headerName: "Plantas/ha",
      decimals: 2,
      validationRules: [
        VALIDATION_RULES.min(0),
        VALIDATION_RULES.max(100000),
        VALIDATION_RULES.integer,
      ],
    },
    PLANTING_YEAR: {
      field: "plantingYear",
      headerName: "Año de Plantación",
      decimals: 1,
      validationRules: [VALIDATION_RULES.validYear],
    },
    SEASON_CRAFT: {
      field: "seasonGraft",
      headerName: "Temporada de Cosecha",
      decimals: 1,
      validationRules: [VALIDATION_RULES.validYear],
    },
    CONDUCTION: {
      field: "conduction",
      headerName: "Conducción",

      validationRules: [VALIDATION_RULES.string],
    },
    CONTROL_FROST: {
      field: "controlFrost",
      headerName: "Control de Heladas",

      validationRules: [VALIDATION_RULES.boolean],
    },
    CONTROL_FROST_TYPE: {
      field: "controlFrostType",
      headerName: "Tipo de Control de Heladas",
      validationRules: [VALIDATION_RULES.string],
    },
    MESH: {
      field: "mesh",
      headerName: "Malla",

      validationRules: [VALIDATION_RULES.boolean],
    },
    MESH_TYPE: {
      field: "meshType",
      headerName: "Tipo de Malla",
      validationRules: [VALIDATION_RULES.string],
    },
    ROOF: {
      field: "roof",
      headerName: "Techo",

      validationRules: [VALIDATION_RULES.boolean],
    },
    ROOF_TYPE: {
      field: "roofType",
      headerName: "Tipo de Techo",
      validationRules: [VALIDATION_RULES.string],
    },
    POLLINATOR: {
      field: "pollinator",
      headerName: "Polinizador",
      decimals: 2,
      validationRules: [VALIDATION_RULES.max(500)],
    },
    ID_POLYGON: {
      field: "idPolygon",
      headerName: "ID Polígono",

      validationRules: [
        VALIDATION_RULES.min(0),
        VALIDATION_RULES.integer,
        VALIDATION_RULES.max(100000),
      ],
    },
    DEH: {
      field: "deh",
      headerName: "DEH",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(50)],
    },
    DSH: {
      field: "dsh",
      headerName: "DSH",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(50)],
    },
    UNIT_ROW: {
      field: "unitRow",
      headerName: "Unidad de Hilera",

      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    POSITION_ROW: {
      field: "positionRows",
      headerName: "Posición en Hilera",

      validationRules: [VALIDATION_RULES.string],
    },
  },
};
//TODO:  DE ACA EN ADELANTE AJUSTAR VALORES MIN Y MAX
export const breakBudDormancyDictionary = {
  BREAK_BUD_DORMANCY: {
    BREAKER_1: {
      field: "breaker1",
      headerName: "Rompedor 1",
      validationRules: [VALIDATION_RULES.string],
    },
    DOSE_1: {
      field: "dose1",
      headerName: "Dosis 1",
      decimals: 3,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    ADYUVANT: {
      field: "adyuvant",
      headerName: "Adyuvante",
      validationRules: [VALIDATION_RULES.string],
    },
    DOSE_ADYUVANT: {
      field: "doseAdyuvant",
      headerName: "Dosis Adyuvante",
      decimals: 1,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    DATE_BREAKER_1: {
      field: "dateBreaker1",
      headerName: "Fecha Rompedor 1",
      validationRules: [VALIDATION_RULES.validDate],
    },
    BREAKER_2: {
      field: "breaker2",
      headerName: "Rompedor 2",
      validationRules: [VALIDATION_RULES.string],
    },
    DOSE_2: {
      field: "dose2",
      headerName: "Dosis 2",
      decimals: 1,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    DOSE_NO_3_CA: {
      field: "doseNo3Ca",
      headerName: "Dosis Nº3 Ca",
      decimals: 1,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    DATE_BREAKER_2: {
      field: "dateBreaker2",
      headerName: "Fecha Rompedor 2",
      validationRules: [VALIDATION_RULES.validDate],
    },
  },
};

export const leafAnalysisDictionary = {
  LEAF_ANALYSIS: {
    LEAF_N: {
      field: "leafN",
      headerName: "Nitrógeno",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    LEAF_P: {
      field: "leafP",
      headerName: "Fósforo",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    LEAF_K: {
      field: "leafK",
      headerName: "Potasio",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    LEAF_CA: {
      field: "leafCa",
      headerName: "Calcio",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    LEAF_MG: {
      field: "leafMg",
      headerName: "Magnesio",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    LEAF_FE: {
      field: "leafFe",
      headerName: "Hierro",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    LEAF_MN: {
      field: "leafMn",
      headerName: "Manganeso",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    LEAF_ZN: {
      field: "leafZn",
      headerName: "Zinc",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    LEAF_CU: {
      field: "leafCu",
      headerName: "Cobre",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    LEAF_BORO: {
      field: "leafBoro",
      headerName: "Boro",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    LEAF_CHLOROPHYLL: {
      field: "leafChlorophyll",
      headerName: "Clorofila",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    LEAF_NA: {
      field: "leafNa",
      headerName: "Sodio",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    LEAF_CL: {
      field: "leafCl",
      headerName: "Cloro",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
  },
};

export const soilAnalysisDictionary = {
  SOIL_ANALYSIS: {
    SOIL1_PH: {
      field: "soil1Ph",
      headerName: "pH Suelo 1",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL1_MAT_ORG: {
      field: "soil1MatOrg",
      headerName: "Materia Orgánica Suelo 1",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL1_CE: {
      field: "soil1Ce",
      headerName: "CE Suelo 1",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL1_NH4: {
      field: "soil1Nh4",
      headerName: "NH4 Suelo 1",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL1_NO3: {
      field: "soil1No3",
      headerName: "NO3 Suelo 1",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL1_P: {
      field: "soil1P",
      headerName: "Fósforo Suelo 1",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL1_K_INTERC: {
      field: "soil1KInterc",
      headerName: "Potasio Intercambiable Suelo 1",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL1_K_DISP: {
      field: "soil1KDisp",
      headerName: "Potasio Disponible Suelo 1",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL1_CA_INTERC: {
      field: "soil1CaInterc",
      headerName: "Calcio Intercambiable Suelo 1",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL1_MG_INTERC: {
      field: "soil1MgInterc",
      headerName: "Magnesio Intercambiable Suelo 1",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL1_NA_INTERC: {
      field: "soil1NaInterc",
      headerName: "Sodio Intercambiable Suelo 1",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL1_S_DISP: {
      field: "soil1SDisp",
      headerName: "Azufre Disponible Suelo 1",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL1_AL_INTERC: {
      field: "soil1AlInterc",
      headerName: "Aluminio Intercambiable Suelo 1",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL1_FE: {
      field: "soil1Fe",
      headerName: "Hierro Suelo 1",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL1_MN: {
      field: "soil1Mn",
      headerName: "Manganeso Suelo 1",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL1_ZN: {
      field: "soil1Zn",
      headerName: "Zinc Suelo 1",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL1_CU: {
      field: "soil1Cu",
      headerName: "Cobre Suelo 1",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL1_BORO: {
      field: "soil1Boro",
      headerName: "Boro Suelo 1",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL2_PH: {
      field: "soil2Ph",
      headerName: "pH Suelo 2",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL2_CE: {
      field: "soil2Ce",
      headerName: "CE Suelo 2",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL2_NH4_DISP: {
      field: "soil2Nh4Disp",
      headerName: "NH4 Disponible Suelo 2",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    SOIL2_NO3_DISP: {
      field: "soil2No3Disp",
      headerName: "NO3 Disponible Suelo 2",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
  },
};

export const rootReservoirDictionary = {
  ROOT_RESERVOIR: {
    RESERVES_ARGININE: {
      field: "reservesArginine",
      headerName: "Reservas Arginina",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    RESERVES_STARCH: {
      field: "reservesStarch",
      headerName: "Reservas Almidón",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
  },
};

export const waterAnalysisDictionary = {
  WATER_ANALYSIS: {
    WATER_PH: {
      field: "waterPh",
      headerName: "pH Agua",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    WATER_COND_ELECTRIC: {
      field: "waterCondElectric",
      headerName: "Conductividad Eléctrica Agua",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    WATER_SALINITY: {
      field: "waterSalinity",
      headerName: "Salinidad Agua",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    WATER_RAS: {
      field: "waterRas",
      headerName: "RAS Agua",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    WATER_HARDNESS: {
      field: "waterHardness",
      headerName: "Dureza Agua",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    WATER_N_NH4: {
      field: "waterNNh4",
      headerName: "N-NH4 Agua",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    WATER_N_NO3: {
      field: "waterNNo3",
      headerName: "N-NO3 Agua",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    WATER_BICARBONATES: {
      field: "waterBicarbonates",
      headerName: "Bicarbonatos Agua",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    WATER_CA: {
      field: "waterCa",
      headerName: "Calcio Agua",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    WATER_MG: {
      field: "waterMg",
      headerName: "Magnesio Agua",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    WATER_CL: {
      field: "waterCl",
      headerName: "Cloro Agua",
      decimals: 2,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
  },
};

export const categorizationDictionary = {
  CATEGORIZATION: {
    EXPORTABLE_ESTIMATE: {
      field: "exportableEstimate",
      headerName: "Exportable Estimate",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    CALIBER_G: {
      field: "caliberG",
      headerName: "Caliber G",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    CALIBER_J: {
      field: "caliberJ",
      headerName: "Caliber J",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    CALIBER_L: {
      field: "caliberL",
      headerName: "Caliber L",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    CALIBER_P: {
      field: "caliberP",
      headerName: "Caliber P",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    PRE_CALIBER: {
      field: "preCaliber",
      headerName: "Pre Caliber",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    CALIBER_SJ: {
      field: "caliberSj",
      headerName: "Caliber SJ",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    CALIBER_SJD: {
      field: "caliberSjd",
      headerName: "Caliber SJD",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    CALIBER_XL: {
      field: "caliberXl",
      headerName: "Caliber XL",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    FRUIT_SOFT: {
      field: "fruitSoft",
      headerName: "Fruit Soft",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    FRUIT_STRONG: {
      field: "fruitStrong",
      headerName: "Fruit Strong",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    FRUIT_VERY_SOFT: {
      field: "fruitVerySoft",
      headerName: "Fruit Very Soft",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    FRUIT_VERY_STRONG: {
      field: "fruitVeryStrong",
      headerName: "Fruit Very Strong",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    BRIX: {
      field: "brix",
      headerName: "Brix",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    MAHOGANY_DARK: {
      field: "mahoganyDark",
      headerName: "Mahogany Dark",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    RED_MAHOGANY: {
      field: "redMahogany",
      headerName: "Red Mahogany",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    MISSING_COLOUR: {
      field: "missingColour",
      headerName: "Missing Colour",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    BLACK: {
      field: "black",
      headerName: "Black",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    RED: {
      field: "red",
      headerName: "Red",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
  },
};

export const receptionDictionary = {
  RECEPTION: {
    F_DEFORMED: {
      field: "fDeformed",
      headerName: "fDeformed",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_D_INSECT: {
      field: "fDInsect",
      headerName: "fDInsect",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_D_TRIPS: {
      field: "fDTrips",
      headerName: "fDTrips",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_D_SCALE: {
      field: "fDScale",
      headerName: "fDScale",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_SOFT_SIDE: {
      field: "fSoftSide",
      headerName: "fSoftSide",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_WITHOUT_PEDICEL: {
      field: "fWithoutPedicel",
      headerName: "fWithoutPedicel",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_DOBLE: {
      field: "fDoble",
      headerName: "fDoble",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_WOUND_SCARRED: {
      field: "fWoundScarred",
      headerName: "fWoundScarred",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_STAINS: {
      field: "fStains",
      headerName: "fStains",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_HALF_MOON: {
      field: "fHalfMoon",
      headerName: "fHalfMoon",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    MIX_VAR: {
      field: "mixVar",
      headerName: "mixVar",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_RUSSET: {
      field: "fRusset",
      headerName: "fRusset",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_WOUND: {
      field: "fWound",
      headerName: "fWound",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    WASTE: {
      field: "waste",
      headerName: "waste",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_DEHYD: {
      field: "fDehyd",
      headerName: "fDehyd",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    PED_DEHYD: {
      field: "pedDehyd",
      headerName: "pedDehyd",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_D_BIRD: {
      field: "fDBird",
      headerName: "fDBird",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_SOFT: {
      field: "fSoft",
      headerName: "fSoft",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_WOUND_OPEN: {
      field: "fWoundOpen",
      headerName: "fWoundOpen",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_START_DEHY: {
      field: "fStartDehy",
      headerName: "fStartDehy",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    PED_START_DEHY: {
      field: "pedStartDehy",
      headerName: "pedStartDehy",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_BRUISING: {
      field: "fBruising",
      headerName: "fBruising",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_STAIN_PUDRITION: {
      field: "fStainPudrition",
      headerName: "fStainPudrition",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_CRACKS_SIDE: {
      field: "fCracksSide",
      headerName: "fCracksSide",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_CRACKS_WATER: {
      field: "fCracksWater",
      headerName: "fCracksWater",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_CRACKS: {
      field: "fCracks",
      headerName: "fCracks",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_PITTING: {
      field: "fPitting",
      headerName: "fPitting",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_PUD_BLACK: {
      field: "fPudBlack",
      headerName: "fPudBlack",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_PUD_BROWN: {
      field: "fPudBrown",
      headerName: "fPudBrown",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
    F_PUDRITION: {
      field: "fPudrition",
      headerName: "fPudrition",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(500)],
    },
  },
};

export const packingDictionary = {
  PACKING: {
    M_PACKED: {
      field: "mPacked",
      headerName: "M Packed",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    L_PACKED: {
      field: "lPacked",
      headerName: "L Packed",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    XL_PACKED: {
      field: "xlPacked",
      headerName: "XL Packed",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    J_PACKED: {
      field: "jPacked",
      headerName: "J Packed",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    SJ_PACKED: {
      field: "sjPacked",
      headerName: "SJ Packed",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    P_PACKED: {
      field: "pPacked",
      headerName: "P Packed",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    G_PACKED: {
      field: "gPacked",
      headerName: "G Packed",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    KG_EXP_VAR_ORCHARD: {
      field: "kgExpVarOrchard",
      headerName: "Kg Exp Var Orchard",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
    KG_HARVESTING_VAR_ORCHARD: {
      field: "kgHarvestingVarOrchard",
      headerName: "Kg Harvesting Var Orchard",
      decimals: 9,
      validationRules: [VALIDATION_RULES.min(0), VALIDATION_RULES.max(10000)],
    },
  },
};
