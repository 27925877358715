import { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import {
  // DataTableWidget,
  SaveAction,
  TextFieldWidgetText,
  CardHeaderWidget,
  BreadCrumbs,
} from "../../../components";

import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  // Divider,
  Card,
  CardContent,
  // Hidden,
  // Typography,
  useTheme,
  Snackbar,
  Alert,
} from "@mui/material";
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BsRow, BsCol, BsContainer } from "../../../layouts/components";
import { Context } from "../../../context/Context";
import { AxiosContext } from "../../../context/AxiosContext";
// import viewsTexts from '../../common/viewsTexts.json';
import { makeStyles } from "@mui/styles";
// import { ToggleButton, ToggleButtonGroup } from '@mui/lab';
import viewsTexts from "../../../common/viewsTexts.json";
// import theme from 'theme';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  rootHelper: {
    fontSize: 17,
  },
  inputText: {
    fontSize: 26,
  },
}));

const CmAdd = (props) => {
  const { userId, userHuerto, userName, userOrchard } = useContext(Context);

  // console.log('----', filterState);

  const axiosContext = useContext(AxiosContext);
  // axiosContext.authAxios //queries with token

  const [loadingQueryState, setLoadingQueryState] = useState(false);
  const [successQueryState, setSuccessQueryState] = useState(false);

  // const [clientDataState, setClientDataState] = useState([]);

  const [errorState, setErrorState] = useState({ message: "", error: false });

  const theme = useTheme();

  const {
    handleSubmit,
    control,
    watch,
    reset,
    // setValue,
    getValues,
    // setError,
    // clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = async (values) => {
    console.log(values, "<<<--------");
    setLoadingQueryState(true);

    // saca los objetos
    // let clientObj = clientDataState.filter(
    // 	(ele) => ele.value === values.client
    // );

    const query = {
      // rut: formatRut(values.rut),
      client: values.client,
      // email: values.email,
      // maternalLastname: values.maternalLastname,
    };

    // console.log(query, 'envia a create');
    try {
      const response = await axiosContext.authAxios.post(
        "v1/client/create-client-platform",
        query
      );
      const bodyInfo = response.data;

      // 	// setProfileSelectedState('');
      // 	console.log(response, '<-----response');
      // 	// let response;

      console.log(response, "------response");

      if (response.status === 200) {
        if (response?.data?.success) {
          setSuccessQueryState(true);
          reset({});
        } else {
          setErrorState({
            message: response.data.message,
            error: true,
          });
        }
        console.log(response.data, "------data");
      }
      setLoadingQueryState(false);
    } catch (err) {
      // console.log('cae al error', err);
      // console.log('Error', err.message);
      console.log("Error", err.request.status);
      if (err.request.status === 500) {
        setErrorState({
          message: "Error del servidor al crear el cliente",
          error: true,
        });
      }

      setLoadingQueryState(false);
    }
  };

  const { password } = getValues();

  // const getClientsAsync = async () => {
  // 	try {
  // 		let response = await axiosContext.authAxios.post(
  // 			'v1/client/list-clients'
  // 		);

  // 		const { data, status } = response;

  // 		if (status === 200) {
  // 			setClientDataState(data.data);

  // 		}
  // 	} catch (error) {
  // 		console.log(error);
  // 		// setLoadingVarietyFilterState(false);
  // 	}
  // };
  // endpoints
  // useEffect(() => {
  // 	getClientsAsync();

  // }, []);

  const handleCloseSnackBarsComponent = () => {
    setErrorState({ message: "", error: false });
  };

  return (
    <div className="form-style">
      <BsContainer fluid class="px-0 px-md-3">
        <BsRow class="no-gutters">
          <BsCol class="col-md-12">
            <Card elevation={0}>
              <CardHeaderWidget
                title={viewsTexts.titles.client.title}
                subheader={<BreadCrumbs subTitle="" />}
              />
              {/* <Divider /> */}
              <CardContent>
                <BsRow>
                  <BsCol class="col-md-12">
                    <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
                      <BsRow>
                        <BsCol class="col-md-6">
                          <TextFieldWidgetText
                            labeltext={viewsTexts.forms.client}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            minLength={1}
                            maxLength={100}
                            control={control}
                            name="client"
                            errors={errors.client}
                            defaultValue=""
                            req
                            autoComplete="cliente"
                            variant="outlined"
                          />
                        </BsCol>
                      </BsRow>
                      <BsRow class="mt-5">
                        <BsCol class="col-md-12 text-center">
                          <SaveAction
                            title="Guardar"
                            color="secondary"
                            size="large"
                            className="roundedButton ml-md-2"
                            variant="contained"
                            disabled={errorState.error}
                            messageType="add"
                            mutationLoading={loadingQueryState}
                            success={successQueryState}
                          />
                        </BsCol>
                      </BsRow>
                    </form>
                  </BsCol>
                </BsRow>
                {errorState.error && (
                  <Snackbar
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    open={errorState}
                    onClose={() => setErrorState({ message: "", error: false })}
                    autoHideDuration={3000}
                    key="error-snackbar2"
                  >
                    <Alert
                      onClose={handleCloseSnackBarsComponent}
                      severity="error"
                      variant="filled"
                      sx={{ width: "100%" }}
                    >
                      {errorState.message}
                    </Alert>
                  </Snackbar>
                )}
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};
export default CmAdd;
